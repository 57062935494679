import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { DivAnchor } from '../../../types';

type ProxySectionContextMenuTargetRef = React.MutableRefObject<DivAnchor> | null;

const isProxySectionContextMenuButtonVisibleAtom = atom<boolean>(false);
const proxySectionContextMenuTargetRefAtom = atom<ProxySectionContextMenuTargetRef>(null);

const setIsProxySectionContextMenuButtonVisible = (isVisible: boolean): void =>
  getDefaultStore().set(isProxySectionContextMenuButtonVisibleAtom, isVisible);

const getProxySectionContextMenuTargetRef = (): ProxySectionContextMenuTargetRef => getDefaultStore().get(proxySectionContextMenuTargetRefAtom);
const setProxySectionContextMenuTargetRef = (ref: ProxySectionContextMenuTargetRef): void =>
  getDefaultStore().set(proxySectionContextMenuTargetRefAtom, ref);

export const useIsProxySectionContextMenuButtonVisible = (): boolean => useAtomValue(isProxySectionContextMenuButtonVisibleAtom);

export const useProxySectionContextMenuTargetRef = (): ProxySectionContextMenuTargetRef =>
  useAtomValue(proxySectionContextMenuTargetRefAtom);

export const showProxySectionContextMenuButton = (): void => {
  setIsProxySectionContextMenuButtonVisible(true);
};

export const hideProxySectionContextMenuButton = (): void => {
  setIsProxySectionContextMenuButtonVisible(false);
};

export const toggleProxySectionContextMenuVisibility = (ref: ProxySectionContextMenuTargetRef): void => {
  const isCurrentlyHidden = !getProxySectionContextMenuTargetRef();
  if (isCurrentlyHidden) {
    setProxySectionContextMenuTargetRef(ref);
  } else {
    setProxySectionContextMenuTargetRef(null);
  }
};

export const hideProxySectionContextMenu = (): void => {
  setProxySectionContextMenuTargetRef(null);
};

import { useTranslate } from '@tolgee/react';
import React, { FC, useContext, useState, MouseEvent } from 'react';
import 'react-coinbase-commerce/dist/coinbase-commerce-button.css';

import { ModalUpgradeButtonContainer, ModalUpgradeCryptoButton } from './domElements';
import { ButtonPay } from './styles';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { E_ACTION_INFO, E_ANALYTICS_ACTIONS } from '../../../../common/constants/analytics';
import { IPlan } from '../../../interfaces/plan';
import { useDiscountObjById, usePriceConfig, usePromoDiscountAvaliable, userContext, workspaceContext } from '../../../state';
import { useIsUpgradeDiscountAvaliable } from '../../../state/pricing/upgrade-discount.atom';
import { getExternalCheckoutUrl } from '../../../utils/open-site';
import { sendReactErrorToSentry } from '../../../utils/sentry.helper';
import { sendActionAnalytics } from '../../common/api';
import { ANNUAL_DISCOUNT, MONTH_DISCOUNT } from '../../modalsComponents/components/checkout-modal/constants';
import { checkPlanMaxProfiles, isDiscountAnnual, getSelectedPlan } from '../../modalsComponents/components/checkout-modal/utils';
import { createLimitsPurchaseSession, GetPaymentSessionUrlParams, getYookassaPaymentLink } from '../api';
import { E_CURRENCY, E_PAYMENT_METHODS, E_PAYMENT_METHODS_ANALYTICS, E_PERIOD } from '../interfaces';
import { handlePaymentCreationError } from '../utils/sentry';
import { Addon } from '../../../interfaces/workspaces/limits';
import { Spinner } from '../../modalsComponents/components/checkout-modal/styles';

const isElectron = !!window.require;

export type YookassaForm = {
  selectedPlan: string;
  selectedDiscount: string;
  plans: IPlan[];
  getCheckmarkLine?: () => JSX.Element;
  workspaceId: string;
  openSuccessModal: (planId: string) => void;
  isAddonPurchase: boolean;
  isPurchaseWithAddon: boolean;
  addon?: Addon;
}

const YookassaForm: FC<YookassaForm> = (props) => {
  const {
    selectedPlan,
    selectedDiscount,
    plans,
    getCheckmarkLine,
    workspaceId,
    isAddonPurchase,
    isPurchaseWithAddon,
    addon = {},
  } = props;

  const isUpgrade = useIsUpgradeDiscountAvaliable();
  const priceConfig = usePriceConfig();
  const selectedDiscountObj = useDiscountObjById(selectedDiscount);

  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  const { profiles: userProfilesCount } = useContext(userContext);
  const { profilesCount: workspaceProfilesCount } = useContext(workspaceContext);

  const { t: translation } = useTranslate();

  const isAnnual = isDiscountAnnual(selectedDiscount);
  const discountId = isAnnual ? ANNUAL_DISCOUNT : MONTH_DISCOUNT;
  const planPeriod = isAnnual ? E_PERIOD.ANNUAL : E_PERIOD.MONTHLY;
  const profilesCount = NEW_FEATURES.workspaces ? workspaceProfilesCount : userProfilesCount;

  const selectedDiscounObj = useDiscountObjById(discountId);
  const promoDiscount = usePromoDiscountAvaliable();

  const handleAddonPurchase = async (): Promise<void> => {
    const { url: checkoutUrl } = await createLimitsPurchaseSession({
      workspaceId,
      planId: selectedPlan,
      currency: E_CURRENCY.RUB,
      paymentMethod: E_PAYMENT_METHODS.YOOKASSA,
      addon,
    });

    await handleRedirect(checkoutUrl);
  };

  const handleRedirect = async (checkoutUrl: string): Promise<void> => {
    if (!checkoutUrl) {
      setIsBtnLoading(false);

      return;
    }

    const checkoutPageUrl = await getExternalCheckoutUrl({ checkoutUrl });
    if (isElectron) {
      window.require('electron').shell.openExternal(checkoutPageUrl)
        .catch((error) => {
          const errorMessage = error instanceof Error ? error.message : 'unknown';
          sendReactErrorToSentry({ message: errorMessage, transactionName: 'open-yookassa-checkout-url' });
        });

      setIsBtnLoading(false);

      return;
    }

    window.open(checkoutPageUrl, '_blank');
    setIsBtnLoading(false);
  };

  const handleSubmit = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    if (isAddonPurchase) {
      await handleAddonPurchase();

      return;
    }

    const actionInfo = isUpgrade ? E_ACTION_INFO.UPGRADE : E_ACTION_INFO.EMPTY;
    sendActionAnalytics(E_ANALYTICS_ACTIONS.clickedPayNow, { actionInfo, paymentMethod: E_PAYMENT_METHODS.YOOKASSA }).catch(() => null);
    event.preventDefault();

    const planToPay = getSelectedPlan(plans, selectedPlan);
    const profilesStatus = planToPay && checkPlanMaxProfiles({
      translation,
      planToPay,
      profiles: profilesCount,
      paymentMethod: E_PAYMENT_METHODS.YOOKASSA,
    });

    if (!profilesStatus) {
      return;
    }

    handleCheckout(event);
  };

  const handleCheckout = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    setIsBtnLoading(true);
    const createCheckoutSessionsParams: GetPaymentSessionUrlParams = {
      plan: selectedPlan,
      discount: selectedDiscount,
      workspaceId,
      currency: E_CURRENCY.RUB,
      isUpgrade,
    };

    if (isPurchaseWithAddon) {
      createCheckoutSessionsParams.addon = addon;
    }

    const { url: checkoutUrl } = await getYookassaPaymentLink(createCheckoutSessionsParams)
      .catch((error: any) => {
        handlePaymentCreationError({
          error,
          selectedDiscounObj,
          planPeriod,
          method: E_PAYMENT_METHODS.YOOKASSA,
          paymentMethod: E_PAYMENT_METHODS_ANALYTICS.YOOKASSA,
          promoDiscount,
          plans,
          selectedPlan,
          priceConfig,
          tabCurrency: E_CURRENCY.RUB,
        });

        return { url: '' };
      });

    await handleRedirect(checkoutUrl);
  };


  return (
    <>
      {getCheckmarkLine ? getCheckmarkLine() : null}
      <ModalUpgradeButtonContainer paddingBottom={isAnnual}>
        <ButtonPay>
          <ModalUpgradeCryptoButton
            onClick={handleSubmit}
          >
            {isBtnLoading ?
              <Spinner size={19} thickness={2} /> :
              translation('pricing.forms.stripe.submitBtnTxt')}
          </ModalUpgradeCryptoButton>
        </ButtonPay>
      </ModalUpgradeButtonContainer>
    </>
  );
};

export default YookassaForm;

import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';

import { ProxyItemBase, proxyListItemActiveStyles } from '../styles';

export const ProxyGroupAddButtonWrapper = styled(ProxyItemBase)<{ isTextInactive: boolean; isHighlighted: boolean }>`
  height: 36px;
  padding-left: 33px;
  border-radius: 4px;
  margin: 1px 4px 4px 4px;

  display: flex;
  align-items: center;

  color: var(--767676-proxy-manager-list-item);

  cursor: pointer;

  ${(props): SerializedStyles | null => props.isHighlighted ? proxyListItemActiveStyles : null}

  :hover {
    ${proxyListItemActiveStyles}

    ${(props): SerializedStyles | null => props.isTextInactive ? null : css`
      color: var(--2B2B31-header);
  
      svg path {
        stroke: var(--2B2B31-header);
      }
    `}
  }
`;

import React, { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { FoldersListPopoverCustom, FolderRow, RemoveFolderText, FolderNameInput, ItemRow, FOLDERS_LIST_CUSTOM_POPOVER_Z_INDEX } from './styles';
import { IFolder } from '../../../interfaces';
import { useCurrentTheme } from '../../../state/theme.atom';
import getSelectedThemeColor from '../../../utils/get-selected-theme-color';
import { GologinPopoverDivider } from '../../gologin-popover/gologin-popover-divider';

interface IFolderSubmenu {
  anchorEl: HTMLElement | null;
  setAnchorEl: (state: null) => void;
  folder: IFolder;
  setFolder: (folder: null) => void;
  onRemoveFolder: () => void;
  onRenameFolder: (newName: string) => void;
  openModalShareFolder: () => void;
}

const FolderSubmenu: FC<IFolderSubmenu> = ({ anchorEl, setAnchorEl, folder, setFolder, onRemoveFolder, onRenameFolder, openModalShareFolder }) => {
  const [folderNameInput, setFolderNameInput] = useState<string>('');
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
  const theme = useCurrentTheme();
  const { t: translation } = useTranslation();
  const isDarkTheme = getSelectedThemeColor(theme) === 'dark';

  useEffect(() => {
    setFolderNameInput(folder.name);
  }, [folder.id]);

  const onKeyPress = (e: React.KeyboardEvent): void => {
    if (e.key !== 'Enter') {
      return;
    }

    e.preventDefault();
    onRenameFolder(folderNameInput);
  };

  const onClose = (): void => {
    setAnchorEl(null);
    setFolder(null);
    setDeleteConfirm(false);
  };

  const deleteFolderForm = (): JSX.Element => {
    if (!deleteConfirm) {
      return (
        <FolderRow onClick={(): void => setDeleteConfirm(true)}>
          <Trans i18nKey='folders.menu.deleteFolders' />
        </FolderRow>
      );
    }

    return (
      <FolderRow style={{ justifyContent: 'space-between' }}>
        <Trans i18nKey='folders.menu.areYouSure' />
        <div>
          <RemoveFolderText
            onClick={(): void => {
              onRemoveFolder();
              setDeleteConfirm(false);
            }}
            style={{ marginRight: 16 }}
          >
            <Trans i18nKey='base.yes' />
          </RemoveFolderText>
          <RemoveFolderText
            onClick={(): void => setDeleteConfirm(false)}
          >
            <Trans i18nKey='base.no' />
          </RemoveFolderText>
        </div>
      </FolderRow>
    );
  };

  return (
    <>
      <FoldersListPopoverCustom
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={onClose}
        transitionDuration={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ zIndex: FOLDERS_LIST_CUSTOM_POPOVER_Z_INDEX }}
        isDarkTheme={isDarkTheme}
      >
        <ItemRow style={{ display: 'flex', stroke: 'var(--767676-header-folders-sub-menu)' }}>
          <FolderNameInput
            value={folderNameInput}
            placeholder={translation('folders.createForm.nameInputPlaceholder')}
            onChange={(e): void => setFolderNameInput(e.target.value)}
            onBlur={(): void => {
              if (anchorEl) {
                onRenameFolder(folderNameInput);
              }
            }}
            onKeyPress={onKeyPress}
          />
        </ItemRow>
        <FolderRow onClick={(): void => openModalShareFolder()}>
          <Trans i18nKey='folders.list.sharing' />
        </FolderRow>
        <GologinPopoverDivider />
        {deleteFolderForm()}
      </FoldersListPopoverCustom>
    </>
  );
};

export default FolderSubmenu;

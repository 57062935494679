import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';

import { textFont } from '../../../../../ui/style-templates';

export const NoGroupsProxyContextMenuWrapper = styled('div')<{ top: number; left: number }>`
  width: 214px;
  outline: var(--gologin-popover-outline);
  border-radius: 4px;

  position: absolute;
  z-index: 1150;

  ${textFont()}

  background: var(--FFFFFF-proxy-manager-context-menu);
  box-shadow: var(--proxy-manager-context-menu-shadow);
  
  ${(props): SerializedStyles => css`
    top: ${props.top}px;
    left: ${props.left}px;
  `}

  ul {
    padding: 5px;
    margin: 0;
    list-style: none;

    li {
      padding: 6px 16px;
      border-radius: 4px;

      &:hover {
        cursor: pointer;
        background-color: var(--F2F2F2-proxy-manager-context-menu);
      }
    }

  }
`;

export const NoGroupsProxyContextMenuDivider = styled('div')`
  background: var(--EBEBEB-proxy-manager-context-menu);
  height: 1px;
  margin: 5px 0;
`;

import * as Sentry from '@sentry/react';
import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { getBasicTableProfileIds } from './basic-table-entities.atom';
import { useFirstOpenUnloadedGroupHeader } from './group-loading';
import { profilesTableGroupFieldAtom, useIsProfilesTableGrouped } from './profiles-table-group-field.atom';
import { MS_IN_SECOND, PROFILE_SHARED_KEY_NAME } from '../../../common/constants/constants';
import { LOCAL_STORAGE_SELECTED_FOLDER } from '../../../common/constants/local-storage';
import { normalizeProfilesData, wait } from '../../../common/utils';
import { handleShareViaLinkFromStorage } from '../../features/common/deep-links';
import { SortOrder } from '../../features/common/sorter-order';
import { handleActiveProxiesAlreadyArchived } from '../../features/proxy/proxy-helpers';
import { IProfilesResponse, IRequestProfiles, requestProfiles } from '../../features/quickProfiles/api';
import { ALL_PROFILES_FOLDER } from '../../features/quickProfiles/components/profiles-list-page';
import { SortField } from '../../features/quickProfiles/constants';
import { DropFileStep } from '../../hooks';
import { IArchivedProxyInBrowser, IProfile } from '../../interfaces';
import { GroupHeader } from '../../interfaces/group-header.interface';
import { IWorkspaceFolder } from '../../interfaces/workspaces';
import PerformanceObserverService from '../../services/performance-observer/performance-observer.service';
import { currentWorkspaceIdAtom } from '../current-workspace-id.atom';
import { NEW_FEATURES } from '../feature-toggle/new-features';
import { getAllProfilesFolderId } from '../folders/all-profiles-folder-id.atom';
import { getIsMoreProfilesAvailable, setIsMoreProfilesAvailable, useIsMoreProfilesAvailable } from '../is-more-profiles-available.atom';
import { upsertProfileRunStatuses } from '../profile-run-statuses.atom';
import {
  appendDeduplicatedProfilesToList,
  calculateIsProfilesTableIniting,
  getProfilesList,
  getProfilesTableHiddenGroups,
  profilesTableHiddenGroupsAtom,
  setProfilesList,
  updateProfilesTableGroupHeadersStatus,
} from '../profiles-list.atom';
import { profilesTableProxyIdFilterAtom } from '../profiles-proxy-filter.atom';
import { profilesSortFieldAtom, profilesSortOrderAtom } from '../profiles-table/profiles-sort.atom';
import { resetProfilesTableSelectedIds } from '../profiles-table-selected-ids.atom';
import { searchQueryAtom } from '../search-query.atom';
import { selectedTagIdAtom } from '../tags/selected-tag.atom';
import { updateFirstSessionProfilesStatus } from '../first-session-status.atom';

interface IProfilesQuery {
  workspaceId: string;
  filter: {
    tagId: string | null;
    searchQuery: string | null;
    proxyId: string;
  };
  sort: {
    field: SortField;
    order: SortOrder;
  };
  groupField: GroupHeader['filter']['type'] | null;
  hiddenGroupIds: string[];
  offset: number;
  shouldResetList?: boolean;
  forceVersion?: number;
}

// temporary solution to control when query updates
// it is preferable to call this force update instead of relying on atoms to update
// because atom updates create useless requests as well as renders
const forceProfilesQueryVersionAtom = atom<number>(0);
export const forceReloadProfilesQuery = (): void => {
  const prev = getDefaultStore().get(forceProfilesQueryVersionAtom);
  getDefaultStore().set(forceProfilesQueryVersionAtom, prev + 1);
};

const lastProfilesSelectorAtom = atom<IRequestProfiles | null>(null);

const getLastProfilesSelector = (): IRequestProfiles | null => getDefaultStore().get(lastProfilesSelectorAtom);
const setLastProfilesSelector = (newLastProfilesSelector: IRequestProfiles | null): void =>
  getDefaultStore().set(lastProfilesSelectorAtom, newLastProfilesSelector);

export type ProfilesQueryLoadingStatus = 'initing'|'loading'|'loaded';

const profilesQueryLoadingStatusAtom = atom<ProfilesQueryLoadingStatus>('initing');

const shouldProfilesQueryMakeRequestsAtom = atom<boolean>(true);

export const setShouldProfilesQueryMakeRequests = (shouldProfilesQueryMakeRequests: boolean): void =>
  getDefaultStore().set(shouldProfilesQueryMakeRequestsAtom, shouldProfilesQueryMakeRequests);

const getShouldProfilesQueryMakeRequests = (): boolean =>
  getDefaultStore().get(shouldProfilesQueryMakeRequestsAtom);

const profilesQueryOffsetAtom = atom<number>(0);
const getProfilesQueryOffset = (): number => getDefaultStore().get(profilesQueryOffsetAtom);
const setProfilesQueryOffset = (newOffset: number): void => getDefaultStore().set(profilesQueryOffsetAtom, newOffset);

export const resetProfilesQueryOffset = (): { isChanged: boolean } => {
  const prevOffset = getProfilesQueryOffset();
  if (prevOffset !== 0) {
    setProfilesQueryOffset(0);

    return { isChanged: true };
  }

  return { isChanged: false };
};

const currentProfilesQueryAtom = atom<IProfilesQuery>((get) => {
  let prevProfilesQuery: IProfilesQuery | null = null;
  try {
    prevProfilesQuery = get(currentProfilesQueryAtom);
  } catch (error: any) {
    if (error.message !== 'no atom init') {
      throw error;
    }
  }

  const workspaceId = get(currentWorkspaceIdAtom) ?? '';
  const tagId: string | null = get(selectedTagIdAtom) ?? '';
  const searchQuery = get(searchQueryAtom);
  const proxyId = get(profilesTableProxyIdFilterAtom);
  const offset = get(profilesQueryOffsetAtom);
  const sortField = get(profilesSortFieldAtom);
  const sortOrder = get(profilesSortOrderAtom);
  const groupField = get(profilesTableGroupFieldAtom);
  const forceVersion = get(forceProfilesQueryVersionAtom);

  // not a get, because we don't need to update this atom, if this changes
  const hiddenGroups = getProfilesTableHiddenGroups();

  // FIXME: this thing should work too
  // if (currentSelectedTag !== prevTag.current) {
  //   if (isTemporaryTag) {
  //     // user chose just created tag, so we do not load any profiles
  //     // until tag is actually created, and this function called again
  //     changeLoading(true);
  //     prevTag.current = currentSelectedTag;
  //
  //     return;
  //   }
  // }

  const hiddenGroupIds = hiddenGroups.map(({ id }) => id);

  let shouldResetList = !offset;
  if (prevProfilesQuery && prevProfilesQuery.groupField === groupField) {
    shouldResetList = false;
  }

  const dataSourceQuery: IProfilesQuery = {
    workspaceId,
    filter: {
      tagId,
      searchQuery,
      proxyId,
    },
    sort: {
      field: sortField,
      order: sortOrder,
    },
    groupField,
    hiddenGroupIds,
    offset,
    shouldResetList,
    forceVersion,
  };

  let isQuerySameAsPrev = false;
  if (prevProfilesQuery) {
    const prevQueryJSON: string = JSON.stringify(prevProfilesQuery);
    const nextQueryJSON = JSON.stringify(dataSourceQuery);
    isQuerySameAsPrev = prevQueryJSON === nextQueryJSON;
  }

  return isQuerySameAsPrev ? prevProfilesQuery : dataSourceQuery;
});

export const refreshProfilesQueryOffset = (): void => {
  const basicTableProfileIds = getBasicTableProfileIds();
  const newOffset = new Set(basicTableProfileIds).size;
  setProfilesQueryOffset(newOffset);
};

export const paginateProfilesQuery = (): void => {
  const loadingStatus = getProfilesQueryLoadingStatus();
  if (loadingStatus !== 'loaded') {
    return;
  }

  refreshProfilesQueryOffset();
};

export const doesProfilesQueryHaveMoreProfiles = (): boolean => getIsMoreProfilesAvailable();
export const useDoesProfilesQueryHaveMoreProfiles = (): boolean => {
  const isProfilesTableGrouped = useIsProfilesTableGrouped();
  const firstOpenUnloadedGroupHeaderId = useFirstOpenUnloadedGroupHeader();
  const isMoreProfilesAvailable = useIsMoreProfilesAvailable();

  if (isProfilesTableGrouped && !firstOpenUnloadedGroupHeaderId) {
    return false;
  }

  return isMoreProfilesAvailable;
};

export const useCurrentProfilesQuery = (): IProfilesQuery => useAtomValue(currentProfilesQueryAtom);
export const getCurrentProfilesQuery = (): IProfilesQuery => getDefaultStore().get(currentProfilesQueryAtom);

export const useProfilesQueryLoadingStatus = (): ProfilesQueryLoadingStatus => useAtomValue(profilesQueryLoadingStatusAtom);
const getProfilesQueryLoadingStatus = (): ProfilesQueryLoadingStatus => getDefaultStore().get(profilesQueryLoadingStatusAtom);
const setProfilesQueryLoadingStatus = (loadingStatus: ProfilesQueryLoadingStatus): void => {
  getDefaultStore().set(profilesQueryLoadingStatusAtom, loadingStatus);
};

// mostly for tests
export const resetProfilesQueryLoadingStatus = (): void => {
  setProfilesQueryLoadingStatus('initing');
};

const isSelectorLast = (currSelector: IRequestProfiles): boolean => currSelector === getLastProfilesSelector();

export const getProfilesQueryAsSelector = (foldersList: IWorkspaceFolder[]): IRequestProfiles => {
  let folderSelector: { folderId: string } | { folder: string } | Record<string, never> = {};
  const selectedFolderName = localStorage.getItem(LOCAL_STORAGE_SELECTED_FOLDER);
  if (NEW_FEATURES.dragAndDrop && selectedFolderName && selectedFolderName !== ALL_PROFILES_FOLDER) {
    const selectedFolder = foldersList.find(folder => folder.name === selectedFolderName);

    folderSelector = { folderId: selectedFolder?.id || '' };
  } else if (NEW_FEATURES.dragAndDrop) {
    const allProfilesFolderId = getAllProfilesFolderId();
    folderSelector = { folderId: allProfilesFolderId || '' };
  }

  if (!folderSelector.folderId && selectedFolderName && selectedFolderName !== ALL_PROFILES_FOLDER) {
    folderSelector = { folder: encodeURIComponent(selectedFolderName) };
  }

  const {
    workspaceId,
    filter,
    offset,
    sort,
    groupField,
    hiddenGroupIds,
  } = getCurrentProfilesQuery();

  return {
    ...folderSelector,
    workspaceId,
    search: filter.searchQuery || '',
    tag: filter.tagId || '',
    proxyId: filter.proxyId,
    offset,
    sortField: sort.field,
    sortOrder: sort.order,
    groupField,
    hiddenGroupIds,
  };
};

export type HandleOrbitaVersionsFn = (currentOrbitaMajorV: string, currentBrowserV: string) => void;
export type HandleFolderDeletedFn = () => void;
export type HandleFullProfilesMapFn = () => void;
export type HandleDropFileLoadedFn = () => void;
export type HandleLoadingDoneFn = () => void;
export type HandleCheckUaFn = (opts: { receivedProfiles: IProfile[]; currentBrowserV: string; currentOrbitaMajorV: string }) => Promise<void>;

// TODO: most (if not all) of these fields are unnecessary
// and should be applied from the state management
// but right now it is too much work :(
export interface IFetchProfilesOpts {
  foldersList: IWorkspaceFolder[];
  dropFileStep: DropFileStep;
  handleOrbitaVersions: HandleOrbitaVersionsFn;
  handleFolderDeleted: HandleFolderDeletedFn;
  handleFullProfilesMap: HandleFullProfilesMapFn;
  handleDropFileLoaded: HandleDropFileLoadedFn;
  handleLoadingDone: HandleLoadingDoneFn;
  handleCheckUa: HandleCheckUaFn;
}

export const fetchProfilesByCurrentQuery = async (opts: IFetchProfilesOpts): Promise<void> => {
  const {
    foldersList,
    dropFileStep,
    handleOrbitaVersions,
    handleFolderDeleted,
    handleFullProfilesMap,
    handleDropFileLoaded,
    handleLoadingDone,
    handleCheckUa,
  } = opts;

  const shouldProfilesQueryMakeRequests = getShouldProfilesQueryMakeRequests();
  if (!shouldProfilesQueryMakeRequests) {
    return;
  }

  const isIniting = calculateIsProfilesTableIniting();
  setProfilesQueryLoadingStatus(isIniting ? 'initing' : 'loading');
  const selector = getProfilesQueryAsSelector(foldersList);
  if (!selector.workspaceId) {
    return;
  }

  setLastProfilesSelector(selector);

  const transaction = Sentry.startTransaction({ name: 'load-profiles-table' });
  selector.transaction = transaction;

  const profilesReq = await requestProfiles(selector).catch(() => ({} as Partial<IProfilesResponse>));
  const {
    profiles: receivedProfiles = [],
    total: allProfilesCount = 0,
    currentOrbitaMajorV = '',
    currentBrowserV = '',
    isFolderDeleted = false,
    isMoreProfilesAvailable: receivedIsMoreProfilesAvailable = false,
    groupsMetadata = [],
  } = profilesReq;

  const isLastSelector = isSelectorLast(selector);
  if (!isLastSelector) {
    return;
  }

  const { tryAgainAfterSeconds } = await updateFirstSessionProfilesStatus(profilesReq);
  if (tryAgainAfterSeconds || tryAgainAfterSeconds === 0) {
    await wait(tryAgainAfterSeconds * MS_IN_SECOND);

    return fetchProfilesByCurrentQuery(opts);
  }

  setShouldProfilesQueryMakeRequests(false);

  const responseStatesSpan = transaction.startChild({ op: 'handle-response-states' });

  handleOrbitaVersions(currentOrbitaMajorV, currentBrowserV);
  if (isFolderDeleted) {
    handleFolderDeleted();
  }

  responseStatesSpan.finish();

  const checkUaSpan = transaction.startChild({ op: 'check-profiles-ua' });
  await handleCheckUa({ receivedProfiles, currentBrowserV, currentOrbitaMajorV });
  checkUaSpan.finish();

  const setProfilesSpan = transaction.startChild({ op: 'ui', description: 'set-profiles' });

  const performanceObserverService = PerformanceObserverService.getInstance();
  performanceObserverService.setProfilesMark(transaction);

  const profiles = getProfilesList();
  let {
    shouldResetList = true,
  } = getCurrentProfilesQuery();

  let normalizedReceivedProfiles = normalizeProfilesData(receivedProfiles);
  const profileSharedViaLinkJson = sessionStorage.getItem(PROFILE_SHARED_KEY_NAME) || '';
  normalizedReceivedProfiles = handleShareViaLinkFromStorage(normalizedReceivedProfiles, profileSharedViaLinkJson);

  const archivedProxies = normalizedReceivedProfiles.reduce<IArchivedProxyInBrowser[]>((archivedProxiesAcc, profile) => {
    const { archivedProxy = null } = profile || {};
    if (archivedProxy) {
      archivedProxiesAcc.push(archivedProxy);
    }

    return archivedProxiesAcc;
  }, []);

  handleActiveProxiesAlreadyArchived(archivedProxies);

  const isDropLoading = dropFileStep === 'loading';
  let updateType = 'reset';
  if (isDropLoading) {
    shouldResetList = true;
    updateType = 'drop-loading';
  }

  if (shouldResetList) {
    setProfilesList(normalizedReceivedProfiles);
  } else {
    updateType = 'append';
    appendDeduplicatedProfilesToList(normalizedReceivedProfiles);
  }

  if (shouldResetList && !isDropLoading) {
    resetProfilesTableSelectedIds();
  }

  performanceObserverService.setLoadProfilesTags({ transaction, selector, prevProfiles: profiles, receivedProfiles, updateType });

  handleFullProfilesMap();

  updateProfilesTableGroupHeadersStatus({ groupsMetadata });
  setIsMoreProfilesAvailable(receivedIsMoreProfilesAvailable);

  setProfilesSpan.finish();

  const initRunStatusesSpan = transaction.startChild({ op: 'ui', description: 'init-run-statuses' });
  upsertProfileRunStatuses(receivedProfiles.map(profile => ({ id: profile.id, status: 'profileStatuses.ready' })));
  initRunStatusesSpan.finish();

  const removeLoadingSpan = transaction.startChild({ op: 'ui', description: 'remove-loading' });
  if (dropFileStep === 'loading') {
    handleDropFileLoaded();
  }

  handleLoadingDone();
  setProfilesQueryLoadingStatus('loaded');
  setShouldProfilesQueryMakeRequests(true);

  removeLoadingSpan.finish();
};

import moment from 'moment';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { GeoProxyType } from '../../../../../common/constants/types';
import { stringifyRemainingTrafficAmount } from '../../../../../common/proxy/traffic/utils';
import { determineIsProxyCheckFailed } from '../../../../../common/proxy/utils';
import { IProxy } from '../../../../interfaces';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import { useTrafficData } from '../../../../state/proxy/traffic-data.atom';
import { getProxyStatus } from '../../proxy-helpers';
import { ProxyStatusDot } from '../../styles';
import { ProxyInfoRowWrapper, ProxyInfoUpdatedAgoDateWrapper, ProxyStatusDotWrapper } from './styles';

type ProxyInfoCheckDateProps = {
  proxy: IProxy;
};

const ProxyInfoCheckDate: React.FC<ProxyInfoCheckDateProps> = (props) => {
  const { proxy } = props;

  const { t: translation, i18n } = useTranslation();
  moment.locale(i18n.language);

  const isTorOrFree = ['gologin', 'tor'].includes(proxy.mode);
  const checkedAgo = proxy.checkDate ? moment(proxy.checkDate).fromNow() : '';
  const trafficData = useTrafficData();

  const type = proxy?.connectionType as GeoProxyType;

  const isRemainingTrafficZero = type && stringifyRemainingTrafficAmount(trafficData, type) === '0.0';
 
  if (isTorOrFree) {
    return (
      <ProxyInfoRowWrapper>
        <ProxyStatusDotWrapper>
          <ProxyStatusDot status="success" isRemainingTrafficZero={isRemainingTrafficZero} isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroupsV2} />
        </ProxyStatusDotWrapper>
        <ProxyInfoUpdatedAgoDateWrapper>
          <Trans i18nKey="proxies.connected" />
        </ProxyInfoUpdatedAgoDateWrapper>
      </ProxyInfoRowWrapper>
    );
  }

  const status = getProxyStatus(proxy);
  const renderStatusDot = (): JSX.Element => (
    <ProxyStatusDotWrapper>
      <ProxyStatusDot status={status} isRemainingTrafficZero={isRemainingTrafficZero} isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroupsV2} />
    </ProxyStatusDotWrapper>
  );

  if (!proxy.checkDate) {
    return (
      <ProxyInfoRowWrapper>
        {renderStatusDot()}
        <ProxyInfoUpdatedAgoDateWrapper>
          <Trans i18nKey="proxies.clickToCheckTheConnection" />
        </ProxyInfoUpdatedAgoDateWrapper>
      </ProxyInfoRowWrapper>
    );
  }

  if (determineIsProxyCheckFailed(proxy)) {
    return (
      <div>
        <ProxyInfoRowWrapper>
          {renderStatusDot()}
          <ProxyInfoUpdatedAgoDateWrapper>
            <Trans i18nKey="proxies.updatedWithSpace" values={{ dateChecked: checkedAgo }} />
          </ProxyInfoUpdatedAgoDateWrapper>
        </ProxyInfoRowWrapper>
        <ProxyInfoRowWrapper>
          <ProxyInfoUpdatedAgoDateWrapper isFail={true}>
            {translation(proxy.error || translation('notifications.error.proxyError'))}
          </ProxyInfoUpdatedAgoDateWrapper>
        </ProxyInfoRowWrapper>
      </div>
    );
  }

  if (!isRemainingTrafficZero && status === 'success') {
    return (
      <ProxyInfoRowWrapper>
        {renderStatusDot()}
        <ProxyInfoUpdatedAgoDateWrapper>
          <Trans i18nKey="proxies.connectedWithSpace" values={{ dateChecked: checkedAgo }} />
        </ProxyInfoUpdatedAgoDateWrapper>
      </ProxyInfoRowWrapper>
    );
  }

  return (
    <ProxyInfoRowWrapper>
      {renderStatusDot()}
      <ProxyInfoUpdatedAgoDateWrapper>
        <Trans i18nKey="proxies.updatedWithSpace" values={{ dateChecked: checkedAgo }} />
      </ProxyInfoUpdatedAgoDateWrapper>
    </ProxyInfoRowWrapper>
  );
};

export default ProxyInfoCheckDate;

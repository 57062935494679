import React, { FC } from 'react';
import { ModalDivider, ModalParamsLeftTxt, ModalParamsLine, ModalParamsRightTxt } from '../domElements';

export type PaymentModalLine = {
  text: JSX.Element;
  price: string;
}

const PaymentModalLine: FC<PaymentModalLine> = (props) => {
  const { text, price } = props;

  return (
    <>
      <ModalParamsLine>
        <ModalParamsLeftTxt>
          {text}
        </ModalParamsLeftTxt>
        <ModalParamsRightTxt>
          {price}
        </ModalParamsRightTxt>
      </ModalParamsLine>
      <ModalDivider />
    </>
  );
};

export default PaymentModalLine;
import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { getLimitRangeByPlanAndLimit } from './payment-modal.atom';
import { getPlansList, usePlanObjById } from './plans-list.atom';
import { useIsUpgradeDiscountAvaliable } from './upgrade-discount.atom';
import { DISCOUNT_IDS, DiscountIds, PLAN_IDS, PlanId } from '../../features/pricing/constants';
import { getBaseLimitsFromPlan, substractLimits, substractProxyData } from '../../features/pricing/utils';
import { IPlan } from '../../interfaces/plan';
import { DEFAULT_PROXY_DATA_TIERS, EMPTY_LIMITS, EMPTY_PROXY_DATA, Limits, PricingChosenLimits, PricingChosenProxyData, ProxyData } from '../../interfaces/workspaces/limits';
import { getWorkspaceLimits, getWorkspacePaidProxyData, useIsWorkspaceLimitsAvaliable, useWorkspaceLimits } from '../limits/workspace-limits.atom';
import { getWorkspaceSubscription } from '../workspace/workspace-subscription.atom';
import { calculateChosenLimitsByPlanAndPeriod, calculateProxyDataByPlanAndPeriod } from '../../features/pricing/components/payment-modal/pure/functions.pure';

const selectedPlanAtom = atom<string>(PLAN_IDS.Professional);
const selectedDiscountAtom = atom<string>(DISCOUNT_IDS.Annual);

const chosenLimitsListAtom = atom<PricingChosenLimits[]>([]);
const chosenProxyDataListAtom = atom<PricingChosenProxyData[]>([]);

export const setSelectedPlan = (data: string): void => getDefaultStore().set(selectedPlanAtom, data);
export const getSelectedPlan = (): string => getDefaultStore().get(selectedPlanAtom);
export const useSelectedPlan = (): string => useAtomValue(selectedPlanAtom);

export const setSelectedDiscount = (data: string): void => getDefaultStore().set(selectedDiscountAtom, data);
export const getSelectedDiscount = (): string => getDefaultStore().get(selectedDiscountAtom);
export const useSelectedDiscount = (): string => useAtomValue(selectedDiscountAtom);

export const getChosenLimitsList = (): PricingChosenLimits[] => getDefaultStore().get(chosenLimitsListAtom);
export const useChosenLimitsList = (): PricingChosenLimits[] => useAtomValue(chosenLimitsListAtom);
export const setChosenLimitsList = (data: PricingChosenLimits[]): void => getDefaultStore().set(chosenLimitsListAtom, data);

export const getChosenProxyDataList = (): PricingChosenProxyData[] => getDefaultStore().get(chosenProxyDataListAtom);
export const useChosenProxyDataList = (): PricingChosenProxyData[] => useAtomValue(chosenProxyDataListAtom);
export const setChosenProxyDataList = (data: PricingChosenProxyData[]): void => getDefaultStore().set(chosenProxyDataListAtom, data);

export const setDefaultChosenLimitsList = (): void => {
  const plans = getPlansList();
  const { planId: workspacePlanId, paymentDiscount: workspaceDiscountId } = getWorkspaceSubscription();
  const workspaceLimits = getWorkspaceLimits();
  const defaultChosenLimits = plans.reduce((chosenLimits: PricingChosenLimits[], planObj: IPlan) => {
    const { limits: planLimits, id: planId } = planObj;
    const chosenLimitsToPush = DiscountIds.map<PricingChosenLimits>(discountId => {
      const isSamePlanAndDiscount = planId === workspacePlanId && discountId === workspaceDiscountId;
      const limits = isSamePlanAndDiscount ? workspaceLimits : planLimits;

      return {
        planId,
        discountId,
        limits,
      };
    });

    chosenLimits.push(...chosenLimitsToPush);

    return chosenLimits;
  }, [] as PricingChosenLimits[]);

  setChosenLimitsList(defaultChosenLimits);
};

export const setDefaultChosenProxyDataList = (): void => {
  const plans = getPlansList();
  const proxyData = getWorkspacePaidProxyData();
  const defaultChosenProxyData = plans.reduce((chosenProxyData: PricingChosenProxyData[], planObj: IPlan) => {
    const { id: planId } = planObj;
    const chosenProxyDataToPush = DiscountIds.map<PricingChosenProxyData>(discountId => ({
      planId,
      discountId,
      proxyData,
      tiers: DEFAULT_PROXY_DATA_TIERS,
    }));

    chosenProxyData.push(...chosenProxyDataToPush);

    return chosenProxyData;
  }, [] as PricingChosenProxyData[]);

  setChosenProxyDataList(defaultChosenProxyData);
};

export const useChosenProxyDataByPlan = (planId: string): ProxyData => {
  const chosenProxyData = useChosenProxyDataList();
  const selectedDiscount = useSelectedDiscount();
  const { proxyData: chosenPlanProxyData } = chosenProxyData.find(proxyData => {
    const { planId: chosenPlanId, discountId } = proxyData || {};

    return planId === chosenPlanId && selectedDiscount === discountId;
  }) || {};

  if (!chosenPlanProxyData) {

    return EMPTY_PROXY_DATA;
  }

  return chosenPlanProxyData;
};

export const useChosenLimitsByPlan = (planId: string): Limits => {
  const chosenLimits = useChosenLimitsList();
  const selectedDiscount = useSelectedDiscount();
  const planObj = usePlanObjById(planId);
  const { limits: chosenPlanLimits } = chosenLimits.find(limits => {
    const { planId: chosenPlanId, discountId } = limits || {};

    return planId === chosenPlanId && selectedDiscount === discountId;
  }) || {};

  if (!chosenPlanLimits) {
    const { limits = EMPTY_LIMITS } = planObj || {};

    return limits;
  }

  return chosenPlanLimits;
};

export const getChosenLimitsByPLan = (planId: string): Limits => {
  const chosenLimits = getChosenLimitsList();
  const selectedDiscount = getSelectedDiscount();
  const { limits: chosenPlanLimits } = chosenLimits.find(limits => {
    const { planId: chosenPlanId, discountId } = limits || {};

    return planId === chosenPlanId && selectedDiscount === discountId;
  }) || {};

  if (!chosenPlanLimits) {
    return EMPTY_LIMITS;
  }

  return chosenPlanLimits;
};

export const getChosenProxyDataByPLan = (planId: string): ProxyData => {
  const chosenProxyData = getChosenProxyDataList();
  const selectedDiscount = getSelectedDiscount();
  const { proxyData: chosenPlanProxyData } = chosenProxyData.find(limits => {
    const { planId: chosenPlanId, discountId } = limits || {};

    return planId === chosenPlanId && selectedDiscount === discountId;
  }) || {};

  if (!chosenPlanProxyData) {
    return EMPTY_PROXY_DATA;
  }

  return chosenPlanProxyData;
};

export const updateChosenProxyDataByPlan = (planId: string, deltaProxyData: ProxyData): void => {
  const chosenProxyData = getChosenProxyDataList();
  const selectedDiscountId = getSelectedDiscount();
  const workspacePaidProxyData = getWorkspacePaidProxyData();
  const updatedChosenProxyData = calculateProxyDataByPlanAndPeriod({
    selectedPlanId: planId,
    selectedDiscountId,
    deltaProxyData,
    chosenProxyDataList: chosenProxyData,
    workspacePaidProxyData,
  });

  if (!updatedChosenProxyData) {
    return;
  }

  setChosenProxyDataList(updatedChosenProxyData);
};

export const updateChosenLimitsByPLan = (planId: string, deltaLimits: Limits): void => {
  const chosenLimits = getChosenLimitsList();
  const selectedDiscountId = getSelectedDiscount();
  const workspaceLimits = getWorkspaceLimits();
  const plansList = getPlansList();
  const { planId: workspacePlanId, paymentDiscount: workspaceDiscountId } = getWorkspaceSubscription();

  const updatedChosenLimits = calculateChosenLimitsByPlanAndPeriod({
    selectedPlanId: planId,
    selectedDiscountId,
    workspacePlanId,
    workspaceDiscountId,
    workspaceLimits,
    chosenLimitsList: chosenLimits,
    plansList,
    deltaLimits,
  });

  if (!updatedChosenLimits) {
    return;
  }

  setChosenLimitsList(updatedChosenLimits);
};

export const useAddonLimitsChosen = (planId: string): Limits => {
  const planObj = usePlanObjById(planId);
  const basePlanLimits = getBaseLimitsFromPlan(planObj);
  const workspaceLimits = useWorkspaceLimits();
  const selectedDiscount = useSelectedDiscount();
  const chosenLimits = useChosenLimitsByPlan(planId);
  const { planId: workspacePlanId, paymentDiscount: workspaceDiscountId } = getWorkspaceSubscription();
  const isSamePlanAndDiscount = planId === workspacePlanId && selectedDiscount === workspaceDiscountId;
  const baseLimits = isSamePlanAndDiscount ? workspaceLimits : basePlanLimits;

  return substractLimits(chosenLimits, baseLimits);
};

export const useAddonProxyDataChosen = (planId: string): ProxyData => {
  const workspaceProxyData = getWorkspacePaidProxyData();
  const chosenProxyData = useChosenProxyDataByPlan(planId);

  return substractProxyData(chosenProxyData, workspaceProxyData);
};

export const useHasAddonLimitsChosen = (workspacePlanId: string): boolean => {
  const workspaceAddonLimits = useAddonLimitsChosen(workspacePlanId);

  return Object.values(workspaceAddonLimits).some((limit: number) => limit > 0);
};

export const useHasAddonProxyDataChosen = (workspacePlanId: string): boolean => {
  const workspaceAddonProxyData = useAddonProxyDataChosen(workspacePlanId);

  return Object.values(workspaceAddonProxyData).some((traffic: number) => traffic > 0);
};

export const useIsSamePlan = (workspacePlanId: string, workspaceDiscount: string): boolean => {
  const selectedPlan = useSelectedPlan();
  const selectedDiscount = useSelectedDiscount();

  return selectedPlan === workspacePlanId && selectedDiscount === workspaceDiscount;
};

export const useIsLimitsPurchase = (workspacePlanId: string, workspaceDiscount: string): boolean => {
  const isWorkspaceLimitsAvaliable = useIsWorkspaceLimitsAvaliable();
  const isAtLeastOneLimitsChosenByUser = useHasAddonLimitsChosen(workspacePlanId);
  const isUpgrade = useIsUpgradeDiscountAvaliable();
  const isSamePlan = useIsSamePlan(workspacePlanId, workspaceDiscount);

  return isWorkspaceLimitsAvaliable && isAtLeastOneLimitsChosenByUser && isUpgrade && isSamePlan;
};

export const useIsProxydataPurchase = (workspacePlanId: string, workspaceDiscount: string): boolean => {
  const isAtLeastOneProxyDataChosenByUser = useHasAddonProxyDataChosen(workspacePlanId);
  const isUpgrade = useIsUpgradeDiscountAvaliable();
  const isSamePlan = useIsSamePlan(workspacePlanId, workspaceDiscount);

  return isAtLeastOneProxyDataChosenByUser && isUpgrade && isSamePlan;
};

export const useShouldShowMemberLimitButtons = (planId: string): boolean => {
  const isWorkspaceLimitsAvaliable = useIsWorkspaceLimitsAvaliable();
  const membersLimitsSettings = getLimitRangeByPlanAndLimit(planId, 'maxMembers');
  const { maxPurchasable: membersLimitsMaxPurchasable = 10 } = membersLimitsSettings || {};

  if (membersLimitsMaxPurchasable === 0) {
    return false;
  }

  return isWorkspaceLimitsAvaliable;
};

export const useMaxMembersShown = (planId: PlanId): number => {
  const { maxMembers: membersUserHas = 0 } = useWorkspaceLimits();
  const { maxMembers: maxMembersChosenByUser = 0 } = useChosenLimitsByPlan(planId);

  return membersUserHas + maxMembersChosenByUser;
};

export const useCanAddMember = (planId: PlanId): boolean => {
  const planObj = usePlanObjById(planId);
  const { maxMembers: maxAccountShares = 0 } = getBaseLimitsFromPlan(planObj);
  const membersLimitsSettings = getLimitRangeByPlanAndLimit(planId, 'maxMembers');
  const { maxPurchasable: membersLimitsMaxPurchasable = 0 } = membersLimitsSettings || {};
  const maxMembersShown = useMaxMembersShown(planId);
  const maxMembersUserCanHave = membersLimitsMaxPurchasable + maxAccountShares;

  return maxMembersShown <= maxMembersUserCanHave;
};

import { Menu } from 'antd';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { MenuKey } from './menu-key';
import { userContext, workspaceContext } from '../../../state';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { MenuTitle, OldPageTitle, SubmenuTitle } from '../styles';

const isElectron = !!window.require;

declare interface IPersonalAreaMenu {
  selectedKey: MenuKey;
}

const PersonalAreaMenu: FC<IPersonalAreaMenu> = (props) => {
  const { selectedKey } = props;

  const { email } = useContext(userContext);
  const { permissions } = useContext(workspaceContext);

  const { t: translation } = useTranslation();

  // placeholder for workspaceContext later
  const isWorkspaceHistoryShown = NEW_FEATURES.workspaces && false;
  const menuMarginTop = NEW_FEATURES.workspaces ? 40 : 0;

  return (
    <>
      {!NEW_FEATURES.workspaces ? (
        <OldPageTitle>
          {translation('personalArea.title')}
        </OldPageTitle>
      ) : null}
      <Menu
        mode='inline'
        style={{ width: 240, marginTop: menuMarginTop }}
        defaultSelectedKeys={[selectedKey]}
      >
        {NEW_FEATURES.workspaces ? (
          <MenuTitle>
            {email || 'Account'}
          </MenuTitle>
        ) : null}
        <Menu.Item key='overview'>
          <Link to='/personalArea'>
            {translation('personalArea.overview.title')}
          </Link>
        </Menu.Item>
        <Menu.Item key='default-profile-settings'>
          <Link to='/personalArea/default-profile-settings'>
            {translation('personalArea.defaultProfile.title')}
          </Link>
        </Menu.Item>
        {!NEW_FEATURES.workspaces ? (
          <Menu.Item key='billing'>
            <Link to='/personalArea/Billing'>
              {translation('personalArea.billing.title')}
            </Link>
          </Menu.Item>
        ) : null}
        <Menu.Item key='two-factor-auth'>
          <Link to='/personalArea/2fa'>
            {translation('personalArea.twoFA.title')}
          </Link>
        </Menu.Item>
        <Menu.Item key='affiliate-program'>
          <Link to='/personalArea/AffiliateProgram'>
            {translation('personalArea.affiliateProgram.title')}
          </Link>
        </Menu.Item>
        <Menu.Item key='active-sessions'>
          <Link to='/personalArea/ActiveSessions'>
            {translation('personalArea.activeSessions.title')}
          </Link>
        </Menu.Item>
        <Menu.Item key='token-api'>
          <Link to='/personalArea/TokenApi'>
            {translation('personalArea.api.title')}
          </Link>
        </Menu.Item>
        {isElectron ? (
          <Menu.Item key='cache'>
            <Link to='/personalArea/Cache'>
              {translation('personalArea.cache.title')}
            </Link>
          </Menu.Item>
        ) : null}
        <Menu.Item key='password-manager'>
          <Link to='/personalArea/PasswordManager'>
            {translation('personalArea.vault.title')}
          </Link>
        </Menu.Item>
        {NEW_FEATURES.workspaces ? (
          <SubmenuTitle>
            {translation('personalArea.workspace.title')}
          </SubmenuTitle>
        ) : null}
        {NEW_FEATURES.workspaces ? (
          <Menu.Item key='members'>
            <Link to='/members'>
              {translation('personalArea.workspaceSetting.title')}
            </Link>
          </Menu.Item>
        ) : null}
        {NEW_FEATURES.workspaces ? (
          <Menu.Item key='billing'>
            <Link to='/personalArea/Billing'>
              {translation('personalArea.billing.title')}
            </Link>
          </Menu.Item>
        ) : null}
        {isWorkspaceHistoryShown ? (
          <Menu.Item key='workspace-history'>
            <Link to='/workspace-history'>
              {translation('personalArea.workspaceSetting.workspaceHistory')}
            </Link>
          </Menu.Item>
        ) : null}
        {NEW_FEATURES.workspaces ? (
          <Menu.Item disabled={!permissions.viewDeletedProfiles} key='restore-profiles'>
            <Link to='/personalArea/restore-profiles'>
              {translation('personalArea.restoreProfiles.title')}
            </Link>
          </Menu.Item>
        ) : null}
      </Menu>
    </>
  );
};

export default PersonalAreaMenu;

import { DEFAULT_AVAILABLE_FOR_PURCHASE } from '../../state/proxy/traffic-data.atom';

export type Addon = {
  limits?: Limits;
  proxyData?: ProxyData;
};

export const EMPTY_LIMITS = {
  maxMembers: 0,
  maxProfiles: 0,
  maxProfileShares: 0,
  maxCloudLaunches: 0,
} as const;

export type LimitTypes = keyof typeof EMPTY_LIMITS;
export const LIMITS_TYPES = Object.keys(EMPTY_LIMITS);

export type PricingChosenLimits = {
  planId: string;
  discountId: string;
  limits: Limits;
};

export type PricingChosenProxyData = {
  planId: string;
  discountId: string;
  proxyData: ProxyData;
  tiers?: ProxyDataTiers; 
};

export type PricingChosenProxyDataTier = (typeof DEFAULT_AVAILABLE_FOR_PURCHASE)[number];
export type ProxyDataTiers = Mutable<{
  [Key in ProxyDataTypes]?: PricingChosenProxyDataTier | 0;
}>;


export const EMPTY_PROXY_DATA = {
  resident: 0,
  mobile: 0,
  dataCenter: 0,
} as const;

export const DEFAULT_PROXY_DATA_TIERS = {
  ...EMPTY_PROXY_DATA,
} as const;

export type ProxyDataTypes = keyof typeof EMPTY_PROXY_DATA;
export const PROXY_DATA_TYPES = Object.keys(EMPTY_PROXY_DATA);

export type Limits = Mutable<{
  [Key in LimitTypes]?: number;
}>;

export type ProxyData = Mutable<{
  [Key in ProxyDataTypes]?: number;
}>;

export type Mutable<T> = {
  -readonly [P in keyof T]: T[P];
};
export type UserScreenInfo = {
  width: number;
  height: number;
}

export interface ILocalUserDevice {
  timezone?: string;
  country?: string;
  languages?: string;
  screen: UserScreenInfo;
}

export const DEFAULT_LOCAL_USER_DEVICE: ILocalUserDevice = {
  timezone: '',
  country: '',
  languages: '',
  screen: {
    width: 0,
    height: 0,
  },
};

import { message } from 'antd';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import ProxyGroupAddButtonMenu from './proxy-group-add-button-menu';
import { ProxyGroupAddButtonWrapper } from './styles';
import { GROUPED_PROXY_MANAGER_ANALYTICS_EVENTS } from '../../../../../../common/constants/analytics';
import { GeoProxyType } from '../../../../../../common/constants/types';
import { useGeoProxyLastSelectedType } from '../../../../../state/proxy/geoproxy-form-data.atom';
import { PROXY_CHECK_TOOLTIP_LOCATIONS } from '../../../../../state/proxy/proxy-check/constants';
import { useProxyContextMenuProxy } from '../../../../../state/proxy/proxy-context-menu.atom';
import { createGeoProxy } from '../../../../../state/proxy/proxy-operations/create-geoproxy.operations';
import { useTrafficData } from '../../../../../state/proxy/traffic-data.atom';
import { hideProxyContextMenu } from '../../../../../state/proxy-select-menu.atom';
import IconProxyPlus from '../../../../../ui/gologin-header/icons/icon-proxy-plus';
import { sendActionAnalytics } from '../../../../common/api';
import { DEFAULT_SELECTED_GEOPROXY_COUNTRY } from '../../../constants';
import { generateProxyAnalyticsData } from '../../../proxy-helpers';
import { DivAnchor } from '../../../../../types';

export type ProxyGroupAddButtonProps = {
  country: string;
  groupId: string;
  availableTypes: GeoProxyType[];
  style: React.CSSProperties;
}

const ProxyGroupAddButton: React.FC<ProxyGroupAddButtonProps> = (props) => {
  const { country = DEFAULT_SELECTED_GEOPROXY_COUNTRY, groupId, availableTypes, style } = props;

  const proxyContextMenuProxy = useProxyContextMenuProxy();
  const geoProxyLastSelectedType = useGeoProxyLastSelectedType();
  const trafficData = useTrafficData();

  const [isArrowHovered, setIsArrowHovered] = useState<boolean>(false);
  const [proxyGroupAddButtonMenuAnchorEl, setProxyGroupAddButtonMenuAnchorEl] = useState<DivAnchor>(null);

  const toggleProxyGroupAddButtonMenuAnchorEl = (newAnchorEl: DivAnchor): void => {
    const updatedAnchorEl = proxyGroupAddButtonMenuAnchorEl ? null : newAnchorEl;
    setProxyGroupAddButtonMenuAnchorEl(updatedAnchorEl);
  };

  const closeProxyGroupAddButtonMenu = (): void => {
    setProxyGroupAddButtonMenuAnchorEl(null);
  };

  const isHighlighted = !!proxyGroupAddButtonMenuAnchorEl;

  const handleClick: React.MouseEventHandler<HTMLDivElement> = async () => {
    if (proxyContextMenuProxy) {
      hideProxyContextMenu();
    }

    const geoProxyFormSubmitResult = await createGeoProxy({
      groupId,
      country,
      selectedConnectionType: geoProxyLastSelectedType,
      availableConnectionTypes: availableTypes,
      profileId: '',
      trafficData,
      checkTooltipView: PROXY_CHECK_TOOLTIP_LOCATIONS.proxyGroupedListItem,
    });

    if (!geoProxyFormSubmitResult) {
      return;
    }

    if (typeof geoProxyFormSubmitResult === 'string') {
      return message.error(<Trans i18nKey={geoProxyFormSubmitResult} />);
    }

    const proxyAnalyticsData = generateProxyAnalyticsData(geoProxyFormSubmitResult);
    sendActionAnalytics(GROUPED_PROXY_MANAGER_ANALYTICS_EVENTS.createdLocationViaBuiltInProxyClickedOnAddServerInExpandedGroup, proxyAnalyticsData);
  };
  
  return (
    <ProxyGroupAddButtonWrapper
      onClick={handleClick}
      isAlwaysOpaque={true}
      isTextInactive={isArrowHovered}
      isHighlighted={isHighlighted}
      style={style}
    >
      <IconProxyPlus
        padding={0}
        iconColor='var(--98989F-header)'
        margin='0 8px 0 0'
      />
      <span>
        <Trans i18nKey='proxyManager.proxyGroupAddButton' />
      </span>
      <ProxyGroupAddButtonMenu
        groupId={groupId}
        country={country}
        geoProxyLastSelectedType={geoProxyLastSelectedType}
        availableTypes={availableTypes}
        proxyGroupAddButtonMenuAnchorEl={proxyGroupAddButtonMenuAnchorEl}
        toggleProxyGroupAddButtonMenuAnchorEl={toggleProxyGroupAddButtonMenuAnchorEl}
        closeProxyGroupAddButtonMenu={closeProxyGroupAddButtonMenu}
        setIsArrowHovered={setIsArrowHovered}
      />
    </ProxyGroupAddButtonWrapper>
  );
};

export default ProxyGroupAddButton;

import React from 'react';

import { EmptyProxyFlagWrapper, ExistingProxyFlagWrapper, IconBlurredProxyFlagWrapper } from './styles';
import { COUNTRIES_WITH_FLAGS } from '../../../../flag-icons';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import IconBlurredProxyFlag from '../../../ui/gologin-header/icons/icon-blurred-proxy-flag';
import IconCrossedProxyFlag from '../../../ui/gologin-header/icons/icon-crossed-proxy-flag';

const FLAG_ITEM_CLASS_NAME = 'flag-item';
export const SMALL_FLAG_HEIGHT = 10;
export const BIG_FLAG_HEIGHT = 12.86;
const DEFAULT_FLAG_MARGIN_RIGHT = 8;

type ProxyFlagProps = {
  countryCode: string;
  isCheckFailed?: boolean;
  height?: number;
}

const ProxyFlag: React.FC<ProxyFlagProps> = (props) => {
  const { countryCode = '', isCheckFailed = false, height = SMALL_FLAG_HEIGHT } = props;

  const lowerCaseCountryCode = countryCode.toLowerCase();
  const finalStyles: React.CSSProperties = {
    marginRight: DEFAULT_FLAG_MARGIN_RIGHT,
    height,
    minHeight: height,
    maxHeight: height,
  };

  const renderEmptyFlag = (): JSX.Element => {
    if (NEW_FEATURES.proxyGroupsV2) {
      return (
        <IconBlurredProxyFlagWrapper style={{ height }}>
          <IconBlurredProxyFlag />
        </IconBlurredProxyFlagWrapper>
      );
    }

    return (
      <IconCrossedProxyFlag
        padding={0}
        iconColor='var(--D9D9D9-proxy-manager-empty-flag)'
        iconHoveredColor='var(--D9D9D9-proxy-manager-empty-flag)'
        size={height}
      />
    );
  };

  if (!(lowerCaseCountryCode && COUNTRIES_WITH_FLAGS.includes(lowerCaseCountryCode))) {
    return (
      <EmptyProxyFlagWrapper style={finalStyles} className={FLAG_ITEM_CLASS_NAME}>
        {renderEmptyFlag()}
      </EmptyProxyFlagWrapper>
    );
  }

  return (
    <ExistingProxyFlagWrapper isSemiTransparent={isCheckFailed}>
      <div
        className={`${FLAG_ITEM_CLASS_NAME} fi fi-${countryCode.toLowerCase()}`}
        style={finalStyles}
      />
    </ExistingProxyFlagWrapper>
  );
};

export default ProxyFlag;

import { handleUpdateProxiesRequestError } from './handle-proxies-update-error';
import { updateProfileProxyWithErrorHandling } from './update-proxies.operations';
import { E_ANALYTICS_ACTIONS } from '../../../../common/constants/analytics';
import { determineIsTorOrFreeProxy } from '../../../../common/constants/types';
import { sendActionAnalytics } from '../../../features/common/api';
import {
  EMPTY_PROXY,
  PROXY_ID_TAG,
  UNUSED_ARTIFICIAL_GEOPROXY_LINK_ERROR_MESSAGE,
  UNUSED_ARTIFICIAL_GEOPROXY_LINKING_ERROR,
} from '../../../features/proxy/constants';
import { determineIsArtificialProxyId } from '../../../features/proxy/utils/proxy-id';
import { IProfile, IProxy } from '../../../interfaces';
import { sendReactErrorToSentry } from '../../../utils/sentry.helper';
import { getProfilesList, mapAndSetProfilesList, setProfilesList } from '../../profiles-list.atom';
import { updateProxySelectionDate } from '../proxy-groups/proxy-selection-dates.atom';
import { decrementProxyProfilesCount, incrementProxyProfilesCount } from '../proxy-list.atom';
import { generateProxyAnalyticsData } from '../../../features/proxy/proxy-helpers';

type ProfileProxyLinkParams = {
  profileId: string;
  proxy: IProxy;
  profilesListArg?: IProfile[];
  shouldUpdateProxyInList?: boolean;
}

type LinkedProxyInProxyListUpdateParams = Pick<ProfileProxyLinkParams, 'proxy'> & {
  prevProfileProxyId: string;
}

export type UpdateLinkedProxiesProps = {
  profileId: string;
  proxy: IProxy;
};

export const decrementProfileLinkedProxyProfilesCounter = (profileId: IProfile['id']): void => {
  const profilesList = getProfilesList();
  const profileInfo = profilesList.find(profile => profile.id === profileId);
  if (profileInfo && profileInfo.proxy?.id) {
    decrementProxyProfilesCount(profileInfo.proxy.id);
  }
};

const determineProxyProfileToLink = ({ profileId, proxy, profilesListArg }: ProfileProxyLinkParams): IProfile | null => {
  const profilesList = profilesListArg || getProfilesList();
  const profileInfo = profilesList.find(profile => profile.id === profileId);
  if (!profileInfo) {
    return null;
  }

  const { archivedProxy = null } = profileInfo;
  if (archivedProxy) {
    return null;
  }

  const prevProfileProxyId = profileInfo.proxy?.id || '';
  const isSameProxy = prevProfileProxyId === (proxy.id || '');
  if (isSameProxy) {
    return null;
  }

  return profileInfo;
};

const updateLinkedProxyInProxyList = ({ proxy, prevProfileProxyId }: LinkedProxyInProxyListUpdateParams): void => {
  if (proxy.id) {
    updateProxySelectionDate(proxy);
    incrementProxyProfilesCount(proxy?.id);
  }

  if (prevProfileProxyId) {
    decrementProxyProfilesCount(prevProfileProxyId);
  }
};

const updateLinkedProxyInProfilesList = (profileId: string, proxy: IProxy, isTorOrFreeProxy: boolean): void => {
  mapAndSetProfilesList(prevProfilesList => prevProfilesList.map((profile) => {
    if (profile.id !== profileId) {
      return profile;
    }

    const { archivedProxy } = profile;
    if (archivedProxy) {
      delete profile.archivedProxy;
    }

    let profilesCount = proxy.profilesCount || 0;
    if (isTorOrFreeProxy) {
      profilesCount = 0;
    }

    return {
      ...profile,
      proxy: {
        id: proxy.id,
        customName: proxy.customName,
        mode: proxy.mode,
        host: proxy.host,
        port: proxy.port,
        username: proxy.username,
        password: proxy.password,
        country: proxy.country,
        city: proxy.city,
        autoProxyRegion: proxy.autoProxyRegion,
        torProxyRegion: proxy.torProxyRegion,
        profiles: [],
        profilesCount,
        createdAt: proxy.createdAt,
        selectionDate: proxy.selectionDate,
      },
      proxyEnabled: proxy.mode !== 'none',
    };
  }));
};

export const updateLinkedProxiesInProfilesList = (profilesProxiesData: UpdateLinkedProxiesProps[]): void => {
  mapAndSetProfilesList(prevProfilesList => prevProfilesList.map((profile) => {
    const newProfileData = profilesProxiesData.find(({ profileId }) => profile.id === profileId);
    if (!newProfileData) {
      return profile;
    }

    const { proxy } = newProfileData;

    const isProxyLateToLink = profile.proxy.selectionDate > proxy.selectionDate;
    if (isProxyLateToLink) {
      return profile;
    }

    const { archivedProxy } = profile;
    if (archivedProxy) {
      delete profile.archivedProxy;
    }

    let profilesCount = proxy.profilesCount || 0;
    const isTorOrFreeProxy = determineIsTorOrFreeProxy(proxy);
    if (isTorOrFreeProxy) {
      profilesCount = 0;
    }

    return {
      ...profile,
      proxy: {
        id: proxy.id,
        customName: proxy.customName,
        mode: proxy.mode,
        host: proxy.host,
        port: proxy.port,
        username: proxy.username,
        password: proxy.password,
        country: proxy.country,
        city: proxy.city,
        autoProxyRegion: proxy.autoProxyRegion,
        torProxyRegion: proxy.torProxyRegion,
        profiles: [],
        profilesCount,
        createdAt: proxy.createdAt,
        selectionDate: proxy.selectionDate,
      },
      proxyEnabled: proxy.mode !== 'none',
    };
  }));
};

export const linkProfileProxyInState = (params: ProfileProxyLinkParams): void => {
  const {
    profileId,
    proxy,
    profilesListArg,
    shouldUpdateProxyInList = true,
  } = params;

  const profilesList = profilesListArg || getProfilesList();
  const profileToLink = determineProxyProfileToLink({ ...params, profilesListArg: profilesList });
  if (!profileToLink) {
    return;
  }

  const isTorOrFreeProxy = determineIsTorOrFreeProxy(proxy);
  if (!isTorOrFreeProxy && shouldUpdateProxyInList) {
    updateLinkedProxyInProxyList({
      proxy,
      prevProfileProxyId: profileToLink.proxy?.id || '',
    });
  }

  updateLinkedProxyInProfilesList(profileId, proxy, isTorOrFreeProxy);
};

const linkProfileProxyInBackend = async ({ profileId, proxy, profilesListArg }: ProfileProxyLinkParams): Promise<void> => {
  const profilesList = profilesListArg || getProfilesList();
  const updateProxyResponse = await updateProfileProxyWithErrorHandling({ profileId, proxy: { ...proxy } })
    .catch(() => setProfilesList(profilesList));

  if (updateProxyResponse?.error) {
    return handleUpdateProxiesRequestError(updateProxyResponse);
  }

  const proxyAnalyticsData = generateProxyAnalyticsData(proxy);
  sendActionAnalytics(E_ANALYTICS_ACTIONS.choseProxyViaProxyManager, proxyAnalyticsData).catch(() => null);
};

export const linkProfileProxy = async ({ profileId, proxy, shouldUpdateProxyInList = true }: ProfileProxyLinkParams): Promise<void> => {
  const profilesList = getProfilesList();
  const profileProxyLinkParams: ProfileProxyLinkParams = {
    profileId,
    proxy,
    profilesListArg: profilesList,
  };

  const profileToLink = determineProxyProfileToLink(profileProxyLinkParams);
  if (!profileToLink) {
    return;
  }

  const isTorOrFreeProxy = determineIsTorOrFreeProxy(proxy);
  if (!isTorOrFreeProxy && shouldUpdateProxyInList) {
    updateLinkedProxyInProxyList({
      proxy,
      prevProfileProxyId: profileToLink.proxy?.id || '',
    });
  }

  updateLinkedProxyInProfilesList(profileId, proxy, isTorOrFreeProxy);
  await linkProfileProxyInBackend(profileProxyLinkParams);
};

export const handleLinkArtificialProxy = (possiblyArtificialProxy: IProxy, proxyEntity: 'header' | 'proxy-item'): boolean => {
  const isArtificialProxy = determineIsArtificialProxyId(possiblyArtificialProxy.id);
  if (isArtificialProxy) {
    sendReactErrorToSentry({
      transactionName: UNUSED_ARTIFICIAL_GEOPROXY_LINKING_ERROR,
      message: UNUSED_ARTIFICIAL_GEOPROXY_LINK_ERROR_MESSAGE,
      tags: [
        ['proxy-entity', proxyEntity],
        [PROXY_ID_TAG, possiblyArtificialProxy.id],
      ],
    });
  }

  return isArtificialProxy;
};

export const unlinkProfileProxy = async (profileId: string): Promise<void> => {
  const { id: _unusedId, ...emptyProxy } = EMPTY_PROXY;
  await linkProfileProxy({ profileId, proxy: emptyProxy });
};

import React, { useRef, memo } from 'react';

import ProxyForm from './proxy-form';
import ProxyPlaceholder from './proxy-placeholder';
import ProxySelectorButtons from './proxy-selector-buttons';
import { ProxySelectorContentWrapper } from './styles';
import { IProxy } from '../../../interfaces';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { useProxyList } from '../../../state/proxy/proxy-list.atom';
import {
  closeProxyManager,
  openProxyManager,
  useProxyManagerState,
} from '../../../state/proxy/proxy-manager-modal-status.atom';
import { EMPTY_PROXY } from '../constants';
import { getIsProxyArchived } from '../proxy-helpers';
import { getProxyForProxySelector } from '../utils/find-proxy-in-list';
import { sendActionAnalytics } from '../../common/api';
import { E_ANALYTICS_ACTIONS } from '../../../../common/constants/analytics';

type ProxySelectorProps = {
  profileId: string;
  proxy?: IProxy;
  isInDrawer: boolean;
  isSharedProxy: boolean;
  proxyEnabled: boolean;
  localProxySelectorLocation: string;
}

const ProxySelector: React.FC<ProxySelectorProps> = (props) => {
  const {
    profileId, proxy: propProxy, isInDrawer,
    isSharedProxy, proxyEnabled, localProxySelectorLocation,
  } = props;

  const proxyList = useProxyList();
  const { currentProfileId: proxyManagerProfileId, proxySelectorLocation } = useProxyManagerState();

  const containerElement = useRef<HTMLDivElement>(null);

  const proxy = proxyEnabled ? propProxy : EMPTY_PROXY;
  const isProxyEditMode = !!profileId && profileId === proxyManagerProfileId &&
    (proxySelectorLocation === 'no-location' || proxySelectorLocation === localProxySelectorLocation);

  const isNoProxy = !proxy || proxy.mode === 'none';
  const shouldProxyTakeAllWidth = isInDrawer && !!NEW_FEATURES.drawer;

  const proxyToRender = getIsProxyArchived(propProxy) ? propProxy : getProxyForProxySelector(proxyList, proxy);

  const renderContent = (): JSX.Element => {
    const shouldShowProxyPlaceholder = !proxy || (isNoProxy && !isProxyEditMode);
    if (!getIsProxyArchived(proxyToRender) && shouldShowProxyPlaceholder) {
      return <ProxyPlaceholder isInDrawer={isInDrawer} />;
    }

    return (
      <ProxyForm
        currentProxy={proxyToRender}
        profileId={profileId}
        isSharedProxy={isSharedProxy}
        editProxyMode={isProxyEditMode}
        isInDrawer={isInDrawer}
        localProxySelectorLocation={localProxySelectorLocation}
        selectorContainerElement={containerElement}
      />
    );
  };

  const handleContainerClick = (): void => {
    if (!profileId) {
      return;
    }

    if (proxyManagerProfileId) {
      closeProxyManager();

      return;
    }

    if (!containerElement.current) {
      console.error('No containerElement.current');

      return;
    }

    sendActionAnalytics(E_ANALYTICS_ACTIONS.openedProxyManagerInProfilesTableClickOnCell);
    openProxyManager({
      modalView: 'proxy-list',
      currentProxy: proxyToRender,
      containerElement: containerElement.current,
      currentProfileId: profileId,
      proxySelectorLocation: localProxySelectorLocation,
    });
  };

  const renderRightControls = (): JSX.Element|null => {
    const isInTableOrNewDrawer = NEW_FEATURES.drawer || !isInDrawer;

    return (
      <ProxySelectorButtons
        isVisible={isProxyEditMode || !isInTableOrNewDrawer}
        profileId={profileId}
        isInDrawer={isInDrawer}
        selectorContainerElement={containerElement}
        localProxySelectorLocation={localProxySelectorLocation}
      />
    );
  };

  return (
    <ProxySelectorContentWrapper
      ref={containerElement}
      onClick={handleContainerClick}
      shouldProxyTakeAllWidth={shouldProxyTakeAllWidth}
      isProxyEditMode={isProxyEditMode}
      newStyle={!!NEW_FEATURES.header}
      isInDrawer={isInDrawer}
    >
      {renderContent()}
      {renderRightControls()}
    </ProxySelectorContentWrapper>
  );
};

export default memo(ProxySelector);

import { AFFILIATE_COOKIE_NAME, API_BASE_URL, E_APP_TYPE } from '../../../../common/constants/constants';
import { getGoogleClientId } from '../../../initGA';
import { http } from '../../../services';
import { saveToken } from '../../../services/http/storage';
import { getLocalUserDevice } from '../../../state/local-user-device.atom';
import { getCanvasAndFontsHash, getCanvasHash } from '../../../utils/canvas-hash';
import { retriveCookie } from '../../../utils/document-cookie';
import { jsFonts } from '../../../utils/fonts-hash';
import { getUserScreenInfo, getUserScreenResolution } from '../../../utils/user-hardware.utils';
import { getGologinMetaHeader, getUserOS, OS, OS_SPEC } from '../../../utils/user-os';
import { SHARE_LINK_KEY } from '../../share-links/constants';
import { getDomainWithPath } from '../helpers/utils';

const isElectron = !!window.require;

interface IUpdateCommonEvents {
  isAdvertising: boolean;
  fontsHash: any;

  googleClientId?: string;
  utm?: string;
}
export const updateCommonEvents = async (events: IUpdateCommonEvents): Promise<void> => {
  const requestBody = JSON.stringify({
    visitedRegisterPage: true,
    googleClientId: events.googleClientId || '',
    isAdvertising: events.isAdvertising,
    utm: events.utm,
    fontsHash: events.fontsHash,
  });

  await fetch(`${API_BASE_URL}/user-stats/update-common-events`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: requestBody,
  }).catch(() => null);
};

export interface ICreateUser {
  email: string;
  password: string;
  passwordConfirm: string;
  captchaToken: string;
  localGoogleClientId?: string | null;
}

type PostUserOpts = ICreateUser & {
  googleClientId: string;
  fromApp: boolean;
  fromAppTrue: boolean;
  canvasAndFontsHash: string;
  affiliate: string;
  userOs: OS;
  osSpec: OS_SPEC | undefined;
  resolution: string;
  registrationLead: {
    url: string;
  };
  fontsHash?: string;
  canvasHash?: string | number;
  shareLink?: string;
}

export async function createUser(userData: ICreateUser): Promise<any> {
  const affiliateCookie = retriveCookie(AFFILIATE_COOKIE_NAME);

  const googleClientId = await getGoogleClientId();

  const fontsHash = jsFonts();
  const canvasHash = getCanvasHash();
  const canvasAndFontsHash = getCanvasAndFontsHash();
  const userOsParams = await getUserOS();

  const localUserDeviceInfo = getLocalUserDevice();
  let { screen } = localUserDeviceInfo;
  if (!screen.width) {
    screen = await getUserScreenInfo();
  }

  const userScreenResolution = getUserScreenResolution(screen);

  const requestObject: PostUserOpts = {
    ...userData,
    googleClientId,
    fromApp: isElectron,
    fromAppTrue: isElectron,
    canvasAndFontsHash,
    affiliate: affiliateCookie || '',
    fontsHash,
    userOs: userOsParams.name,
    osSpec: userOsParams.spec,
    resolution: userScreenResolution,
    registrationLead: {
      url: isElectron ? E_APP_TYPE.desktop : getDomainWithPath(),
    },
  };

  if (canvasHash) {
    requestObject.canvasHash = canvasHash;
  }

  const shareLink = sessionStorage.getItem(SHARE_LINK_KEY);
  if (shareLink) {
    requestObject.shareLink = shareLink;
  }

  const requestBody: string = JSON.stringify(requestObject);

  const registeredAccount = await http(`${API_BASE_URL}/user?free-plan=true&registerAs=workspaces`, {
    method: 'POST',
    body: requestBody,
    headers: {
      'GoLogin-Meta-Header': await getGologinMetaHeader(),
    },
  });

  saveToken(registeredAccount.body.token, 'createUser');

  return registeredAccount;
}

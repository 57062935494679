import moment from 'moment';

import { updateLinkedProxiesInProfilesList, UpdateLinkedProxiesProps } from './link-proxy.operations';
import { LocalizationErrorMessages } from '../../../../common/constants/localization-error-messages';
import { updateProxyAndProfileProxyData } from '../../../features/common/update-local-profile-data';
import { createProxiesRequest } from '../../../features/proxy/api';
import { getCreateProxyValidationError, isCreateProxiesValidationError } from '../../../features/proxy/proxy-helpers';
import { E_PROXY_FOLDERS } from '../../../features/proxy/proxy-page/constants/proxy-folders';
import { areProxyFieldsEqual, determineAreProxyCheckSensitiveFieldsEqual } from '../../../features/proxy/utils/find-proxy-in-list';
import { IProxy } from '../../../interfaces';
import { isProxyDataValid } from '../../../utils/proxy.validation-message';
import { ISetProxyStatusParams } from '../../proxies.context/interfaces/ISetProxyStatusParams';
import { PROXY_CHECK_TOOLTIP_LOCATIONS } from '../proxy-check/constants';
import { showProxyCheckTooltip } from '../proxy-check/proxy-check-tooltip.atom';
import { addProxyStatuses, removeProxyStatuses, updateProxyStatuses } from '../proxy-check/proxy-statuses.atom';
import { ProxyCheckTooltipLocation } from '../proxy-check/types/proxy-check-tooltip-location.type';
import { updateProxyItem, updateProxyItems, upsertOneProxy } from '../proxy-list.atom';
import { mapAndSetProxiesTableList } from '../proxy-table/proxies-table-list.atom';
import { getSelectedProxyFolder, setSelectedProxyFolder } from '../proxy-table/proxy-folders.atom';
import { updateProxiesTableSelectedIds } from '../proxy-table/proxy-table-selected-ids.atom';

// eslint-disable-next-line no-shadow
export enum MULTIPLE_PROXIES_ADD_ERRORS {
  couldntParseProxiesError = 'couldntParseProxiesError',
  limitReachedError = 'limitReachedError',
  invalidProxiesError = 'invalidProxiesError',
}

export interface IMultipleProxiesAddError {
  message: MULTIPLE_PROXIES_ADD_ERRORS;
  count?: number;
  isCustomError?: boolean;
}

interface IMultipleProxiesAddResultSuccess {
  proxies: IProxy[];
  error: null;
}

interface IMultipleProxiesAddResultError {
  error: IMultipleProxiesAddError;
}

export type IMultipleProxiesAddResult = IMultipleProxiesAddResultSuccess | IMultipleProxiesAddResultError;

type GroupedData = {
  proxy: IProxy;
  profileIds: string[];
};

type GroupProfilesByProxiesParams = {
  selectedProfiles: string[];
  pastedProxiesData: IProxy[];
  upsertedProxies: IProxy[];
};

const groupProfilesByProxies = ({
  selectedProfiles,
  pastedProxiesData,
  upsertedProxies,
}: GroupProfilesByProxiesParams) => selectedProfiles.reduce((results: GroupedData[], profileId, index) => {
  const proxyForProfile = pastedProxiesData[index];
  if (!proxyForProfile) {
    return results;
  }

  const areProxiesEqual = (proxyData: IProxy) => determineAreProxyCheckSensitiveFieldsEqual(proxyData, proxyForProfile, { shouldCompareMode: false });
  const upsertedProxy = upsertedProxies.find((upsertedProxy) => areProxiesEqual(upsertedProxy));
  if (!upsertedProxy) {
    return results;
  }

  const groupIndex = results.findIndex((result) => {
    const proxyFromGroup = result.proxy;

    return areProxiesEqual(proxyFromGroup);
  });

  if (groupIndex > -1) {
    results[groupIndex].profileIds.push(profileId);
  } else {
    results.push({ proxy: upsertedProxy, profileIds: [profileId] });
  }

  return results;
}, []);

const getUniqueProxies = (proxies: IProxy[]): IProxy[] => proxies.reduce<IProxy[]>((acc, proxyToAdd) => {
  const isProxyAlreadyAdded = acc.some(addedProxy => areProxyFieldsEqual(addedProxy, proxyToAdd));
  if (isProxyAlreadyAdded) {
    return acc;
  }

  acc.push(proxyToAdd);

  return acc;
}, []);

const validateMultipleProxies = (proxies: IProxy[], isProxyPage: boolean): IMultipleProxiesAddResult => {
  if (!proxies.length) {
    return {
      error: { message: MULTIPLE_PROXIES_ADD_ERRORS.couldntParseProxiesError },
    };
  }

  const uniqueProxies = getUniqueProxies(proxies);

  const proxiesMultipleAddLimit = isProxyPage ? 30 : 50;
  const slicedProxes = uniqueProxies.slice(0, proxiesMultipleAddLimit);
  if (slicedProxes.length < uniqueProxies.length) {
    return {
      error: { message: MULTIPLE_PROXIES_ADD_ERRORS.limitReachedError, count: proxiesMultipleAddLimit },
    };
  }

  const proxiesValidated = slicedProxes.filter((proxyToValidate) => isProxyDataValid({
    host: proxyToValidate.host,
    port: proxyToValidate.port.toString(),
    shouldThrowNotification: false,
  }));

  if (proxiesValidated.length < slicedProxes.length) {
    return {
      error: { message: MULTIPLE_PROXIES_ADD_ERRORS.invalidProxiesError, count: slicedProxes.length - proxiesValidated.length },
    };
  }

  return { proxies: slicedProxes, error: null };
};

const upsertProxyAfterCreating = (createdProxies: IProxy[]): void => {
  createdProxies.forEach(upsertOneProxy);
  mapAndSetProxiesTableList((prevProxies) => {
    const newProxies = [...prevProxies];
    newProxies.unshift(...createdProxies);

    return newProxies;
  }, [], false);
};

const updateProxyAfterCreating = (createdProxies: IProxy[], isProxyPage: boolean): void => {
  if (!isProxyPage) {
    return updateProxyItems(createdProxies);
  }

  mapAndSetProxiesTableList((prevProxies) => prevProxies.map(((prevProxy) => {
    const existingProxy = createdProxies.find(({ id }) => id === prevProxy.id);
    if (!existingProxy) {
      return prevProxy;
    }

    return {
      ...prevProxy,
      ...existingProxy,
    };
  })), [], isProxyPage);
};

const updateProxyAfterChecking = (listedProxy: IProxy, checkedProxy: ISetProxyStatusParams, isProxyPage: boolean): IProxy => {
  const proxyToShowInTooltip: IProxy = { ...listedProxy, ...checkedProxy, checkDate: moment().toDate() };
  if (isProxyPage) {
    mapAndSetProxiesTableList((prevProxies) => prevProxies.map(((prevProxy) => {
      if (prevProxy.id !== listedProxy.id) {
        return prevProxy;
      }

      return {
        ...prevProxy,
        ...checkedProxy,
        checkDate: moment().toDate(),
      };
    })), [], isProxyPage);
  } else {
    updateProxyItem(proxyToShowInTooltip);
  }

  return proxyToShowInTooltip;
};

type ProxyStatusCheckParams = {
  proxyToCheck: IProxy;
  selectedProfiles: string[];
  proxyCheckTooltipView: ProxyCheckTooltipLocation;
  shouldShowCheckInfoTooltip: boolean;
  isProxyPage: boolean;
  isManyProxies?: boolean;
}

const checkProxyStatus = async ({
  proxyToCheck,
  selectedProfiles,
  proxyCheckTooltipView,
  shouldShowCheckInfoTooltip,
  isProxyPage,
  isManyProxies = false,
}: ProxyStatusCheckParams): Promise<void> => {
  const checkedProxy = await updateProxyStatuses({ proxies: [proxyToCheck] });
  let proxyToShowInTooltip: IProxy|null = null;
  if (checkedProxy) {
    proxyToShowInTooltip = updateProxyAfterChecking(proxyToCheck, checkedProxy, isProxyPage);
  }

  const profilesDataToUpdate: UpdateLinkedProxiesProps[] = [];
  selectedProfiles.forEach((profileId) => {
    if (checkedProxy && proxyToShowInTooltip) {
      updateProxyAndProfileProxyData({
        currentProxyData: proxyToCheck,
        newProxyData: proxyToShowInTooltip,
        statusParams: checkedProxy,
        profileId,
      });

      profilesDataToUpdate.push({
        profileId,
        proxy: proxyToShowInTooltip,
      });
    }
  });

  if (!isManyProxies) {
    updateLinkedProxiesInProfilesList(profilesDataToUpdate);
  }

  if (!(proxyToShowInTooltip && shouldShowCheckInfoTooltip)) {
    return;
  }

  showProxyCheckTooltip({
    profileIds: selectedProfiles,
    proxies: [proxyToShowInTooltip],
    view: proxyCheckTooltipView,
    timeout: 2 * 1000,
  });
};

type MultipleProxiesAddParams = {
  proxyList: IProxy[];
  proxies: IProxy[];
  selectedProfiles?: string[];
  proxyCheckTooltipView?: ProxyCheckTooltipLocation;
  isImportingProxies?: boolean;
  isPastingProxies?: boolean;
}

export const addMultipleProxies = async ({
  proxyList = [],
  proxies = [],
  selectedProfiles = [],
  proxyCheckTooltipView = null,
  isImportingProxies = false,
  isPastingProxies = false,
}: MultipleProxiesAddParams): Promise<IMultipleProxiesAddResult> => {
  const isProxyPage = proxyCheckTooltipView === PROXY_CHECK_TOOLTIP_LOCATIONS.proxyPageTable;
  const validatedProxiesResult = validateMultipleProxies(proxies, isProxyPage);
  if (validatedProxiesResult.error) {
    return validatedProxiesResult;
  }

  const proxiesToAdd = validatedProxiesResult.proxies.map((pastedProxy): IProxy => {
    const existingProxy = proxyList.find((listedProxy) =>
      determineAreProxyCheckSensitiveFieldsEqual(listedProxy, pastedProxy, { shouldCompareMode: false }));

    if (existingProxy && existingProxy.status) {
      return {
        ...pastedProxy,
        mode: existingProxy.mode,
      };
    }

    return pastedProxy;
  });

  type IProxyCustomError = { error: IMultipleProxiesAddError };
  const requestedProxies: IProxy[] | IProxyCustomError = await createProxiesRequest(proxiesToAdd).catch((error) => {
    const errorMessage = error?.body?.message;
    const isValidationError = isCreateProxiesValidationError(errorMessage);
    if (isValidationError) {
      const proxiesMultipleAddLimit = isProxyPage ? 30 : 50;

      return getCreateProxyValidationError(errorMessage, proxiesMultipleAddLimit);
    }

    return {
      error: {
        message: errorMessage || LocalizationErrorMessages.SomethingWentWrongAgainLater,
        isCustomError: true,
      },
    };
  });

  const isProxyCreationError = (requestProxiesResult: IProxy[] | IProxyCustomError): requestProxiesResult is IProxyCustomError =>
    !!(requestProxiesResult as IProxyCustomError).error;

  if (isProxyCreationError(requestedProxies)) {
    return requestedProxies;
  }

  if (isProxyPage) {
    const requestedProxyIds = requestedProxies.map(({ id }) => id);
    updateProxiesTableSelectedIds(requestedProxyIds);
  }

  const shouldShowCheckInfoTooltip = requestedProxies.length === 1;
  const { editedProxies, addedProxies } = requestedProxies.reduce<{
    editedProxies: IProxy[];
    addedProxies: IProxy[];
  }>((acc, requestedProxy) => {
    const existingProxy = proxyList.find(listedProxy => listedProxy.id === requestedProxy.id);
    if (existingProxy) {
      acc.editedProxies.push(existingProxy);
    } else {
      acc.addedProxies.push(requestedProxy);
    }

    return acc;
  }, { editedProxies: [], addedProxies: [] });

  if (editedProxies.length) {
    updateProxyAfterCreating(editedProxies, isProxyPage);
  }

  if (addedProxies.length) {
    upsertProxyAfterCreating(addedProxies);
  }

  if (isProxyPage && getSelectedProxyFolder() !== E_PROXY_FOLDERS.allProxies) {
    setSelectedProxyFolder(E_PROXY_FOLDERS.allProxies);
  }

  const upsertedProxies = [...addedProxies, ...editedProxies];

  addProxyStatuses(upsertedProxies, selectedProfiles);

  if (!upsertedProxies.length) {
    return {
      proxies: upsertedProxies,
      error: null,
    };
  }

  if (upsertedProxies.length === 1) {
    checkProxyStatus({
      proxyToCheck: upsertedProxies[0],
      selectedProfiles,
      proxyCheckTooltipView,
      shouldShowCheckInfoTooltip,
      isProxyPage,
    });
  } else {
    if (isImportingProxies || isPastingProxies) {
      upsertedProxies.forEach((upsertedProxy) => {
        checkProxyStatus({
          proxyToCheck: upsertedProxy,
          selectedProfiles: [],
          proxyCheckTooltipView,
          shouldShowCheckInfoTooltip,
          isProxyPage,
          isManyProxies: true,
        });
      });

      return {
        proxies: upsertedProxies,
        error: null,
      };
    }

    const profilesDataToUpdate: UpdateLinkedProxiesProps[] = [];
    // группировка профилей по проксям, для оптимизации чека прокси
    const groupedByProxyProfiles = groupProfilesByProxies({
      selectedProfiles,
      pastedProxiesData: proxies,
      upsertedProxies,
    });

    const maxCountCheckedProxies = 20;
    groupedByProxyProfiles.forEach(({ proxy, profileIds }, index) => {
      profileIds.forEach((profileId) => {
        profilesDataToUpdate.push({ profileId, proxy });
      });

      if (index >= maxCountCheckedProxies) {
        removeProxyStatuses([proxy], profileIds.filter(Boolean));

        return;
      }

      checkProxyStatus({
        proxyToCheck: proxy,
        selectedProfiles: profileIds,
        proxyCheckTooltipView,
        shouldShowCheckInfoTooltip,
        isProxyPage,
        isManyProxies: true,
      });
    });

    updateLinkedProxiesInProfilesList(profilesDataToUpdate);
  }

  return {
    proxies: upsertedProxies,
    error: null,
  };
};

import React from 'react';

import {
  DescriptionPlan,
  MenuRowContainer,
  MenuRowText,
  MenuHeaderItem,
  WorkspaceContainerDescription,
} from './styles';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { greenMain, greyInformers } from '../../style-templates/colors';
import { IconCheck } from '../icons';
import { IconBriefcase } from '../icons/icon-briefcase';

type WorkspaceInfoProps = {
  name: string;
  profiles: number;
  planMaxProfiles: number;
  planName: string;
  onClick: () => void;
  isSelected: boolean;
}

const WorkspaceInfo: React.FC<WorkspaceInfoProps> = (props): JSX.Element => {
  const { name, profiles, planMaxProfiles, planName, onClick, isSelected } = props;

  let profilesText = `${profiles} / ${planMaxProfiles} ${planName}`;
  if (!planMaxProfiles) {
    profilesText = `${profiles} / ${planName}`;
  }

  return (
    <MenuHeaderItem onClick={onClick} isActive={true} newStyle={NEW_FEATURES.header}>
      <MenuRowContainer>
        <IconBriefcase padding={0} styleType='lightGray' iconColor={greyInformers} />
        <MenuRowText style={{ margin: '0 8px', cursor: 'pointer' }}>
          {name}
        </MenuRowText>
        {isSelected ? (
          <IconCheck
            padding={0}
            styleType='green'
            iconColor={greenMain}
            size={12}
          />
        ) : null}
      </MenuRowContainer>
      <WorkspaceContainerDescription>
        <DescriptionPlan>
          {profilesText}
        </DescriptionPlan>
      </WorkspaceContainerDescription>
    </MenuHeaderItem>
  );
};

export default WorkspaceInfo;

import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { Resizable, ResizeCallbackData } from 'react-resizable';

import AllRowsSelection from './all-rows-selection';
import { SorterCol } from './sorter-col';
import { GologinTableHeaderColumnContainer, GologinTableHeaderColumnContent, ResizableHandle } from './styles';
import { IProfilesTableColumn } from '../../features/quickProfiles/components/interfaces/app-settings.interface';
import { NEW_FEATURES } from '../../state/feature-toggle/new-features';
import { onProfilesTableSortClick } from '../../state/profiles-table/profiles-sort.atom';
import { toggleAllProfilesSelected, useAllProfilesSelectionStatus } from '../../state/profiles-table-selected-ids.atom';
import TooltipWrapper from '../tooltip-custom/tooltip-wrapper';

export interface IResizeProps {
  onResize: (columnIndex: number, event: React.SyntheticEvent<Element>, data: ResizeCallbackData) => void;
  resizeStart: () => void;
  resizeEnd: () => void;
}

export interface IGologinTableHeaderColumn extends IResizeProps {
  column: IProfilesTableColumn;
  columnIndex: number;
}

const GologinTableHeaderColumn: FC<IGologinTableHeaderColumn> = (props) => {
  const { column, columnIndex, onResize, resizeStart, resizeEnd } = props;
  const {
    colName,
    label: columnLabel,
    labelTooltip: columnLabelTooltip,
    sortField,
    width: colWidth,
    minWidth: colMinWidth,
    headerWidth,
    leftPadding,
  } = column;

  const width = headerWidth ?? colWidth;
  const minWidth = (headerWidth || headerWidth === 0) ? Math.min(headerWidth, colMinWidth) : colMinWidth;

  const onResizeHandle = (event: React.SyntheticEvent<Element>, data: ResizeCallbackData): void => {
    onResize(column.originalColumnIndex ?? columnIndex, event, data);
  };

  const isDragEnabled = true;
  const className = 'gologin-table-header-column ' + column.className;
  let contentClassName = '';
  if (isDragEnabled) {
    contentClassName += ' dragHandler';
  }

  const onColumnClick = (): void => {
    if (sortField) {
      onProfilesTableSortClick(sortField);
    }
  };

  if (colName === 'selection' as any) {
    return (
      <AllRowsSelection
        className={className}
        width={width}
        useSelectionStatus={useAllProfilesSelectionStatus}
        toggleAllRowsSelection={toggleAllProfilesSelected}
      />
    );
  }

  const renderColumnLabel = (): JSX.Element => {
    if (!columnLabelTooltip) {
      return (
        <span className='nowrap'>
          {columnLabel}
        </span>
      );
    }

    return (
      <span className='nowrap'>
        <TooltipWrapper
          isTransition={true}
          value={<Trans i18nKey={columnLabelTooltip} />}
        >
          {columnLabel}
        </TooltipWrapper>
      </span>
    );
  };

  return (
    <Resizable
      width={width}
      height={0}
      minConstraints={[minWidth, 0]}
      handle={
        <ResizableHandle
          newStyle={!!NEW_FEATURES.header}
          className='react-resizable-handle'
          onClick={(event): void => {
            event.stopPropagation();
          }}
        />
      }
      className='noselect'
      onResize={onResizeHandle}
      onResizeStart={resizeStart}
      onResizeStop={resizeEnd}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <GologinTableHeaderColumnContainer
        style={{ width, paddingLeft: leftPadding }}
        className={className}
        onClick={onColumnClick}
        newStyle={!!NEW_FEATURES.header}
      >
        <GologinTableHeaderColumnContent
          className={contentClassName}
        >
          {renderColumnLabel()}
          {sortField && NEW_FEATURES.header ? <SorterCol sorterField={sortField} /> : null}
          {column.actionsSlot}
        </GologinTableHeaderColumnContent>
      </GologinTableHeaderColumnContainer>
    </Resizable>
  );
};

export default GologinTableHeaderColumn;

import React from 'react';
import { Trans } from 'react-i18next';

import { OptionItem, SearchMenuPopup } from './styles';
import { IconCheck } from '../icons';

type SearchMeanuProps = {
  anchorEl: HTMLElement|null;
  onCloseSearchPopup: () => void;
  onPinSearch: () => void;
  isSearchPinned: boolean;
}

const SearchMenu: React.FC<SearchMeanuProps> = (props) => {
  const { anchorEl, onCloseSearchPopup, onPinSearch, isSearchPinned } = props;

  return (
    <SearchMenuPopup
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onCloseSearchPopup}
      transitionDuration={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      style={{ zIndex: 900 }}
    >
      <OptionItem style={{ margin: 0 }} onClick={onPinSearch}>
        <span>
          <Trans i18nKey='header.searchProfile.keepAlwaysOpen' />
        </span>
        {isSearchPinned ? <IconCheck iconColor='var(--36363D)' padding={0} /> : null}
      </OptionItem>
    </SearchMenuPopup>
  );
};

export default SearchMenu;

export const GEO_PROXY_TYPE_HINT_KEY_BASE = 'proxyManager.proxyGroupAddButtonMenuText.';
export const GEO_PROXY_TYPE_HINTS = {
  resident: `${GEO_PROXY_TYPE_HINT_KEY_BASE}resident`,
  mobile: `${GEO_PROXY_TYPE_HINT_KEY_BASE}mobile`,
  dataCenter: `${GEO_PROXY_TYPE_HINT_KEY_BASE}dataCenter`,
  default: `${GEO_PROXY_TYPE_HINT_KEY_BASE}default`,
  trialDefault: `${GEO_PROXY_TYPE_HINT_KEY_BASE}trialDefault`,
} as const;

export type GeoProxyTypeHint = typeof GEO_PROXY_TYPE_HINTS[keyof typeof GEO_PROXY_TYPE_HINTS];

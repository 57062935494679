import { useTranslate } from '@tolgee/react';
import React, { FC } from 'react';

import { PreviewCardContent } from './preview-card-content';
import { PreviewCardFooter } from './preview-card-footer';
import {
  Card,
  CardTag,
  CardTitle,
  CardContentContainer,
  DescriptionPlan,
} from './styles';
import { IPlan } from '../../../../interfaces/plan';
import { IMappedPlan } from '../../interfaces';
import { PopularPlanText } from '../domElements';

interface IPreviewCard {
  previewPlan: IMappedPlan;
  selectedDiscount: string;
  getPlanInfo: (planId: string, field: keyof IPlan) => JSX.Element | string;
  getCustomPlanSelect?: () => JSX.Element;
  onPlanSelect: (id: string, isUpgrade?: boolean) => Promise<void>;
}

const PreviewCard: FC<IPreviewCard> = (props): JSX.Element => {
  const {
    previewPlan,
    selectedDiscount,
    getPlanInfo,
    getCustomPlanSelect,
    onPlanSelect,
  } = props;

  const {
    name,
    maxProfiles,
    maxAccountShares,
    background,
    isPopular,
    isCustomPlan,
    geoProxyTraffic,
  } = previewPlan;

  const { t: translation } = useTranslate();

  const getMaxProfilesItem = (): JSX.Element => {
    if (isCustomPlan && getCustomPlanSelect) {
      return getCustomPlanSelect();
    }

    return (
      <DescriptionPlan>
        {maxProfiles}
      </DescriptionPlan>
    );
  };

  return (
    <Card backgroundColor={background}>
      <CardContentContainer>
        <div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CardTag isCustomPlan={isCustomPlan}>
              {name}
            </CardTag>
            {isPopular ?
              <PopularPlanText>
                {translation('pricing.popularPlan.text')}
              </PopularPlanText> :
              null}
          </div>
          <CardTitle isCustomPlan={isCustomPlan}>
            {translation(`pricing.${name.toLowerCase()}Plan.description`)}
        </CardTitle>
          <PreviewCardContent
            isCustomPlan={isCustomPlan}
            getMaxProfilesItem={getMaxProfilesItem}
            planName={name}
            maxAccountShares={maxAccountShares}
            geoProxyTraffic={geoProxyTraffic}
          />
        </div>
        <PreviewCardFooter
          previewPlan={previewPlan}
          onPlanSelect={onPlanSelect}
          selectedDiscount={selectedDiscount}
          getPlanInfo={getPlanInfo}
        />
      </CardContentContainer>
    </Card>
  );
};

export default PreviewCard;

/* eslint-disable max-lines */
import { CloseOutlined } from '@material-ui/icons';
import { useTranslate } from '@tolgee/react';
import { Dropdown, Icon } from 'antd';
import React, { FC, memo, useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import CryptoForm from '../crypto-form';
import {
  AnnuallyTxt,
  CustomAnnuallyMenu,
  CustomItemMenu,
  CustomPaymentModal,
  GreenCheckoutIcon,
  HeaderTitleContainer,
  LoadingContainer,
  ModalCheckMarkContainer,
  ModalCheckMarkTxt,
  ModalDivider,
  ModalHeaderBoldTxt,
  ModalHeaderTxt,
  ModalParamsLeftTxt,
  ModalParamsLine,
  ModalParamsRightTxt,
  ModalSwitcher,
  ModalSwitcherItem,
  ModalSwitcherItemSelected,
  PaymentModalCancelContainer,
} from '../domElements';
import PaymentIconsBar from '../external/payment-icons';
import PayPalForm from '../paypal-form';
import StripeForm from '../stripe-form';
import { PaymentModalIconsBarContainer, ThreeDSecureStyled } from '../styles';
import { E_ACTION_INFO, E_ANALYTICS_ACTIONS } from '../../../../../common/constants/analytics';
import { useQuery } from '../../../../hooks';
import { E_DISCOUNT_TYPE } from '../../../../interfaces';
import { IPlan } from '../../../../interfaces/plan';
import {
  convertDiscountIdToPeriod,
  setPaymentModalPaymentType,
  useCurrentTabShownCurrency,
  useDiscountObjById,
  usePlansAddonPricingConfig,
  useModalShownTabsConfig,
  usePaymentModalPaymentType,
  usePlanObjById,
  usePlansList,
  usePriceConfig,
  usePromoDiscountAvaliable,
  useDefaultCountryPaymentType,
} from '../../../../state';
import { useIsWorkspaceLimitsAvaliable, useWorkspaceLimits, useWorkspacePaidProxyData } from '../../../../state/limits/workspace-limits.atom';
import {
  useIsLimitsPurchase,
  useHasAddonLimitsChosen,
  useSelectedDiscount,
  useSelectedPlan,
  useAddonLimitsChosen,
  useIsProxydataPurchase,
  useHasAddonProxyDataChosen,
  useChosenLimitsList,
  useChosenProxyDataList,
} from '../../../../state/pricing/pricing-page.atom';
import {
  setShouldShowUpgradeDiscountLine,
  useShouldShowUpgradeDiscountLine,
  useIsUpgradeDiscountAvaliable,
  useUpgradeDiscount,
} from '../../../../state/pricing/upgrade-discount.atom';
import IconPaymentModalCheckmark from '../../../../ui/icons/IconPaymentModalCheckmark';
import { sendActionAnalytics } from '../../../common/api';
import { ANNUAL_DISCOUNT, APPLE_PAY, GOOGLE_PAY, MONTH_DISCOUNT } from '../../../modalsComponents/components/checkout-modal/constants';
import { isDiscountAnnual, getIsGooglePay, getNextPaymentDate, getPaymentTypes } from '../../../modalsComponents/components/checkout-modal/utils';
import SuccessPaymentModal from '../../../modalsComponents/components/success-payment-modal';
import { fetchUserVisitedPageAnalytics } from '../../api';
import { DISCOUNT_IDS, EMPTY_UPGRADE_DISCOUNT } from '../../constants';
import { E_PERIOD, ICalcModalHeightParams, IUpgradeDiscount, PaymentType } from '../../interfaces';
import { QUICK_PRICING_MODAL_Z_INDEX } from '../../styles';
import { Price, calcModalHeight, getAnalyticsPaymentType, getBaseLimitsFromPlan, retrieveTrafficPrice, getPlanByPlanId, substractLimits } from '../../utils';
import PaddleForm from '../paddle-form';
import { useTrafficData } from '../../../../state/proxy/traffic-data.atom';
import YookassaForm from '../yokassa-form';
import PaymentModalLine from './payment-modal-line';
import { deriveAddonProxyDataForProxyLine, retrieveIsSamePlanAndPeriod, deriveShouldShowLimitsLine, deriveShouldShowProductLine, deriveShouldShowProxyDataLine, deriveShouldDiscountLineBeActive } from './pure/functions.pure';
import { useWorkspaceSubscription } from '../../../../state/workspace/workspace-subscription.atom';
import { PaymentModalStateParams } from './pure/types';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';

export interface IPlanPriceParams {
  isDiscount: boolean;
  isAnnual?: boolean;
  isMobilePayment?: boolean;
  upgradeDiscount?: boolean;
}

interface IPaymentModal {
  modalVisible: boolean;
  changeMonthlyPaymentPeriod: (checked: boolean) => any;
  closePaymentModal: () => void;
  getPlanInfo: (planId: string, field: keyof IPlan) => JSX.Element | string;
  workspaceId?: string;
  userData: {
    email: string;
    plan: IPlan;
    isLastPaymentTrialEnded: boolean;
  };
}

const PaymentModal: FC<IPaymentModal> = (props) => {
  const {
    modalVisible,
    changeMonthlyPaymentPeriod,
    closePaymentModal,
    getPlanInfo,
    workspaceId = '',
    userData,
  } = props;

  const isWorkspaceLimitsAvaliable = useIsWorkspaceLimitsAvaliable();
  const chosenLimitsList = useChosenLimitsList();
  const chosenProxyDataList = useChosenProxyDataList();
  const workspaceSubscription = useWorkspaceSubscription();
  const plans = usePlansList();
  const currentPaymentType = usePaymentModalPaymentType();
  const promoDiscount = usePromoDiscountAvaliable();
  const priceConfig = usePriceConfig();
  const selectedPlanId = useSelectedPlan();
  const selectedDiscountId = useSelectedDiscount();
  const selectedPlanObj = usePlanObjById(selectedPlanId);
  const selectedDiscounObj = useDiscountObjById(selectedDiscountId);
  const currency = useCurrentTabShownCurrency();
  const paymentTabsConfig = useModalShownTabsConfig();
  const isUpgrade = useIsUpgradeDiscountAvaliable();
  const upgradeDiscount = useUpgradeDiscount();
  const showUpgradeDiscountLine = useShouldShowUpgradeDiscountLine();
  const limitsPricingSetings = usePlansAddonPricingConfig();

  const { bundlePrices } = useTrafficData();

  const { planId: workspacePlanId = '', paymentDiscount: workspaceDiscountId = '', planExpiresAt } = workspaceSubscription;

  const isPurchaseWithProxyDataChosen = useHasAddonProxyDataChosen(selectedPlanId);
  const isLimitsPurchase = useIsLimitsPurchase(workspacePlanId, workspaceDiscountId);
  const isProxyDataPurchase = useIsProxydataPurchase(workspacePlanId, workspaceDiscountId);
  const isPurchaseWithAddonLimits = useHasAddonLimitsChosen(selectedPlanId);
  const addonLimitsChosen = useAddonLimitsChosen(selectedPlanId);
  const workspaceLimits = useWorkspaceLimits();
  const workspacePaidProxyData = useWorkspacePaidProxyData();
  const defaultCountryPaymentType = useDefaultCountryPaymentType();

  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [threeDSecureUrl, setThreeDSecureUrl] = useState<string>('');
  const [showThreeDSecureModal, setShowThreeDSecureModal] = useState<boolean>(false);
  const [isCloseModalDisabled, setIsCloseModalDisabled] = useState<boolean>(false);
  const [isLoadingModalShown, setIsLoadingModalShown] = useState<boolean>(false);
  const [isRussianIp, setIsRussianIp] = useState<boolean>(false);
  const [planForSuccessModal, setPlanForSuccessModal] = useState<string>(selectedPlanId);

  const {
    email,
    plan,
    isLastPaymentTrialEnded,
  } = userData;

  const { t: translation } = useTranslate();

  const paymentTypes = getPaymentTypes(paymentTabsConfig);
  const isAnnual = isDiscountAnnual(selectedDiscountId);
  const needChangePlan = !(plan.id || selectedPlanId === plan.id);
  const periodType = convertDiscountIdToPeriod(selectedDiscountId);
  const disableMonthPlan = isUpgrade && upgradeDiscount.periodType === E_PERIOD.ANNUAL;
  const workspacePlanObj = getPlanByPlanId(workspacePlanId, plans);
  const actionInfo = isUpgrade ? E_ACTION_INFO.UPGRADE : E_ACTION_INFO.EMPTY;
  const { daysLeftFraction = 1 } = upgradeDiscount;

  const isSamePlan = retrieveIsSamePlanAndPeriod({
    workspacePlanId,
    selectedPlanId,
    workspaceDiscountId,
    selectedDiscountId,
  });

  const isRenew = isSamePlan && isUpgrade && !isLimitsPurchase && !isProxyDataPurchase;
  const basePlanLimits = getBaseLimitsFromPlan(selectedPlanObj);
  const workspaceAddonLimits = substractLimits(workspaceLimits, basePlanLimits);
  const addonLimits = isRenew ? workspaceAddonLimits : addonLimitsChosen;
  const { maxMembers = 0 } = workspaceAddonLimits;
  const isRenewWithAddonProxyData = isRenew && Object.values(workspacePaidProxyData).some((traffic: number) => traffic > 0);
  const state: PaymentModalStateParams = {
    hasActiveRealPayment: isUpgrade,
    workspacePlanId,
    selectedPlanId,
    workspaceDiscountId,
    selectedDiscountId,
    workspacePaidProxyData,
    chosenProxyDataList,
    chosenLimitsList,
    isWorkspaceLimitsAvaliable,
    plansList: plans,
    workspaceLimits,
    promoDiscount,
  };

  const addonProxyData = deriveAddonProxyDataForProxyLine(state);
  const addon = { limits: addonLimits, proxyData: addonProxyData }
  const shouldShowProxyDataLine = deriveShouldShowProxyDataLine(state);
  const canShowProductLine = deriveShouldShowProductLine(state);
  const canSnowLimitsLine = deriveShouldShowLimitsLine(state);
  const shouldDiscountLineBeActive = deriveShouldDiscountLineBeActive(state);
  const isMonthToAnnualUpdgrade = isUpgrade && selectedDiscountId === DISCOUNT_IDS.Annual && workspaceDiscountId === DISCOUNT_IDS.Monthly;
  const isPurchaseWithAddons = isPurchaseWithAddonLimits || isRenew || isPurchaseWithProxyDataChosen || isMonthToAnnualUpdgrade;

  const query = useQuery();

  useEffect(() => {
    if (modalVisible) {
      sendActionAnalytics(E_ANALYTICS_ACTIONS.visitedPaymentMethodsPage, { actionInfo }).catch(() => null);
      fetchUserVisitedPageAnalytics().catch(() => null);
      setPaymentModalPaymentType(defaultCountryPaymentType);
    }
  }, [modalVisible]);

  useEffect(() => {
    const checkoutStatus = query.get('status');
    const orderId = query.get('order_id');
    const sessionId = query.get('session_id');
    if (checkoutStatus === 'success' && orderId) {
      setPaymentModalPaymentType(PaymentType.Crypto);
    }

    if (checkoutStatus === 'success' && sessionId) {
      setPaymentModalPaymentType(PaymentType.Card);
    }
  }, []);

  useEffect(() => {
    const { active: isUpgradeDiscountActive = false, periodType: period = E_PERIOD.ANNUAL } = upgradeDiscount;
    const upgradeAmount = new Price(workspacePlanObj).saveUpgrade(upgradeDiscount).getAmount();
    const planPrice = new Price(selectedPlanObj).period(selectedDiscounObj).getAmount();
    const isPeriodDowngrade = period === E_PERIOD.ANNUAL && !isAnnual;
    const isPriceDowngrade = planPrice < upgradeAmount;
    const isPaypal = currentPaymentType === PaymentType.PayPal;
    const { priceForMonth: selectedPriceForMonth = 0 } = selectedPlanObj || {};
    const { priceForMonth: workspacePriceForMonth = 0 } = workspacePlanObj || {};
    const isPlanDowngrade = selectedPriceForMonth < workspacePriceForMonth;
    const canShowUpgradeLine = isUpgradeDiscountActive && !(isPaypal || isPriceDowngrade || isSamePlan || isPeriodDowngrade || isPlanDowngrade);

    setShouldShowUpgradeDiscountLine(canShowUpgradeLine);
  }, [currentPaymentType, selectedDiscountId, selectedPlanId]);

  const correctUpgradeDiscount = (): IUpgradeDiscount => {
    if (!showUpgradeDiscountLine) {
      return EMPTY_UPGRADE_DISCOUNT;
    }

    return upgradeDiscount;
  };

  const modalHeightParams: ICalcModalHeightParams = {
    paymentType: currentPaymentType,
    isNewStripeFlow: NEW_FEATURES.newStripeCheckout,
    isTrialEnded: isLastPaymentTrialEnded,
    isUpgradeDiscount: showUpgradeDiscountLine || isLimitsPurchase,
    canShowProxyDataLine: shouldShowProxyDataLine,
    canSnowLimitsLine,
    canShowProductLine,
  };

  const modalHeight = calcModalHeight(modalHeightParams);

  const setPaymentTypeAndSendAnalytics = (type: PaymentType): void => {
    setPaymentModalPaymentType(type);
    const paymentMethod = getAnalyticsPaymentType(type, getIsGooglePay());
    sendActionAnalytics(E_ANALYTICS_ACTIONS.visitedPaymentTab, { actionInfo, paymentMethod }).catch(() => null);
  };

  const stripeButtonTitle = isRussianIp ? translation('pricing.paymentModal.stripeTitleRU') : translation('pricing.paymentModal.stripeTitle');

  const renderSelectedPaymentTypeButton = (): (JSX.Element | null)[] => paymentTypes.map(({ type }) => {
    const PAYMENT_TITLES = {
      [PaymentType.Paddle]: stripeButtonTitle,
      [PaymentType.Card]: stripeButtonTitle,
      [PaymentType.Mobile]: getIsGooglePay() ? GOOGLE_PAY : APPLE_PAY,
      [PaymentType.PayPal]: translation('pricing.paymentModal.paypal.title'),
      [PaymentType.Crypto]: translation('pricing.paymentModal.crypto.title'),
      [PaymentType.Sigma]: translation('pricing.paymentModal.ruCardButton'),
      [PaymentType.Yookassa]: translation('pricing.paymentModal.ruCardButton'),
    };


    if (type === currentPaymentType) {
      return (
        <ModalSwitcherItemSelected
          key={type}
        >
          {PAYMENT_TITLES[type]}
        </ModalSwitcherItemSelected>
      );
    }

    return (
      <ModalSwitcherItem
        key={type}
        onClick={(): void => setPaymentTypeAndSendAnalytics(type)}
      >
        {PAYMENT_TITLES[type]}
      </ModalSwitcherItem>
    );
  });

  const renderCheckmarkLine = (): JSX.Element => {
    if (PaymentType.Crypto === currentPaymentType) {
      return (
        <>
          <ModalCheckMarkContainer>
            <IconPaymentModalCheckmark />
            <ModalCheckMarkTxt>
              {translation('pricing.paymentModal.crypto.autoSubscription')}
            </ModalCheckMarkTxt>
          </ModalCheckMarkContainer>
          <ModalCheckMarkContainer style={{ marginTop: 0 }}>
            <IconPaymentModalCheckmark />
            <ModalCheckMarkTxt style={{ width: 360 }}>
              {translation('pricing.paymentModal.crypto.manualRenew')}
            </ModalCheckMarkTxt>
          </ModalCheckMarkContainer>
        </>
      );
    }

    if (PaymentType.Yookassa === currentPaymentType) {
      return (
        <>
          <ModalCheckMarkContainer>
            <IconPaymentModalCheckmark />
            <ModalCheckMarkTxt>
              {translation('pricing.paymentModal.autoSubscription')}
              {' '}
              {getNextPaymentDate(isAnnual)}
            </ModalCheckMarkTxt>
          </ModalCheckMarkContainer>
          <ModalCheckMarkContainer style={{ marginTop: 0 }}>
            <IconPaymentModalCheckmark />
            <ModalCheckMarkTxt style={{ width: 360 }}>
              {translation('pricing.paymentModal.cancelTxt')}
            </ModalCheckMarkTxt>
          </ModalCheckMarkContainer>
        </>
      );
    }

    const planNextPaymentDate = isLimitsPurchase ? planExpiresAt.toString() : '';

    return (
      <>
        <ModalCheckMarkContainer>
          <IconPaymentModalCheckmark />
          <ModalCheckMarkTxt>
            {translation('pricing.paymentModal.autoSubscription')}
            {' '}
            {getNextPaymentDate(isAnnual, planNextPaymentDate)}
          </ModalCheckMarkTxt>
        </ModalCheckMarkContainer>
        <ModalCheckMarkContainer style={{ marginTop: 0 }}>
          <IconPaymentModalCheckmark />
          <ModalCheckMarkTxt>
            {translation('pricing.paymentModal.cancelTxt')}
          </ModalCheckMarkTxt>
        </ModalCheckMarkContainer>
      </>
    );
  };

  const openSuccessModal = (planId: string): void => {
    setPlanForSuccessModal(planId);
    setIsLoadingModalShown(false);
    setShowSuccessModal(true);
  };

  const renderPaymentButton = (): JSX.Element => {
    if (currentPaymentType === PaymentType.Crypto) {
      return (
        <CryptoForm
          selectedPlan={selectedPlanId}
          selectedDiscount={selectedDiscountId}
          plans={plans}
          getCheckmarkLine={renderCheckmarkLine}
          workspaceId={workspaceId}
          closePaymentModal={closePaymentModal}
          setIsLoadingModalShown={setIsLoadingModalShown}
          openSuccessModal={openSuccessModal}
          isAddonPurchase={isLimitsPurchase || isProxyDataPurchase}
          isPurchaseWithAddon={isPurchaseWithAddons}
          addon={addon}
        />
      );
    }

    if (currentPaymentType === PaymentType.Paddle) {
      return (
        <PaddleForm
          selectedPlan={selectedPlanId}
          selectedDiscount={selectedDiscountId}
          plans={plans}
          getCheckmarkLine={renderCheckmarkLine}
          workspaceId={workspaceId}
          closePaymentModal={closePaymentModal}
          setIsLoadingModalShown={setIsLoadingModalShown}
          openSuccessModal={openSuccessModal}
          isLimitsPurchase={isLimitsPurchase}
          isRenewWithAddonLimits={isPurchaseWithAddonLimits}
          addonLimits={addonLimits}
        />
      );
    }

    if (currentPaymentType === PaymentType.PayPal) {
      return (
        <PayPalForm
          selectedPlan={selectedPlanId}
          selectedDiscount={selectedDiscountId}
          plans={plans}
          getCheckmarkLine={renderCheckmarkLine}
          workspaceId={workspaceId}
          setIsLoadingModalShown={setIsLoadingModalShown}
          openSuccessModal={openSuccessModal}
        />
      );
    }

    if ([PaymentType.Yookassa, PaymentType.Sigma].includes(currentPaymentType)) {
      return (
        <YookassaForm
          selectedPlan={selectedPlanId}
          selectedDiscount={selectedDiscountId}
          plans={plans}
          getCheckmarkLine={renderCheckmarkLine}
          workspaceId={workspaceId}
          openSuccessModal={openSuccessModal}
          isAddonPurchase={isLimitsPurchase || isProxyDataPurchase}
          isPurchaseWithAddon={isPurchaseWithAddons}
          addon={addon}
        />
      );
    }

    return (
      <StripeForm
        plans={plans}
        needChangePlan={needChangePlan}
        getCheckmarkLine={renderCheckmarkLine}
        openSuccessModal={openSuccessModal}
        workspaceId={workspaceId}
        setThreeDSecureUrl={setThreeDSecureUrl}
        setShowThreeDSecureModal={setShowThreeDSecureModal}
        setIsCloseModalDisabled={setIsCloseModalDisabled}
        setIsLoadingModalShown={setIsLoadingModalShown}
        setClosePaymentModal={closePaymentModal}
        isAddonPurchase={isLimitsPurchase || isProxyDataPurchase}
        isPurchaseWithAddon={isPurchaseWithAddons}
        addon={addon}
      />
    );
  };

  const paymentDropdownMenu = (): JSX.Element => (
    <CustomAnnuallyMenu selectable={true} selectedKeys={[selectedDiscountId]}>
      <CustomItemMenu
        key={ANNUAL_DISCOUNT}
        onClick={(): void => changeMonthlyPaymentPeriod(false)}
      >
        {translation('pricing.checkoutModal.annually')}
        {' '}
        {isAnnual ? <GreenCheckoutIcon /> : null}
      </CustomItemMenu>
      <CustomItemMenu
        key={MONTH_DISCOUNT}
        disabled={disableMonthPlan}
        onClick={(): void => changeMonthlyPaymentPeriod(true)}
      >
        {translation('pricing.checkoutModal.monthly')}
        {' '}
        {!isAnnual && <GreenCheckoutIcon />}
      </CustomItemMenu>
    </CustomAnnuallyMenu>
  );

  const getPaymentDropdown = (): JSX.Element => (
    <Dropdown
      overlay={paymentDropdownMenu}
      placement='bottomCenter'
      overlayStyle={{ width: 240, zIndex: 1210, zIndex: 1300 }}
      trigger={['click']}
    >
      <span>
        <AnnuallyTxt>
          {isAnnual ?
            translation('pricing.checkoutModal.annually').toLowerCase() :
            translation('pricing.checkoutModal.monthly').toLowerCase()}
        </AnnuallyTxt>
        <Icon style={{ fontSize: 14, cursor: 'pointer' }} type='caret-down' />
      </span>
    </Dropdown>
  );

  const renderPromoOrAnnualDiscountLine = (): JSX.Element => {
    const { active = false, discountType = E_DISCOUNT_TYPE.FIX, value } = promoDiscount;
    let saved = new Price(selectedPlanObj, currency).savePeriod(selectedDiscounObj).toLocalString(null, priceConfig);
    let leftText = translation('pricing.checkoutModal.discountTitle');
    const noDiscount = !shouldDiscountLineBeActive;
    if (active && !isAnnual) {
      saved = new Price(selectedPlanObj, currency)
        .applyLimits({ limits: addonLimitsChosen, planId: selectedPlanId, pricingSettings: limitsPricingSetings })
        .savePromo(promoDiscount, priceConfig)
        .toLocalString(null, priceConfig);

      if (discountType === E_DISCOUNT_TYPE.FIX) {
        leftText = translation('pricing.paymentModal.promoFixText');
      }

      if (discountType === E_DISCOUNT_TYPE.PERCENT) {
        leftText = translation('pricing.paymentModal.promoPercentText', { percent: value });
      }
    }

    if (isLimitsPurchase && isAnnual) {
      saved = new Price(0, currency)
        .applyLimits({ limits: addonLimitsChosen, planId: selectedPlanId, pricingSettings: limitsPricingSetings })
        .savePeriod(selectedDiscounObj)
        .toLocalString(null, priceConfig);
    }

    return (
      <>
        <ModalParamsLine>
          <ModalParamsLeftTxt
            greyTxt={noDiscount}
          >
            {leftText}
          </ModalParamsLeftTxt>
          <ModalParamsRightTxt
            greyTxt={noDiscount}
          >
            {!noDiscount ?
              `- ${saved}` : '–'}
          </ModalParamsRightTxt>
        </ModalParamsLine>
        <ModalDivider />
      </>
    );
  };

  const renderTrialMessage = (): JSX.Element | null => {
    if (!isLastPaymentTrialEnded) {
      return null;
    }

    return (
      <>
        {translation('pricing.paymentModal.trial.ended')}
        <br />
      </>
    );
  };

  const { resident: residentPrice } = retrieveTrafficPrice(currency, addonProxyData, bundlePrices);

  let totalPrice = new Price(selectedPlanObj, currency)
    .period(selectedDiscounObj)
    .promo(promoDiscount, priceConfig)
    .upgrade(correctUpgradeDiscount())
    .toLocalString(null, priceConfig);

  if (isPurchaseWithAddonLimits || isPurchaseWithProxyDataChosen || isRenew || isRenewWithAddonProxyData || isMonthToAnnualUpdgrade) {
    totalPrice = new Price(selectedPlanObj, currency)
      .applyLimits({ limits: addonLimits, planId: selectedPlanId, pricingSettings: limitsPricingSetings, priceConfig })
      .period(selectedDiscounObj)
      .promo(promoDiscount, priceConfig)
      .upgrade(correctUpgradeDiscount())
      .addPrice(residentPrice.period(selectedDiscounObj))
      .toLocalString(null, priceConfig);
  }

  if (isLimitsPurchase || isProxyDataPurchase) {
    let addonPrice = new Price(0, currency);
    if (isLimitsPurchase) {
      addonPrice
        .applyLimits({ limits: addonLimitsChosen, planId: selectedPlanId, pricingSettings: limitsPricingSetings, daysLeftFraction, priceConfig })

    }

    addonPrice.period(selectedDiscounObj);
    if (isProxyDataPurchase) {
      addonPrice.addPrice(residentPrice.period(selectedDiscounObj))
    }

    totalPrice = addonPrice.toLocalString(null, priceConfig);
  }


  const renderUpgradeDiscountLine = (): JSX.Element | null => {
    if (!showUpgradeDiscountLine) {
      return null;
    }

    return (
      <>
        <ModalParamsLine>
          <ModalParamsLeftTxt>
            {translation('pricing.paymentModal.prorationDiscountText')}
          </ModalParamsLeftTxt>
          <ModalParamsRightTxt>
            {`-${new Price(selectedPlanObj, currency).saveUpgrade(correctUpgradeDiscount()).toLocalString(null, priceConfig)}`}
          </ModalParamsRightTxt>
        </ModalParamsLine>
        <ModalDivider />
      </>
    );
  };

  const renderAddonLimitsLine = (): JSX.Element | null => {
    if (!canSnowLimitsLine) {
      return null;
    }

    const { maxMembers: extraMaxMembers = 0 } = addonLimitsChosen;
    const addonLimitsPrice = new Price(0, currency)
      .applyLimits({ limits: addonLimits, planId: selectedPlanId, pricingSettings: limitsPricingSetings })
      .period(selectedDiscounObj)
      .toLocalString(null, priceConfig);

    return (
      <>
        <ModalParamsLine>
          <ModalParamsLeftTxt>
            <Trans i18nKey={'pricing.paymentModal.teamMembersText'} values={{ count: extraMaxMembers }} />
          </ModalParamsLeftTxt>
          <ModalParamsRightTxt>
            {`${addonLimitsPrice}`}
          </ModalParamsRightTxt>
        </ModalParamsLine>
        <ModalDivider />
      </>
    );
  };

  const renderAddonProxyDataLine = (): JSX.Element | null => {
    if (!shouldShowProxyDataLine) {
      return null;
    }

    const { resident: extraResident = 0 } = addonProxyData;
    const addonResidentPrice = new Price(0, currency).addPrice(residentPrice.period(selectedDiscounObj)).toLocalString(null, priceConfig);

    return (
      <PaymentModalLine
        text={<Trans i18nKey={'pricing.paymentModal.residentDataText'} values={{ traffic: extraResident }} />}
        price={addonResidentPrice}
      />
    );
  };

  const renderProrateLimitsDiscountLine = (): JSX.Element | null => {
    if (!isLimitsPurchase) {
      return null;
    }

    const prorateDiscount = new Price(0, currency)
      .applyLimits({ limits: addonLimitsChosen, planId: selectedPlanId, pricingSettings: limitsPricingSetings })
      .period(selectedDiscounObj)
      .saveProrate(daysLeftFraction, priceConfig)
      .toLocalString(null, priceConfig);

    return (
      <PaymentModalLine
        text={<Trans i18nKey={'pricing.paymentModal.prorationDiscountText'} />}
        price={prorateDiscount}
      />
    );
  };

  const renderProductLine = (): JSX.Element | null => {
    if (!canShowProductLine) {
      return null;
    }

    return (
      <>
        <ModalParamsLine>
          <ModalParamsLeftTxt>
            {isAnnual ?
              getPlanInfo(selectedPlanId, 'name') + ` ${translation('pricing.checkoutModal.billedAnnually')}` :
              'GoLogin ' + getPlanInfo(selectedPlanId, 'name')}
          </ModalParamsLeftTxt>
          <ModalParamsRightTxt>
            {new Price(selectedPlanObj, currency).multiply(periodType).toLocalString(0, priceConfig)}
          </ModalParamsRightTxt>
        </ModalParamsLine>
        <ModalDivider />
      </>
    );
  };

  const getTitleMargin = (): number => isAnnual ? 32 : 64;
  const renderPaymentModalContent = (): JSX.Element => (
    <>
      <PaymentModalCancelContainer>
        <CloseOutlined
          style={{ cursor: 'pointer', fontSize: 30 }}
          onClick={closePaymentModal}
        />
      </PaymentModalCancelContainer>
      <HeaderTitleContainer>
        <ModalHeaderTxt style={{ marginBottom: getTitleMargin() }}>
          {renderTrialMessage()}
          {translation('pricing.paymentModal.trial.contentTxt1')}
          {' '}
          <ModalHeaderBoldTxt>
            {getPlanInfo(selectedPlanId, 'name')}
            {' '}
            {translation('pricing.paymentModal.trial.plan')}
            ,
          </ModalHeaderBoldTxt>
          <br />
          {translation('pricing.paymentModal.trial.contentTxt2')}
          {' '}
          {getPaymentDropdown()}
          {' '}
          {translation('pricing.paymentModal.trial.at')}
          {' '}
          {new Price(selectedPlanObj, currency).period(selectedDiscounObj).perMonth(periodType).toLocalString(0, priceConfig)}
          {' '}
          {translation('pricing.paymentModal.trial.contentTxt3')}
          <br />
          {isAnnual ? ` ${translation('pricing.paymentModal.trial.with')} ` : ''}
          {isAnnual ? (
            <ModalHeaderBoldTxt>
              {translation('pricing.paymentModal.trial.contentTxt4')}
            </ModalHeaderBoldTxt>
          ) : null}
        </ModalHeaderTxt>
      </HeaderTitleContainer>
      <ModalSwitcher>
        {renderSelectedPaymentTypeButton()}
      </ModalSwitcher>
      <ModalDivider />
      <ModalParamsLine>
        <ModalParamsLeftTxt>
          {translation('pricing.checkoutModal.accountName')}
        </ModalParamsLeftTxt>
        <ModalParamsRightTxt>
          {email}
        </ModalParamsRightTxt>
      </ModalParamsLine>
      <ModalDivider />
      {renderProductLine()}
      {renderAddonLimitsLine()}
      {renderAddonProxyDataLine()}
      {renderPromoOrAnnualDiscountLine()}
      {renderUpgradeDiscountLine()}
      {renderProrateLimitsDiscountLine()}
      <></>
      <ModalParamsLine style={{ fontWeight: 700 }}>
        <ModalParamsLeftTxt>
          {translation('pricing.checkoutModal.chargedTitle')}
        </ModalParamsLeftTxt>
        <ModalParamsRightTxt>
          {totalPrice}
        </ModalParamsRightTxt>
      </ModalParamsLine>
      <ModalDivider />
      {renderPaymentButton()}
      {currentPaymentType === PaymentType.Card ? (
        <PaymentModalIconsBarContainer>
          <PaymentIconsBar />
        </PaymentModalIconsBarContainer>
      ) : null}
    </>
  );

  const renderModalContent = (): JSX.Element => {
    if (showThreeDSecureModal) {
      return (
        <ThreeDSecureStyled
          src={threeDSecureUrl}
          id='three-d-secure-iframe'
        />
      );
    }

    if (showSuccessModal) {
      return (
        <SuccessPaymentModal
          modalVisible={showSuccessModal}
          selectedPlan={planForSuccessModal}
          getPlanInfo={getPlanInfo}
        />
      );
    }

    if (isLoadingModalShown) {
      return (
        <LoadingContainer>
          <Icon type='loading' />
        </LoadingContainer>
      );
    }

    return renderPaymentModalContent();
  };

  return (
    <CustomPaymentModal
      width={636}
      title={null}
      footer={null}
      closable={false}
      zIndex={QUICK_PRICING_MODAL_Z_INDEX + 1}
      maskClosable={!isCloseModalDisabled}
      onCancel={closePaymentModal}
      visible={modalVisible}
      bodyStyle={{ padding: 0 }}
    >
      <div
        style={{
          height: modalHeight,
          transition: 'height 0.2s ease-in-out 0s',
          overflowY: 'hidden',
        }}
      >
        {renderModalContent()}
      </div>
    </CustomPaymentModal>
  );
};

export default memo(PaymentModal);

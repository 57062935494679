import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { loadProxyList } from './proxy/proxy-operations/load-proxies.operations';
import { IProfilesResponse } from '../features/quickProfiles/api';
import { sendActionAnalytics } from '../features/common/api';
import { E_ANALYTICS_ACTIONS } from '../../common/constants/analytics';

type FirstSessionStatus = {
  profilesCreation: { isDone: boolean; attempts: number };
};

const firstSessionStatusAtom = atom<FirstSessionStatus | null>(null);

const setFirstSessionStatus = (firstSessionStatus: FirstSessionStatus | null): void =>
  getDefaultStore().set(firstSessionStatusAtom, firstSessionStatus);

const getFirstSessionStatus = (): FirstSessionStatus | null => getDefaultStore().get(firstSessionStatusAtom);
const useFirstSessionStatus = (): FirstSessionStatus | null => useAtomValue(firstSessionStatusAtom);

export const startFirstSession = (): void => {
  setFirstSessionStatus({ profilesCreation: { isDone: false, attempts: 0 } });
};

export const updateFirstSessionProfilesStatus = async (
  profilesResponse: Partial<IProfilesResponse>,
): Promise<{ tryAgainAfterSeconds: number | null }> => {
  const firstSessionStatus = getFirstSessionStatus();
  const { profilesCreation } = firstSessionStatus || { profilesCreation: { isDone: true, attempts: 0 } };
  const DONE = { tryAgainAfterSeconds: null };
  if (profilesCreation.isDone) {
    return DONE;
  }

  if (profilesCreation.attempts >= 10) {
    console.error('failed to create profiles');

    return DONE;
  }

  const profilesCount = (profilesResponse?.profiles?.length || 0);
  const isSuccess = profilesCount > 0;
  if (isSuccess) {
    setFirstSessionStatus({
      ...firstSessionStatus,
      profilesCreation: {
        isDone: true,
        attempts: profilesCreation.attempts,
      },
    });

    await onProfilesCreationFinish();

    return DONE;
  }

  const newAttempts = profilesCreation.attempts + 1;
  setFirstSessionStatus({
    ...firstSessionStatus,
    profilesCreation: {
      isDone: false,
      attempts: newAttempts,
    },
  });

  return { tryAgainAfterSeconds: newAttempts };
};

export const onProfilesCreationFinish = async (): Promise<void> => {
  await loadProxyList();
  sendActionAnalytics(E_ANALYTICS_ACTIONS.finishedCreatingDefaultProfiles);
};

export const useIsCreatingFirstSessionProfiles = (): boolean => {
  const firstSessionStatus = useFirstSessionStatus();
  const { profilesCreation } = firstSessionStatus || { profilesCreation: { isDone: true, attempts: 0 } };

  return !profilesCreation.isDone && profilesCreation.attempts > 0;
};

import { GeoProxyType, ProxyGeoTypes } from '../../../../common/constants/types';
import { BundlePrices } from '../../../interfaces';
import { ProxyData } from '../../../interfaces/workspaces/limits';
import { TRAFFIC_DATA_DEFAULT } from '../../../state/proxy/traffic-data.atom';
import { IDiscount, PriceInCurrencies, TCurrency } from '../interfaces';
import { Price } from './price';

export const retrieveProxyBundlePrice = (
  dataType: GeoProxyType,
  dataAmount: number,
  bundlePrices: BundlePrices,
): PriceInCurrencies | null => {
  let bundlePricesSettings = bundlePrices;
  if (!bundlePrices) {
    bundlePricesSettings = TRAFFIC_DATA_DEFAULT.bundlePrices;
  }
  TRAFFIC_DATA_DEFAULT.bundlePrices
  const dataTypeBundles = bundlePricesSettings[dataType];
  if (!dataTypeBundles) {
    return null;
  }

  let [priceInfo] = dataTypeBundles;
  for (const bundle of dataTypeBundles) {
    if (bundle.dataAmount > dataAmount) {
      break;
    }

    priceInfo = bundle;
  }

  const { pricePerGb } = priceInfo;
  if (!pricePerGb) {
    return null;
  }

  return pricePerGb;
};

export const retrieveTrafficPrice = (currency: TCurrency, addonProxyDataChosen: ProxyData, bundlePrices: BundlePrices): Record<ProxyGeoTypes, Price> => {
  const { resident = 0, mobile = 0, dataCenter = 0 } = addonProxyDataChosen;
  const residentPricePerGb = retrieveProxyBundlePrice(GeoProxyType.Resident, resident, bundlePrices);

  let residentPrice = new Price(0);
  if (residentPricePerGb) {
    residentPrice = new Price(residentPricePerGb[currency], currency).multiplyByQuantity(resident);
  }

  return {
    resident: residentPrice,
    mobile: new Price(0),
    dataCenter: new Price(0),
  }
};

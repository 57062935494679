import styled from '@emotion/styled/macro';

import { textFont } from '../style-templates';

export const GOLOGIN_POPOVER_DEFAULT_PADDING: React.CSSProperties['padding'] = '6px 16px';

export const GologinPopoverItemRowBasic = styled('div')<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;

  border-radius: 4px;

  ${textFont}

  color: var(--gologin-popover-text-color);
  background: ${(props): string => props.isSelected ? 'var(--gologin-popover-item-selected-background)' : 'inherit'};
`;

export const GologinPopoverItemRowStatic = styled(GologinPopoverItemRowBasic)`
  cursor: default;
  padding: ${GOLOGIN_POPOVER_DEFAULT_PADDING};
`;

export const GologinPopoverItemRow = styled(GologinPopoverItemRowBasic)`
  cursor: pointer;
  padding: ${GOLOGIN_POPOVER_DEFAULT_PADDING};

  :hover {
    background: var(--gologin-popover-item-hovered-background);
  }
`;

import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';
import { Popover } from '@material-ui/core';

import { textFont } from '../../../style-templates';

export const Button = styled.div`
  ${textFont};
  color: var(--222222);
  flex: 1;
  white-space: nowrap;
  text-align: left;
  padding: 6px 12px;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  .buttonWithInput {
    padding-right: 16px;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;

  &:hover {
    border-radius: 4px;
    background: var(--button-create-in-header-popover-item-hovered-background);

    input {
      border-color: var(--D2D2D5);
      background: var(--button-create-in-header-popover-background);
    }
  }
`;

export const WrapperInput = styled.div`
  padding-right: 4px;
`;

export const Input = styled.input`
  ${textFont};
  padding: 0 16px;
  border: 0.5px solid var(--E9E9E7);
  border-radius: 4px;
  padding: 2px;
  height: 22px;
  width: 48px;
  color: var(--696973);
  text-align: center;
  background: transparent;

  &:focus-visible {
    outline: none;
    color: var(--222222);
  }

  &:focus {
    border-color: var(--D2D2D5);
    background: var(--button-create-in-header-popover-background);
  }
`;

export const Separator = styled.div`
  margin: 5px 0;
  height: 0.5px;
  background: var(--D2D2D5);
`;

export const AccountMenuPopoverCustom = styled(Popover)<{ isDarkTheme: boolean }>`
  .MuiPopover-paper {
    width: auto;
    padding: 5px;
    outline: var(--button-create-in-header-popover-outline);
    border-radius: 4px;
    margin-top: 8px;

    background: var(--button-create-in-header-popover-background);
    
    ${(props): SerializedStyles|null => !props.isDarkTheme ? css`
      box-shadow: 0 3px 6px var(--0000001A-header-account-menu), 0 4px 8px var(--00000014-header-account-menu), 0 1px 12px var(--0000000A-header-account-menu);
    ` : null}
  }
`;

import React from 'react';
import { Trans } from 'react-i18next';

import { GeoProxyType } from '../../../../../common/constants/types';
import { RadioButton } from '../domElements';

type GeoProxyTypeRadioButtonProps = {
  geoProxyType: GeoProxyType;
  isDisabled: boolean;
  style?: React.CSSProperties;
}

const GeoProxyTypeRadioButton: React.FC<GeoProxyTypeRadioButtonProps> = (props) => {
  const { geoProxyType, isDisabled, style } = props;

  return (
    <RadioButton
      value={geoProxyType}
      disabled={isDisabled}
      style={style}
    >
      <Trans i18nKey={`proxyTypes.${geoProxyType}`} />
    </RadioButton>
  );
};

export default GeoProxyTypeRadioButton;

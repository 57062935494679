import { E_DISCOUNT_TYPE } from '../../../interfaces/discount-types.interfaces';
import { IPlan } from '../../../interfaces/plan';
import { Limits } from '../../../interfaces/workspaces/limits';
import { CENTS_IN_DOLLAR, DOLLARS_IN_DOLLAR, EMPTY_DISCOUNT } from '../constants';
import {
  E_CURRENCY,
  E_CURRENCY_FRACTION_DIGITS,
  E_CURRENCY_LOCALES,
  E_PERIOD,
  IDiscount,
  IDiscountElem,
  IPriceConfig,
  IUpgradeDiscount,
  LimitName,
  PaymentPeriodType,
  PlansAddonPricingConfig,
  TCurrency,
} from '../interfaces';

export type PriceApplyLimitsParams = {
  limits: Limits;
  pricingSettings: PlansAddonPricingConfig;
  daysLeftFraction?: number;
  planId: string;
  currency?: TCurrency;
  priceConfig?: IPriceConfig;
}

export class Price {
  private amount: number;
  private fullPrice: number;
  private planObj: IPlan | undefined;
  private currency: TCurrency;
  private daysLeftFraction: number;
  private periodApplied: boolean;
  private MINIMUM_AMOUNT = 100;
  private price: Record<TCurrency, number> = {
    usd: 0,
    rub: 0,
    pkr: 0,
    bdt: 0,
  };

  private periodFactors = {
    annual: 12,
    monthly: 1,
  };

  constructor(initial: number | IPlan | null, currency?: TCurrency) {
    this.currency = currency || E_CURRENCY.USD;
    this.periodApplied = false;
    // Full plan period
    this.daysLeftFraction = 1;
    if (typeof initial === 'number') {
      this.amount = initial;
      this.price[this.currency] = initial;
      this.fullPrice = initial;
    } else if (initial === null) {
      this.amount = this.price[this.currency];
      this.fullPrice = this.price[this.currency];
    } else {
      this.planObj = initial;
      this.price = { ...initial.price };
      this.amount = this.price[this.currency];
      this.fullPrice = this.price[this.currency];
    }
  }

  addPrice(price: Price): this {
    if(!price) {
      return this;
    }

    if (this.fullPrice === this.MINIMUM_AMOUNT) {
      this.amount = 0;
    }
  
    this.amount += price.amount;
    return this;
  }

  applyLimits(params: PriceApplyLimitsParams): this {
    if (!params) {
      return this;
    }

    const { limits, pricingSettings, daysLeftFraction, planId, currency, priceConfig } = params;
    if (!(limits && pricingSettings && planId)) {
      return this;
    }

    if (currency) {
      this.currency = currency;
    }

    this.daysLeftFraction = daysLeftFraction || 1;
    if (!(daysLeftFraction && typeof daysLeftFraction === 'number' && daysLeftFraction >= 0)) {
      this.daysLeftFraction = 1;
    }

    const planAddonLimitsSettings = pricingSettings[planId.toString()];
    if (!planAddonLimitsSettings) {
      return this;
    }

    const { limits: limitsConfig } = planAddonLimitsSettings;
    const totalAmount = Object.keys(limits).reduce((limitCost, key) => {
      if (!limitsConfig[key]) {
        return limitCost;
      }

      const { pricing } = limitsConfig[key];
      let remaining = limits[key];
      for (const tier of pricing) {
        const [min, max] = tier.range;
        const upperLimit = max || Infinity;
        const applicable = upperLimit - (min - 1);
        const priceInCurrency = tier.price[this.currency];
        if (applicable < remaining) {
          limitCost += applicable * priceInCurrency;
          remaining -= applicable;
          continue;
        }

        limitCost += remaining * priceInCurrency;
        break;
      }

      return limitCost;
    }, 0);

    this.amount += totalAmount * this.daysLeftFraction;
    this.amount = this.getMinimiumCheckedAmount(priceConfig);

    return this;
  }

  private getMinimiumCheckedAmount(priceConfig?: IPriceConfig): number {
    if (!priceConfig) {
      return this.amount;
    }

    const { minimumAmounts } = priceConfig || {};
    const currency = this.currency || E_CURRENCY.USD;
    if (!minimumAmounts) {
      return this.amount;
    }

    const currencyFactor = minimumAmounts[currency];
    const mimimumAmountInCurrency = this.MINIMUM_AMOUNT * currencyFactor;
    if (this.amount < mimimumAmountInCurrency) {
      return 0;
    }

    return this.amount;
  }

  getAmount(): number {
    return this.amount;
  }

  getAmountInCents(): number {
    return Math.round(this.amount);
  }

  getAmountInCurrency(fractionDigits?: number): number {
    const decimalPlaces = typeof fractionDigits === 'number' ? fractionDigits : 2;
    const factor = Math.pow(10, decimalPlaces);

    return Math.round(this.amount * factor / CENTS_IN_DOLLAR) / factor;
  }

  setCurrency(currency: TCurrency): this {
    this.currency = currency;
    const { priceForMonthRUB = 0 } = this.planObj || {};
    if (currency === E_CURRENCY.RUB && priceForMonthRUB) {
      this.amount = priceForMonthRUB;
    }

    return this;
  }

  multiply(periodType: PaymentPeriodType): this {
    if ([E_PERIOD.MONTHLY, E_PERIOD.ANNUAL].includes(periodType)) {
      this.amount *= this.periodFactors[periodType];
    }

    return this;
  }

  multiplyByQuantity(quantity: number): this {
    this.amount *= quantity;

    return this;
  }

  period(param: IDiscount|number|null): this {
    if (!param) {
      return this;
    }

    if (typeof param === 'number') {
      this.amount *= param;

      return this;
    }

    const { discounts } = param;
    if (!discounts) {
      return this;
    }

    const activeDiscount = (param.discounts || []).find((elem) => elem?.active) || EMPTY_DISCOUNT;

    return this.promo(activeDiscount);
  }

  perMonth(periodType: PaymentPeriodType): this {
    if ([E_PERIOD.MONTHLY, E_PERIOD.ANNUAL].includes(periodType)) {
      const pricePerMonth = this.amount / this.periodFactors[periodType];
      this.amount = Math.floor(pricePerMonth / CENTS_IN_DOLLAR) * CENTS_IN_DOLLAR;
    }

    return this;
  }

  promo(discountElem: Partial<IDiscountElem>, config?: IPriceConfig): this {
    if (!discountElem) {
      return this;
    }

    const { discountType = E_DISCOUNT_TYPE.MONTH, active = false } = discountElem;
    const { value: discountValue = 1 } = discountElem;

    if (!active) {
      return this;
    }

    if (discountType === E_DISCOUNT_TYPE.MONTH && !this.periodApplied) {
      this.periodApplied = true;
      this.amount *= discountValue;
    }

    if (discountType === E_DISCOUNT_TYPE.PERCENT) {
      this.amount *= (1 - discountValue / CENTS_IN_DOLLAR);
    }

    if (discountType === E_DISCOUNT_TYPE.FIX) {
      const { currencyFactors = null } = config || {};
      const currencyFactor = currencyFactors ? currencyFactors[this.currency] : DOLLARS_IN_DOLLAR;
      this.amount -= (discountValue * currencyFactor);
    }

    if (this.amount < 0) {
      this.amount = 0;
    }

    return this;
  }

  savePeriod(param: IDiscount|null): this {
    if (!param) {
      return this;
    }

    const { discounts, monthCount = 1 } = param;
    if (!discounts) {
      return this;
    }

    this.fullPrice = this.amount * monthCount;
    const activeDiscount = (param.discounts || []).find((elem) => elem?.active) || EMPTY_DISCOUNT;
    this.promo(activeDiscount);

    this.amount = this.fullPrice - this.amount;

    return this;
  }

  savePromo(discountElem: IDiscountElem|null, config?: IPriceConfig): this {
    if (!discountElem) {
      return this;
    }

    const { discountType = E_DISCOUNT_TYPE.MONTH } = discountElem || {};
    if (!discountElem || discountType === E_DISCOUNT_TYPE.MONTH) {
      this.amount = 0;

      return this;
    }

    const { value: discountValue = 1 } = discountElem;

    if (discountType === E_DISCOUNT_TYPE.PERCENT) {
      this.amount *= (discountValue / CENTS_IN_DOLLAR);
    }

    if (discountType === E_DISCOUNT_TYPE.FIX) {
      const { currencyFactors = null } = config || {};
      const currencyFactor = currencyFactors ? currencyFactors[this.currency] : DOLLARS_IN_DOLLAR;
      this.amount = discountValue * currencyFactor;
    }

    return this;
  }

  saveUpgrade(upgradeDiscount: IUpgradeDiscount): this {
    if (!upgradeDiscount) {
      this.amount = 0;

      return this;
    }

    const { amountInCurrency } = upgradeDiscount;
    this.amount = amountInCurrency[this.currency];

    return this;
  }

  saveProrate(ratio: number, priceConfig?: IPriceConfig): this {
    if (typeof ratio !== 'number') {
      return this;
    }

    const fullPrice = this.amount;
    this.amount = fullPrice * ratio;
    const prorateDiscount = this.getMinimiumCheckedAmount(priceConfig);
    this.amount = fullPrice - prorateDiscount;

    return this;
  }

  toLocalString(digits?: number|null, config?: IPriceConfig): string {
    const resultAmount = this.amount / CENTS_IN_DOLLAR;
    const isDefinedFraction = typeof digits === 'number';
    let fractionDigits = isDefinedFraction ? digits : E_CURRENCY_FRACTION_DIGITS.TWO;

    const { currencyLocales = null, currencyFractionDigits = null } = config || {};
    const currencyLocale = currencyLocales ? currencyLocales[this.currency] : E_CURRENCY_LOCALES.USD;
    if (currencyFractionDigits && !isDefinedFraction) {
      fractionDigits = currencyFractionDigits[this.currency];
    }

    return new Intl.NumberFormat(currencyLocale, {
      style: 'currency',
      currency: this.currency.toUpperCase(),
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    }).format(resultAmount);
  }

  upgrade(upgradeDiscount: IUpgradeDiscount): this {
    if (!upgradeDiscount) {
      return this;
    }

    const { amountInCurrency, discountType = E_DISCOUNT_TYPE.FIX } = upgradeDiscount;
    if (discountType !== E_DISCOUNT_TYPE.FIX) {
      return this.promo(upgradeDiscount);
    }

    const discountAmount = amountInCurrency[this.currency];
    this.amount -= discountAmount;

    return this;
  }
}

import { message } from 'antd';
import React, { memo, useCallback, useContext, useRef } from 'react';
import { Trans } from 'react-i18next';
import { E_ANALYTICS_ACTIONS } from '../../../../common/constants/analytics';
import { IProxy } from '../../../interfaces';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { useProxyList } from '../../../state/proxy/proxy-list.atom';
import {
  closeProxyManager,
  openProxyManager,
  useProxyManagerState,
} from '../../../state/proxy/proxy-manager-modal-status.atom';
import { pasteProxies } from '../../../state/proxy/proxy-operations/paste-proxies-operations';
import { sendActionAnalytics } from '../../common/api';
import { EMPTY_PROXY } from '../constants';
import { copyProxies, getIsProxyArchived } from '../proxy-helpers';
import { getProxyForProxySelector } from '../utils/find-proxy-in-list';
import ProxyForm from './proxy-form';
import ProxyPlaceholder from './proxy-placeholder';
import ProxySelectorButtons from './proxy-selector-buttons';
import { ProxySelectorContentWrapper } from './styles';
import { openProfilesTableModal, PAYMENT_EXPIRED_MODAL_NAME } from '../../../state/profiles-table-modal.atom';
import { workspaceContext } from '../../../state';

type ProxySelectorProps = {
  profileId: string;
  proxy?: IProxy;
  isInDrawer: boolean;
  isSharedProxy: boolean;
  proxyEnabled: boolean;
  localProxySelectorLocation: string;
};

const ProxySelector: React.FC<ProxySelectorProps> = props => {
  const { profileId, proxy: propProxy, isInDrawer, isSharedProxy, proxyEnabled, localProxySelectorLocation } = props;

  const proxyList = useProxyList();
  const { currentProfileId: proxyManagerProfileId, proxySelectorLocation } = useProxyManagerState();

  const { isUnpaid } = useContext(workspaceContext);

  const containerElement = useRef<HTMLDivElement>(null);
  const proxySelectorId = `proxy-selector-${profileId}`;

  const proxy = proxyEnabled ? propProxy : EMPTY_PROXY;
  const isProxyEditMode =
    !!profileId &&
    profileId === proxyManagerProfileId &&
    (proxySelectorLocation === 'no-location' || proxySelectorLocation === localProxySelectorLocation);

  const isNoProxy = !proxy || proxy.mode === 'none';

  const proxyToRender = getIsProxyArchived(propProxy) ? propProxy : getProxyForProxySelector(proxyList, proxy);

  const renderContent = (): JSX.Element => {
    const shouldShowProxyPlaceholder = !proxy || (isNoProxy && !isProxyEditMode);
    if (!getIsProxyArchived(proxyToRender) && shouldShowProxyPlaceholder) {
      return <ProxyPlaceholder isInDrawer={isInDrawer} />;
    }
    
    return (
      <ProxyForm
        currentProxy={proxyToRender}
        profileId={profileId}
        isSharedProxy={isSharedProxy}
        editProxyMode={isProxyEditMode}
        isInDrawer={isInDrawer}
        localProxySelectorLocation={localProxySelectorLocation}
        selectorContainerElement={containerElement}
      />
    );
  };

  const handleContainerClick = (): void => {
    if (!profileId) {
      return;
    }

    if (isUnpaid) {
      openProfilesTableModal(PAYMENT_EXPIRED_MODAL_NAME);

      return;
    }

    if (proxyManagerProfileId) {
      closeProxyManager();

      return;
    }

    if (!containerElement.current) {
      console.error('No containerElement.current');

      return;
    }

    sendActionAnalytics(E_ANALYTICS_ACTIONS.openedProxyManagerInProfilesTableClickOnCell);
    openProxyManager({
      modalView: 'proxy-list',
      currentProxy: proxyToRender,
      containerElement: containerElement.current,
      currentProfileId: profileId,
      proxySelectorLocation: localProxySelectorLocation,
    });
  };

  const renderRightControls = (): JSX.Element | null => (
    <ProxySelectorButtons
      isVisible={isProxyEditMode}
      profileId={profileId}
      isInDrawer={isInDrawer}
      selectorContainerElement={containerElement}
      localProxySelectorLocation={localProxySelectorLocation}
    />
  );

  const handlePasteProxy = useCallback(async () => {
    try {
      const pastedProxies = await pasteProxies({
        profileId,
        localProxySelectorLocation,
        isInDrawer,
      });
      if (pastedProxies.length > 0) {
        message.success(<Trans i18nKey='base.pastedText' />);
      }
    } catch (error) {
      message.error(<Trans i18nKey='base.pasteError' />);
    }
  }, [profileId, localProxySelectorLocation, isInDrawer]);

  const handleCopyProxy = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      event.preventDefault();
      copyProxies([proxyToRender]);
      message.success(<Trans i18nKey='base.copiedText' />);
    },
    [proxyToRender],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>): void => {
      if (event.ctrlKey || event.metaKey) {
        if (event.key === 'c' || event.key === 'C') {
          event.preventDefault();
          handleCopyProxy(event);
        } else if (event.key === 'v' || event.key === 'V') {
          event.preventDefault();
          handlePasteProxy();
        }
      }
    },
    [handleCopyProxy, handlePasteProxy],
  );

  return (
    <ProxySelectorContentWrapper
      ref={containerElement}
      id={proxySelectorId}
      onClick={handleContainerClick}
      onKeyDown={handleKeyDown}
      shouldProxyTakeAllWidth={isInDrawer}
      isProxyEditMode={isProxyEditMode}
      newStyle={!!NEW_FEATURES.header}
      isInDrawer={isInDrawer}
      data-proxy-manager-exclude={isProxyEditMode ? 'proxy-selector-buttons' : undefined}
    >
      {renderContent()}
      {renderRightControls()}
    </ProxySelectorContentWrapper>
  );
};

export default memo(ProxySelector);

import styled from '@emotion/styled';

import { textFont } from '../../../../../../ui/style-templates';

const GEO_PROXY_TRAFFIC_TOOLTIP_ITEMS_GAP: React.CSSProperties['marginTop'] = '4px';

export const GeoProxyTrafficTooltipWrapper = styled.div`
  margin-top: 4px;

  ${textFont()}

  /* gap */
  & > * + * {
    margin-top: ${GEO_PROXY_TRAFFIC_TOOLTIP_ITEMS_GAP};
  }
`;

export const GeoProxyTrafficTooltipList = styled.ul`
  padding: 0;

  li {
    list-style-type: '•';
    margin-left: 13px;
    padding-inline-start: 8px;
  }

  /* gap */
  & > li + li {
    margin-top: ${GEO_PROXY_TRAFFIC_TOOLTIP_ITEMS_GAP};
  }
`;

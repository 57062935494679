import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { GreenSubmitButton } from '../modern-modal';
import { ActionButton, RedActionButton } from './styles';

interface IActionButtons {
  confirmText: string;
  onConfirm: () => void;
  onCancel: () => void;
  isDisabledConfirm?: boolean;
  isDangerous?: boolean;
  isCancelFirst?: boolean;
}

const ActionButtons: FC<IActionButtons> = ({
  confirmText,
  onConfirm,
  onCancel,
  isDisabledConfirm,
  isDangerous,
  isCancelFirst,
}): JSX.Element => {
  const { t: translation } = useTranslation();

  const renderActionButton = (): JSX.Element => {
    if (isDangerous) {
      return (
        <RedActionButton onClick={onConfirm} disabled={isDisabledConfirm} style={{ marginTop: 0, marginRight: 8 }}>
          {confirmText}
        </RedActionButton>
      );
    }

    if (isCancelFirst) {
      return (
        <GreenSubmitButton onClick={onConfirm} disabled={isDisabledConfirm} style={{ marginTop: 0, marginLeft: 8 }}>
          {confirmText}
        </GreenSubmitButton>
      );
    }

    return (
      <ActionButton onClick={onConfirm} disabled={isDisabledConfirm} style={{ marginTop: 0, marginRight: 8 }}>
        {confirmText}
      </ActionButton>
    );
  };
  
  if (isCancelFirst) {
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', marginTop: 32 }}>
        <ActionButton onClick={onCancel}>
          {translation('base.cancel')}
        </ActionButton>
        {renderActionButton()}
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 32 }}>
      {renderActionButton()}
      <GreenSubmitButton onClick={onCancel}>
        {translation('base.cancel')}
      </GreenSubmitButton>
    </div>
  );
};

export default ActionButtons;

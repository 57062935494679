import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';
import React from 'react';

import { ProxySelectorButtonsWrapper } from './proxy-selector-buttons/styles';
import { flagIcons } from '../../../../flag-icons';
import { PROFILE_SETTINGS_HEADER_Z_INDEX } from '../../profile-settings/profile-settings-header/styles';
import { CheckProxyButtonWrapper } from '../check-proxy-button/styles';

export const PROXY_SELECTOR_CONTENT_HEIGHT: React.CSSProperties['height'] = '16px';

const expandedSelectorStyles = css`
  width: fit-content;
  min-width: 100%;
  padding-right: 16px;
`;

export const ProxySelectorContentWrapper = styled.div<{
  shouldProxyTakeAllWidth: boolean;
  isProxyEditMode: boolean;
  newStyle: boolean;
  isInDrawer: boolean;
}>`
  height: 100%;

  position: relative;
  z-index: ${((props): number => props.isInDrawer ? PROFILE_SETTINGS_HEADER_Z_INDEX - 1 : 122)};

  display: flex;
  justify-content: flex-start;

  ${(props): SerializedStyles | null => props.newStyle ? css`
    align-items: center;
    background-color: var(--FFFFFF-proxy-manager-selector);
  ` : css`
    padding-top: ${props.shouldProxyTakeAllWidth ? 21 : 19}px;
    margin-left: ${props.isInDrawer ? 16 : -6}px;
    align-items: flex-start;
  `}

  ${(props): SerializedStyles | null => props.isInDrawer ? null : css`
    padding-top: 18px;
    align-items: flex-start;
  `}

  ${(props): SerializedStyles | null => {
    switch (true) {
      case props.shouldProxyTakeAllWidth:
        return css`
          width: 100%;
        `;
      case props.isProxyEditMode:
        return expandedSelectorStyles;
      default:
        return null;
    }
  }};

  ${(props): SerializedStyles | null => props.isProxyEditMode ? css`
    ${CheckProxyButtonWrapper} {
      display: flex;
      opacity: 1;
    }
  ` : null}

  ${(props): SerializedStyles | null => props.shouldProxyTakeAllWidth ? css`
    width: 100%;
    justify-content: space-between;

    ${ProxySelectorButtonsWrapper} {
      visibility: visible;
    }
  ` : null}

  ${flagIcons}

  :hover {
    ${(props): SerializedStyles | null => props.shouldProxyTakeAllWidth ? null : expandedSelectorStyles}

    ${ProxySelectorButtonsWrapper} {
      visibility: visible;
    }
  }
`;

import { GEOPROXY_MODE } from '../../../../common/constants/constants';
import { determineIsProxyTruthy } from '../../../../common/constants/types';
import { IProfile, IProxy, ProxyMode } from '../../../interfaces';

export const PROXY_ARTIFICIAL_ID_SEPARATOR = '-';

export const generateProxyGroupId = (mode: ProxyMode, country: string): string => [mode, country.toUpperCase()].join(PROXY_ARTIFICIAL_ID_SEPARATOR);

export const convertProxyGroupIdToCountry = (groupId: string): string => {
  const [_mode, country = ''] = groupId.split(PROXY_ARTIFICIAL_ID_SEPARATOR);

  return country;
};

type NoIdProxyIdParams = Pick<IProxy, 'mode'|'autoProxyRegion'|'torProxyRegion'>;

export const getNoIdProxyId = (proxy: NoIdProxyIdParams): string => {
  let country = '';
  switch (proxy.mode) {
    case 'tor':
      country = proxy.torProxyRegion || '';
      break;
    case 'gologin':
      country = proxy.autoProxyRegion || '';
      break;
    default:
      break;
  }

  return proxy.mode + PROXY_ARTIFICIAL_ID_SEPARATOR + country.toUpperCase();
};

export const convertProfileToArtificialProxyId = (profile: IProfile): IProfile => {
  const newProfile: IProfile = { ...profile };
  if (determineIsProxyTruthy(newProfile.proxy) && !newProfile.proxy.id) {
    newProfile.proxy = {
      ...newProfile.proxy,
      id: getNoIdProxyId(newProfile.proxy),
    };
  }

  return newProfile;
};

export const generateArtificialProxyId = (mode: ProxyMode): string => mode + PROXY_ARTIFICIAL_ID_SEPARATOR + Date.now();

export const generateArtificialGeoProxyId = (): string => generateArtificialProxyId(GEOPROXY_MODE);

export const determineIsArtificialProxyId = (proxyId: string): boolean => !!proxyId && proxyId.includes(PROXY_ARTIFICIAL_ID_SEPARATOR);

export const convertFromArtificialProxyIds = (proxyIds: string[]): string[] => proxyIds.filter(proxyId => !determineIsArtificialProxyId(proxyId));

export const convertProxyFromArtificialProxyId = <ProxyType extends Pick<IProxy, 'id'>>(proxy: ProxyType): ProxyType => {
  if (proxy.id && determineIsArtificialProxyId(proxy.id)) {
    const { id: _mockedId, ...noIdProxy } = proxy;

    return noIdProxy;
  }

  return proxy;
};

export const convertProfileFromArtificialProxyId = (profile: IProfile): IProfile => {
  if (!(profile.proxy.id && determineIsArtificialProxyId(profile.proxy.id))) {
    return profile;
  }

  const { id: _mockedId, ...noIdProxy } = profile.proxy;

  return {
    ...profile,
    proxy: noIdProxy,
  };
};

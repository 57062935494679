import React from 'react';
import { Trans } from 'react-i18next';

import {
  ProxyDeletionConfirmWrapper,
  ProxyDeletionConfirmContent,
  ProxyDeletionConfirmTitle,
  ProxyDeletionConfirmButtons,
  ProxyDeleteButton,
  ProxyCancelDeleteButton,
} from './styles';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import { deleteProxies } from '../../../../state/proxy/proxy-operations/delete-proxies.operations';
import { updateSelectedProxies, useSelectedProxies } from '../../../../state/proxy/selected-proxies.atom';
import { switchConfirmBlockVisible, useConfirmBlockVisible, useIsSelectProxyModeOpened } from '../../../../state/proxy-select-menu.atom';
import { deleteProxiesRequest } from '../../api';

const ProxyDeletionConfirm: React.FC = () => {
  const { selectedProxy, selectedProxies } = useSelectedProxies();
  const isSelectProxyModeOpened = useIsSelectProxyModeOpened();
  const isDeletionConfirmVisible = useConfirmBlockVisible();

  if (!isDeletionConfirmVisible) {
    return null;
  }

  const handleDelete = (): void => {
    let proxiesToDelete: string[];
    if (selectedProxy) {
      proxiesToDelete = [selectedProxy.id];
    } else {
      proxiesToDelete = selectedProxies;
    }

    deleteProxiesRequest(proxiesToDelete);
    deleteProxies(proxiesToDelete);
    switchConfirmBlockVisible(false);
  };

  const handleCancel = (): void => {
    switchConfirmBlockVisible(false);
    if (!isSelectProxyModeOpened) {
      updateSelectedProxies({ selectedProxies: [] });
    }
  };

  let [translationKey, proxiesCounter] = ['proxies.deleteProxiesPermanently', selectedProxies.length];
  if (selectedProxy || selectedProxies.length === 1) {
    [translationKey, proxiesCounter] = ['proxies.deleteProxyPermanently', 1];
  }

  return (
    <ProxyDeletionConfirmWrapper isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroupsV2}>
      <ProxyDeletionConfirmContent>
        <ProxyDeletionConfirmTitle>
          <Trans i18nKey={translationKey} values={{ count: proxiesCounter }} />
        </ProxyDeletionConfirmTitle>
        <ProxyDeletionConfirmButtons>
          <ProxyDeleteButton onClick={handleDelete}>
            <Trans i18nKey='proxies.delete' />
          </ProxyDeleteButton>
          <ProxyCancelDeleteButton onClick={handleCancel}>
            <Trans i18nKey='proxies.cancel' />
          </ProxyCancelDeleteButton>
        </ProxyDeletionConfirmButtons>
      </ProxyDeletionConfirmContent>
    </ProxyDeletionConfirmWrapper>
  );
};

export default ProxyDeletionConfirm;

import moment from 'moment';
import React from 'react';

import { IProxy, IArchivedProxy } from '../../../interfaces';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { PROXY_CHECK_TOOLTIP_LOCATIONS } from '../../../state/proxy/proxy-check/constants';
import { showProxyCheckTooltip } from '../../../state/proxy/proxy-check/proxy-check-tooltip.atom';
import { updateProxyStatuses } from '../../../state/proxy/proxy-check/proxy-statuses.atom';
import { ProxyCheckTooltipLocation } from '../../../state/proxy/proxy-check/types/proxy-check-tooltip-location.type';
import { checkArtificialProxyInForm } from '../../../state/proxy/proxy-operations/check-proxies.operations';
import { IconSpin } from '../../../ui/gologin-header/icons';
import { sendReactErrorToSentry } from '../../../utils/sentry.helper';
import {
  ARTIFICIAL_GEOPROXY_CHECK_WARNING,
  ARTIFICIAL_GEOPROXY_CHECK_WARNING_MESSAGE,
  GEOPROXY_TYPE_TAG,
  PROXY_COUNTRY_TAG,
  PROXY_GROUP_ID_TAG,
  PROXY_ID_TAG,
} from '../constants';
import { getProxyStatus, getIsProxyArchived, restoreProxy } from '../proxy-helpers';
import { ProxyStatusDot } from '../styles';
import { determineIsArtificialProxyId } from '../utils/proxy-id';

type CheckProxyStatusDotProps = {
  proxy: IProxy|IArchivedProxy;
  isSpinnerVisible: boolean;
  isChecking: boolean;
  proxyTooltipView: ProxyCheckTooltipLocation;
  profileId?: string;
  isSharedProxy?: boolean;
}

const CheckProxyStatusDot: React.FC<CheckProxyStatusDotProps> = (props) => {
  const { proxy, isSpinnerVisible, isChecking, proxyTooltipView, profileId, isSharedProxy = false } = props;

  const isProxyArchived = getIsProxyArchived(proxy);

  if (!isSpinnerVisible) {
    const status = isProxyArchived ? '' : getProxyStatus(proxy);

    return <ProxyStatusDot status={status} isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroupsV2} />;
  }

  const handleClick = async (): Promise<void> => {
    const isProxyCheckable = determineIsArtificialProxyId(proxy?.id);
    if (isProxyCheckable) {
      return sendReactErrorToSentry({
        transactionName: ARTIFICIAL_GEOPROXY_CHECK_WARNING,
        message: ARTIFICIAL_GEOPROXY_CHECK_WARNING_MESSAGE,
        level: 'warning',
        tags: [
          [PROXY_ID_TAG, proxy?.id],
          [PROXY_GROUP_ID_TAG, proxy?.groupId],
          [GEOPROXY_TYPE_TAG, proxy?.connectionType],
          [PROXY_COUNTRY_TAG, proxy?.country],
        ],
      });

      return;
    }

    let proxyToCheck = proxy;
    if (isProxyArchived) {
      proxyToCheck = await restoreProxy(proxy);
    }

    // We need this condition,
    // because the standard `updateProxyStatuses()` works only with "real" proxies in `proxyList`.
    // Here, we are to check an "artificial" proxy, that is present in the edit form, but not in `proxyList` yet.
    if (proxyTooltipView === PROXY_CHECK_TOOLTIP_LOCATIONS.proxyManagerEditForm) {
      await checkArtificialProxyInForm(proxyToCheck);

      return;
    }

    const checkedProxy = await updateProxyStatuses({
      proxies: [proxyToCheck],
      profileId,
      isSharedProxy,
      view: proxyTooltipView,
    });

    const fullCheckedProxy: IProxy = { ...proxyToCheck, ...checkedProxy, checkDate: moment().toDate() };
    showProxyCheckTooltip({
      profileIds: [profileId || ''],
      proxies: [fullCheckedProxy],
      view: proxyTooltipView,
      timeout: 2000,
    });
  };

  return (
    <IconSpin
      hasSpin={isChecking}
      size={16}
      iconColor='var(--767676-proxy-manager-check-proxy-button)'
      padding={0}
      onClick={handleClick}
    />
  );
};

export default CheckProxyStatusDot;

import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';

import { PAGE_BACKDROP_Z_INDEX } from '../components/page-overlay';

export const ProxySelectorWrapper = styled.div<{ isAppearedAboveBackdrop: boolean }>`
  height: 100%;
  width: 100%;
  padding-right: 32px;
  margin-left: 24px;

  position: relative;

  cursor: pointer;

  ${(props): SerializedStyles | null => props.isAppearedAboveBackdrop ? css`
    z-index: ${PAGE_BACKDROP_Z_INDEX + 1};
  ` : null};
`;

import * as Sentry from '@sentry/react';

import { LOAD_TRAFFIC_DATA_TRANSACTION, TRAFFIC_DATA_LOAD_TRIGGER_TAG, TrafficDataLoadTrigger } from '../../../features/proxy/constants';
import { getTrafficData } from '../../../ui/gologin-header/proxy-traffic-popover/api';
import { TRAFFIC_DATA_DEFAULT, updateIsTrafficDataLoading, updateTrafficData } from '../traffic-data.atom';

export const loadTrafficData = async (trafficDataLoadTrigger: TrafficDataLoadTrigger): Promise<void> => {
  const transaction = Sentry.startTransaction({ name: LOAD_TRAFFIC_DATA_TRANSACTION });
  transaction.setTag(TRAFFIC_DATA_LOAD_TRIGGER_TAG, trafficDataLoadTrigger);

  updateIsTrafficDataLoading(true);
  const trafficData = await getTrafficData();
  const normalizedTrafficData = typeof trafficData === 'string' ?
    TRAFFIC_DATA_DEFAULT : trafficData;

  updateTrafficData(normalizedTrafficData);

  transaction.finish();
};

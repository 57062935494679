import styled from '@emotion/styled';

export const QUICK_PRICING_MODAL_Z_INDEX = 1200;

export const PricingContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 0 240px;
`;

export const ContentContainer = styled('div')`
  padding: 0 48px;
`;

export const PricingTitle = styled('h1')`
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 28px;
  line-height: 40px;
  color: var(--36363D-new-pricing);
  max-width: 720px;
  text-align: center;
`;

export const PricingSubtitle = styled('h2')`
  margin: 16px 0 0 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: var(--36363D-new-pricing);
  text-align: center;
`;

export const SwitchContainer = styled('div')`
  margin-top: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const CardContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
  margin-top: 56px;
`;

export const InfoHeaderContainer = styled('div')`
  position: absolute;
  top: 24px;
  right: 48px;
  display: flex;
  align-items: center;
  column-gap: 24px;
`;

export const PricingPageCancelContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

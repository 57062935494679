import React, { useRef } from 'react';

import ProxyGroupAddButtonPopover, { ProxyGroupAddButtonPopoverPropsBase } from './proxy-group-add-button-menu-popover';
import { ADD_BUTTON_ARROW_ACTIVE_BACKGROUND_COLOR, ADD_BUTTON_ARROW_ACTIVE_COLOR, ADD_BUTTON_ARROW_COLOR, IconArrowDownWrapper } from './styles';
import { DivAnchor } from '../../../../../../types';
import { IconArrowDown } from '../../../../../../ui/gologin-header/icons/icon-arrow-down';

type ProxyGroupAddButtonMenuProps = ProxyGroupAddButtonPopoverPropsBase & {
  proxyGroupAddButtonMenuAnchorEl: DivAnchor;
  toggleProxyGroupAddButtonMenuAnchorEl: (newAnchorEl: DivAnchor) => void;
  closeProxyGroupAddButtonMenu: () => void;
  setIsArrowHovered: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProxyGroupAddButtonMenu: React.FC<ProxyGroupAddButtonMenuProps> = (props) => {
  const {
    proxyGroupAddButtonMenuAnchorEl,
    toggleProxyGroupAddButtonMenuAnchorEl,
    closeProxyGroupAddButtonMenu,
    setIsArrowHovered,
  } = props;

  const buttonWrapperRef = useRef<DivAnchor>(null);

  const isHighlighted = !!proxyGroupAddButtonMenuAnchorEl;

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    toggleProxyGroupAddButtonMenuAnchorEl(buttonWrapperRef.current);
  };

  const handleClose = (): void => {
    closeProxyGroupAddButtonMenu();
  };

  return (
    <>
      <IconArrowDownWrapper
        ref={buttonWrapperRef}
        isHighlighted={isHighlighted}
        onClick={handleClick}
        onMouseOver={(): void => setIsArrowHovered(true)}
        onMouseLeave={(): void => setIsArrowHovered(false)}
      >
        <IconArrowDown
          padding='4px 2px'
          margin='0 0 0 5px'
          borderRadius={4}
          hoveredHighlight={true}
          highlight={isHighlighted}
          iconColor={isHighlighted ? ADD_BUTTON_ARROW_ACTIVE_COLOR : ADD_BUTTON_ARROW_COLOR}
          iconHoveredColor={ADD_BUTTON_ARROW_ACTIVE_BACKGROUND_COLOR}
        />
      </IconArrowDownWrapper>
      <ProxyGroupAddButtonPopover
        {...props}
        anchorElement={proxyGroupAddButtonMenuAnchorEl}
        handleClose={handleClose}
      />
    </>
  );
};

export default ProxyGroupAddButtonMenu;

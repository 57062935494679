import { IProxy, ProxyMode } from '../../app/interfaces';

export enum GeoProxyType {
  Resident = 'resident',
  Mobile = 'mobile',
  DataCenter = 'dataCenter',
}

export const geoProxyTypes = Object.values(GeoProxyType);
export type ProxyGeoTypes = (typeof geoProxyTypes)[number];

export const ORDERED_GEO_PROXY_TYPES_FOR_EDIT_BROWSER_PAGE = <const>[GeoProxyType.Resident, GeoProxyType.DataCenter, GeoProxyType.Mobile];

type TruthyProxyMode = Exclude<ProxyMode, 'none'>;
type TruthyProxy = Omit<IProxy, 'mode'> & {
  mode: TruthyProxyMode;
};

export const determineIsProxyTruthy = (proxy: IProxy | null): proxy is TruthyProxy =>
  !!proxy && proxy.mode !== 'none' && (['tor', 'gologin'].includes(proxy.mode) || !!proxy.id);

type TorOrFreeProxyMode = Extract<ProxyMode, 'tor' | 'gologin'>;
export type TorOrFreeProxy = Omit<IProxy, 'mode'> & {
  mode: TorOrFreeProxyMode;
};

export const determineIsTorOrFreeProxy = (proxy: IProxy): proxy is TorOrFreeProxy => proxy && ['tor', 'gologin'].includes(proxy.mode);

type GologinProxyMode = Extract<ProxyMode, 'tor' | 'gologin' | 'geolocation'>;
type GologinProxy = Omit<IProxy, 'mode'> & {
  mode: GologinProxyMode;
};

export const determineIsGologinProxy = (proxy: IProxy): proxy is GologinProxy => ['tor', 'gologin', 'geolocation'].includes(proxy.mode);

export const determineIsGeoProxyType = (value: string): value is GeoProxyType => (<any>Object).values(GeoProxyType).includes(value);

// TODO: remove `residential` in favor of `resident` by replacing the enum with `GeoProxyType`
export enum GeoProxyPromocodesType {
  Resident = 'residential',
  Mobile = 'mobile',
  DataCenter = 'dataCenter',
}

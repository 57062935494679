import { THighEntropyHints } from '../interfaces/browser-navigator.interfaces';

export type OS = 'lin' | 'win' | 'mac' | 'android';
export type OS_SPEC = 'win11' | 'M1' | 'M2' | 'M3' | 'M4';
export interface IOSParams {
  name: OS;
  arch?: 'arm' | 'intel';
  spec?: OS_SPEC;
}

type OS_VERSION_FROM_WEB = { os: Exclude<OS, 'android'> | ''; osRelease: string };

let ipcRenderer: Electron.IpcRenderer;
const isElectron = !!window.require;
if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

let cachedDeviceFingerprint = '';

export const getUserOS = async (): Promise<IOSParams> => {
  if (!isElectron) {
    return {
      name: 'lin',
    };
  }

  const os = require('os');

  switch (os.platform()) {
    case 'win32': {
      const isWin11 = await getIsWin11();

      if (isWin11) {
        return { name: 'win', spec: 'win11' };
      }

      return { name: 'win' };
    }
    case 'darwin': {
      if (os.arch() === 'arm64') {
        const spec = await getMacArmSpec();

        return {
          name: 'mac',
          arch: 'arm',
          spec,
        };
      }

      return {
        name: 'mac',
        arch: 'intel',
      };
    }
    default:
      return { name: 'lin' };
  }
};

const getMacArmSpec = async (): Promise<Exclude<OS_SPEC, 'win11'>> => {
  const { exec } = require('child_process');
  const { promisify } = require('util');

  const doExec = promisify(exec);

  const { stdout } = await doExec('sysctl machdep.cpu');
  const regExp = /Apple M\d/;
  const [match] = stdout.match(regExp);
  const [_, armVersion] = match.split(' ');

  return armVersion;
};

export const getOSReleaseVersionForWeb = (): OS_VERSION_FROM_WEB => {
  const result: OS_VERSION_FROM_WEB = { os: '', osRelease: '' };
  const { userAgent } = navigator;

  if (/Macintosh/.test(userAgent)) {
    const [_, version = ''] = /Mac OS X (\d+([_\\.\s]\d+)*)/.exec(userAgent) || [];
    result.os = 'mac';
    result.osRelease = version;
  }

  if (/Linux/.test(userAgent)) {
    result.os = 'lin';
    let version = '';
    let osBuild = '';

    switch (true) {
      case /Ubuntu/.test(userAgent):
        osBuild = 'Ubuntu';
        break;
      case /Mint/.test(userAgent):
        osBuild = 'Mint';
        break;
      case /Debian/.test(userAgent):
        osBuild = 'Debian';
        break;
      default:
        osBuild = '';
    }

    if (osBuild) {
      const regex = new RegExp(`${osBuild}/([\\d\\.]+)`);
      version = matchVersion(userAgent, regex, osBuild.toLowerCase());
    }

    result.osRelease = version;
  }

  if (/Windows/.test(userAgent)) {
    const [_, version = ''] = /Windows NT (\d+([_\\.\s]\d+)*)/.exec(userAgent) || [];
    result.os = 'win';
    result.osRelease = version;
  }

  return result;
};

const matchVersion = (userAgent: string, regex: RegExp, build: string): string => {
  let version = '';
  const versionMatch = userAgent.match(regex);
  if (versionMatch) {
    const [_, osVersion = ''] = versionMatch;
    if (!osVersion) {
      return build;
    }

    version = `${build}_${osVersion}`;
  }

  return version;
};

export const getGologinMetaHeader = async (): Promise<string> => {
  if (!isElectron) {
    const { os, osRelease } = getOSReleaseVersionForWeb();

    return `site-${os}-${osRelease}`;
  }

  const osUtils = require('os');
  const appRelease = window.gologinAppVersion?.split(/\s+/)?.join(' ') || '';
  const os = await getUserOS();
  const osRelease = osUtils.release() || '';

  return `${appRelease}-${os.name}-${osRelease}`;
};

export const getDeviceFingerprint = async () => {
  if (!ipcRenderer) {
    return '';
  }

  if (cachedDeviceFingerprint) {
    return cachedDeviceFingerprint;
  }

  cachedDeviceFingerprint = await ipcRenderer.invoke('get-device-fingerprint').catch(() => '');

  return cachedDeviceFingerprint;
};

const getClientHighEntropyValues = async (hints: THighEntropyHints[]): Promise<any> => {
  const {
    navigator: {
      userAgentData,
    },
  } = window;

  const res = await userAgentData.getHighEntropyValues(hints);

  return res;
};

const getIsWin11 = async (): Promise<boolean> => {
  const { platformVersion = '' } = await getClientHighEntropyValues(['platformVersion']);
  const [majorPlatrformVersionString] = platformVersion.split('.');
  const majorPlatrformVersionValue = Number(majorPlatrformVersionString) || 10; // по умолчанию - win10

  return majorPlatrformVersionValue >= 13;
};

export const modifyUserAgentForWin11 = (userAgent: string): string => {
  const regex = /Windows NT (\d+(\.\d+)*)/;
  const newPlatformVersion = '10.0';
  const modifiedUserAgent = userAgent.replace(regex, `Windows NT ${newPlatformVersion}`);

  return modifiedUserAgent;
};

import css from '@emotion/css';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import PriceList from './price-list';
import {
  ProxyTrafficPopoverLoaderWrapper,
  ProxyTrafficPopoverProxyTypeHintWrapper,
  ProxyTrafficPopoverTrialHintWrapper,
  ProxyTrafficPopoverRow,
  VolumeProxyContainer,
} from './styles';
import { GeoProxyType } from '../../../../common/constants/types';
import { stringifyRemainingTrafficAmount } from '../../../../common/proxy/traffic/utils';
import {
  GEO_PROXY_TYPE_HINTS,
  GeoProxyTypeHint,
} from '../../../features/proxy/proxy-manager/proxy-list/proxy-group-add-button/proxy-group-add-button-menu/constants';
import {
  POPOVER_DEFAULT_PADDING,
} from '../../../features/proxy/proxy-manager/proxy-list/proxy-group-add-button/proxy-group-add-button-menu/proxy-group-add-button-menu-popover';
import {
  AddButtonPopoverRowContent,
  AddButtonPopoverRowRightControls,
  AddButtonPopoverRowTitle,
} from '../../../features/proxy/proxy-manager/proxy-list/proxy-group-add-button/proxy-group-add-button-menu/styles';
import { IMenuItem } from '../../../features/quickProfiles/profile-dropdown-menu/interfaces';
import { useIsTrafficDataLoading, useTrafficData } from '../../../state/proxy/traffic-data.atom';
import GologinPopover from '../../gologin-popover';
import { GologinPopoverHint } from '../../gologin-popover/gologin-popover-hint';
import { itemRowIconProps } from '../account-menu/menu-items';
import { IconPlusFill, IconSpinner } from '../icons';
import { IconHome } from '../icons/icon-home';
import { IconProxyDataCenterSmall } from '../icons/icon-proxy-datacenter-small';
import { IconProxyMobile } from '../icons/icon-proxy-mobile';

type GeoProxyTypeItem = Omit<IMenuItem, 'name' | 'onAction'> & {
  name: GeoProxyType;
  hint: {
    translationKey: string;
  };
}

type ProxyTrafficPopoverProps = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  isTrial: boolean;
}

const ProxyTrafficPopover: React.FC<ProxyTrafficPopoverProps> = (props) => {
  const { anchorEl, onClose, isTrial } = props;

  const [anchorElPriceList, setAnchorElPriceList] = useState<HTMLElement | null>(null);
  const [selectedType, setSelectedType] = useState<GeoProxyType>(GeoProxyType.Mobile);

  const trafficData = useTrafficData();
  const isTrafficDataLoading = useIsTrafficDataLoading();

  const [activeGeoProxyHint, setActiveGeoProxyHint] = useState<GeoProxyTypeHint | null>(null);

  const geoProxyTypeItems: GeoProxyTypeItem[] = [
    {
      name: GeoProxyType.Resident,
      translationKey: `proxyTypes.${GeoProxyType.Resident}`,
      icon: <IconHome {...itemRowIconProps} />,
      hint: { translationKey: GEO_PROXY_TYPE_HINTS[GeoProxyType.Resident] },
    },
    {
      name: GeoProxyType.Mobile,
      translationKey: `proxyTypes.${GeoProxyType.Mobile}`,
      icon: <IconProxyMobile {...itemRowIconProps} />,
      hint: { translationKey: GEO_PROXY_TYPE_HINTS[GeoProxyType.Mobile] },
    },
    {
      name: GeoProxyType.DataCenter,
      translationKey: `proxyTypes.${GeoProxyType.DataCenter}`,
      icon: <IconProxyDataCenterSmall {...itemRowIconProps} />,
      hint: { translationKey: GEO_PROXY_TYPE_HINTS[GeoProxyType.DataCenter] },
    },
  ];

  const openPriceList = (type: GeoProxyType, currentTarget: HTMLDivElement): void => {
    setSelectedType(type);
    setAnchorElPriceList(currentTarget);
  };

  const renderMenuItemRightControls = (geoProxyType: GeoProxyType): JSX.Element => {
    const stringifiedRemainingTrafficAmount = stringifyRemainingTrafficAmount(trafficData, geoProxyType);

    return (
      <VolumeProxyContainer onClick={(event): void => openPriceList(geoProxyType, event.currentTarget)}>
        <Trans
          i18nKey='proxyManager.trafficRemainingLimit'
          values={{ limit: stringifiedRemainingTrafficAmount }}
        />
        <IconPlusFill
          padding={0}
          margin='0 0 0 8px'
          iconColor='var(--00A987-header-buy-proxy)'
          iconHoveredColor='var(--00997A-header-buy-proxy)'
        />
      </VolumeProxyContainer>
    );
  };

  const renderMenuItem = (menuItem: GeoProxyTypeItem): JSX.Element => {
    const { name, translationKey, icon } = menuItem;

    const handleMenuItemClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
      event.stopPropagation();
    };

    return (
      <ProxyTrafficPopoverRow
        key={name}
        style={{
          height: 28,
          padding: '4px 12px',
        }}
        onClick={handleMenuItemClick}
        onMouseOver={(): void => {
          setActiveGeoProxyHint(GEO_PROXY_TYPE_HINTS[name]);
        }}
        onMouseLeave={(): void => {
          setActiveGeoProxyHint(null);
        }}
      >
        {icon}
        <AddButtonPopoverRowContent>
          <AddButtonPopoverRowTitle>
            <Trans i18nKey={translationKey} />
          </AddButtonPopoverRowTitle>
          <AddButtonPopoverRowRightControls>
            {renderMenuItemRightControls(name)}
          </AddButtonPopoverRowRightControls>
        </AddButtonPopoverRowContent>
      </ProxyTrafficPopoverRow>
    );
  };

  const renderPopoverContent = (): JSX.Element => {
    if (isTrafficDataLoading) {
      return (
        <ProxyTrafficPopoverLoaderWrapper>
          <IconSpinner size={24} padding={0} />
        </ProxyTrafficPopoverLoaderWrapper>
      );
    }

    return (
      <>
        {geoProxyTypeItems.map(renderMenuItem)}
        <ProxyTrafficPopoverProxyTypeHintWrapper onClick={(event): void => event.stopPropagation()}>
          <Trans i18nKey={activeGeoProxyHint || GEO_PROXY_TYPE_HINTS.default} />
        </ProxyTrafficPopoverProxyTypeHintWrapper>
        {isTrial ? (
          <ProxyTrafficPopoverTrialHintWrapper>
            <GologinPopoverHint
              style={{ padding: POPOVER_DEFAULT_PADDING }}
              onClick={(event): void => event.stopPropagation()}
            >
              <Trans i18nKey={GEO_PROXY_TYPE_HINTS.trialDefault} />
            </GologinPopoverHint>
          </ProxyTrafficPopoverTrialHintWrapper>
        ) : null}
      </>
    );
  };

  return (
    <>
      <GologinPopover
        anchorEl={anchorEl}
        onClose={(event): void => {
          event.stopPropagation();
          onClose();
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        extraTranslate={{ left: 9, bottom: 4 }}
        width={271}
        popoverStyle={css`
          padding: 12px 4px 16px;
          overflow: visible;
          gap: 0;
        `}
        zIndex={1100}
      >
        {renderPopoverContent()}
      </GologinPopover>
      <PriceList
        anchorEl={anchorElPriceList}
        onClose={(): void => {
          setAnchorElPriceList(null);
        }}
        price={trafficData.prices}
        geoProxyType={selectedType}
        availableForPurchase={trafficData.availableForPurchase}
      />
    </>
  );
};

export default ProxyTrafficPopover;

import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { DescriptionContainer } from './styles';
import ActionButtons from '../../../../ui/action-buttons';
import GologinModal from '../../../../ui/gologin-modal';
import { closeProfilesTableModal, PAYMENT_EXPIRED_MODAL_NAME, useProfilesTableModalIsVisible } from '../../../../state/profiles-table-modal.atom';
import { userContext, workspaceContext } from '../../../../state';
import { openWebsitePricing } from '../../../../utils/open-site';
import { IconWarningTriangle } from '../../../../ui/gologin-header/icons/icon-warning-triangle';
import { sendActionAnalytics } from '../../api';
import { E_ANALYTICS_ACTIONS } from '../../../../../common/constants/analytics';
import { useCurrentWorkspaceId } from '../../../../state/current-workspace-id.atom';

const PaymentExpiredModal: React.FC = () => {
  const isModalVisible = useProfilesTableModalIsVisible(PAYMENT_EXPIRED_MODAL_NAME);

  const { t: translation } = useTranslation();

  const {
    isQuickSettingsEnabled,
  } = useContext(userContext);

  const workspaceId = useCurrentWorkspaceId();

  const redirectToPricing = (): void => {
    hideModal();

    if (!workspaceId) {
      return;
    }

    sendActionAnalytics(E_ANALYTICS_ACTIONS.clickedBuyPlan, { actionInfo: 'trial finished modal' });
    openWebsitePricing({
      workspaceId,
      isQuickSettingsEnabled,
    });
  };

  const hideModal = () => {
    closeProfilesTableModal();
  };

  return (
    <GologinModal
      isVisible={isModalVisible}
      onClose={hideModal}
      width='480px'
    >
      <DescriptionContainer>
        <IconWarningTriangle padding={'2px 0px 0px 0px'} />
        <Trans i18nKey={'trialFinishedModal.description'} />
      </DescriptionContainer>
      <ActionButtons
        confirmText={translation('base.upgrade')}
        onConfirm={redirectToPricing}
        onCancel={hideModal}
        isCancelFirst={true}
      />
    </GologinModal>
  );
};

export default PaymentExpiredModal;

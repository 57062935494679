import React from 'react';
import { Trans } from 'react-i18next';

import {
  EmptyProxyListAddIconWrapper,
  EmptyProxyListPlaceholderContent,
  EmptyProxyListPlaceholderText,
  EmptyProxyListPlaceholderWrapper,
  EmptyProxyListSearchIconWrapper,
} from './styles';
import { openProxyManager } from '../../../../../state/proxy/proxy-manager-modal-status.atom';
import { clearProxySearch } from '../../../../../state/proxy/proxy-search.atom';
import { IconAddGeolocation } from '../../../../../ui/gologin-header/icons/icon-add-geolocation';
import { IconSearchGeolocation } from '../../../../../ui/gologin-header/icons/icon-search-geolocation';

type EmptyListPlaceholderItemProps = {
  localProxyElementLocation: string | null;
  style: React.CSSProperties;
}

export const EmptyProxyListPlaceholder: React.FC<EmptyListPlaceholderItemProps> = (props) => {
  const { localProxyElementLocation, style } = props;

  const handleClick: React.MouseEventHandler<HTMLDivElement> = () => {
    clearProxySearch();
    openProxyManager({
      modalView: 'proxy-add',
      currentProxy: null,
      currentProfileId: null,
      proxySelectorLocation: localProxyElementLocation,
    });
  };

  return (
    <EmptyProxyListPlaceholderWrapper style={style}>
      <EmptyProxyListPlaceholderContent onClick={handleClick}>
        <EmptyProxyListSearchIconWrapper>
          <IconSearchGeolocation
            padding={0}
            iconColor='var(--B5B5BA)'
          />
        </EmptyProxyListSearchIconWrapper>
        <EmptyProxyListAddIconWrapper>
          <IconAddGeolocation
            padding={0}
            iconColor='var(--00A987-proxy-manager)'
          />
        </EmptyProxyListAddIconWrapper>
        <EmptyProxyListPlaceholderText>
          <Trans i18nKey='proxyManager.emptyProxyListPlaceholder' />
        </EmptyProxyListPlaceholderText>
      </EmptyProxyListPlaceholderContent>
    </EmptyProxyListPlaceholderWrapper>
  );
};

import { Select } from 'antd';
import React, { FC, useEffect, useRef, useState } from 'react';

import { ArrowDown, CustomMenu, SelectAdvanced, SelectButtonContainer, SelectResolutionMenuItem } from './styles';
import { RESOLUTIONS } from '../all-resolutions';

const { Option } = Select;

interface IResolutionManager {
  updateResolution: (value: string) => void;
  resolution: string;
  isDisabled?: boolean;
  width?: string;
  oldStyle?: boolean;
  autoOpen?: boolean;
  onClose?: () => void;
}

const ResolutionManager: FC<IResolutionManager> = (props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const selectRef = useRef<HTMLButtonElement>(null);

  const { updateResolution, resolution, isDisabled, width, oldStyle, autoOpen, onClose } = props;

  const openSelect = (): void => {
    setAnchorEl(selectRef.current);
  };

  useEffect(() => {
    if (autoOpen) {
      openSelect();
    }
  }, []);

  if (oldStyle) {
    return (
      <SelectAdvanced
        width={width}
        value={resolution}
        onChange={(resolution): void => {
          if (typeof resolution !== 'string') {
            return;
          }

          updateResolution(resolution);
        }}
        disabled={isDisabled}
        dropdownMenuStyle={{ maxHeight: 170 }}
      >
        {RESOLUTIONS.map(item => (
          <Option key={item} value={item}>
            {item}
          </Option>
        ))}
      </SelectAdvanced>
    );
  }

  return (
    <>
      <SelectButtonContainer
        onClick={(e): void => setAnchorEl(e.currentTarget)}
        disabled={isDisabled}
        width={width}
        ref={selectRef}
      >
        {resolution}
        {!isDisabled && <ArrowDown />}
      </SelectButtonContainer>
      <CustomMenu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={(): void => {
          onClose && onClose();
          setAnchorEl(null);
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 0, horizontal: 0 }}
        width={width}
      >
        {RESOLUTIONS.map(item => (
          <SelectResolutionMenuItem
            onClick={(): void => {
              updateResolution(item);
              setAnchorEl(null);
            }}
            key={item}
            isSelected={resolution === item}
          >
            {item}
          </SelectResolutionMenuItem>
        ))}
      </CustomMenu>
    </>
  );
};

export default ResolutionManager;

/* eslint-disable id-length */
import styled from '@emotion/styled/macro';

import { textFont } from '../../../../ui/style-templates';

export const NoGroupsProxyManagerProxyStatusWrapper = styled.div`
  width: 18px;
  height: 18px;
  padding: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;

export const GroupedProxyManagerProxyStatusWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  /* gap */
  & > * + * {
    margin-left: 6px;
  }
`;

export const ProxyStatusDescription = styled.span`
  ${textFont()}

  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;

  color: var(--767676-proxy-status-description-color);
`;

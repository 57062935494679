import moment from 'moment';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ProxyInfoRowWrapper, ProxyInfoUpdatedAgoDateWrapper, ProxyStatusDotWrapper, ProxyStatusIconWrapper } from './styles';
import { determineIsProxyCheckFailed } from '../../../../../common/proxy/utils';
import { IProxy } from '../../../../interfaces';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import IconWarningTr from '../../../../ui/icons/IconWarningTr';
import { getProxyStatus } from '../../proxy-helpers';
import { ProxyStatusDot } from '../../styles';

type ProxyInfoCheckDateProps = {
  proxy: IProxy;
}

const ProxyInfoCheckDate: React.FC<ProxyInfoCheckDateProps> = (props) => {
  const { proxy } = props;

  const { t: translation, i18n } = useTranslation();
  moment.locale(i18n.language);

  const isTorOrFree = ['gologin', 'tor'].includes(proxy.mode);
  const checkedAgo = proxy.checkDate ? moment(proxy.checkDate).fromNow() : '';

  if (isTorOrFree) {
    return (
      <ProxyInfoRowWrapper>
        <ProxyStatusDotWrapper>
          <ProxyStatusDot status='success' isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroupsV2} />
        </ProxyStatusDotWrapper>
        <ProxyInfoUpdatedAgoDateWrapper>
          <Trans i18nKey='proxies.connected' />
        </ProxyInfoUpdatedAgoDateWrapper>
      </ProxyInfoRowWrapper>
    );
  }

  const status = getProxyStatus(proxy);

  const renderStatusDot = (): JSX.Element => (
    <ProxyStatusDotWrapper>
      <ProxyStatusDot status={status} isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroupsV2} />
    </ProxyStatusDotWrapper>
  );

  if (!proxy.checkDate) {
    return (
      <ProxyInfoRowWrapper>
        {renderStatusDot()}
        <ProxyInfoUpdatedAgoDateWrapper>
          <Trans i18nKey='proxies.clickToCheckTheConnection' />
        </ProxyInfoUpdatedAgoDateWrapper>
      </ProxyInfoRowWrapper>
    );
  }

  if (determineIsProxyCheckFailed(proxy)) {
    return (
      <div>
        <ProxyInfoRowWrapper>
          {renderStatusDot()}
          <ProxyInfoUpdatedAgoDateWrapper>
            <Trans i18nKey='proxies.updatedWithSpace' values={{ dateChecked: checkedAgo }} />
          </ProxyInfoUpdatedAgoDateWrapper>
        </ProxyInfoRowWrapper>
        <ProxyInfoRowWrapper>
          <ProxyStatusIconWrapper isFail={true}>
            <IconWarningTr />
          </ProxyStatusIconWrapper>
          <ProxyInfoUpdatedAgoDateWrapper isFail={true}>
            {translation(proxy.error || translation('notifications.error.proxyError'))}
          </ProxyInfoUpdatedAgoDateWrapper>
        </ProxyInfoRowWrapper>
      </div>
    );
  }

  return (
    <ProxyInfoRowWrapper>
      {renderStatusDot()}
      <ProxyInfoUpdatedAgoDateWrapper>
        <Trans i18nKey='proxies.updatedWithSpace' values={{ dateChecked: checkedAgo }} />
      </ProxyInfoUpdatedAgoDateWrapper>
    </ProxyInfoRowWrapper>
  );
};

export default ProxyInfoCheckDate;

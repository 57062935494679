import { ProfilesTableColumn } from './components/interfaces/app-settings.interface';

const LOCATION_FIRST_SESSION_DEFAULT_COLUMNS_ORDER: ProfilesTableColumn[] = [
  'name', 'status', 'notes', 'customStatus', 'proxy',
  'tags', 'lastUpdate', 'lastLaunch', 'createdAt', 'sharing',
  'proxyType', 'proxyPort', 'proxyUsername', 'proxyPassword', 'os', 'orbitaVersion',
];

const DEFAULT_COLUMNS_ORDER: ProfilesTableColumn[] = [
  'name', 'status', 'notes', 'customStatus', 'tags',
  'lastUpdate', 'lastLaunch', 'createdAt', 'sharing',
  'proxyType', 'proxy', 'proxyPort', 'proxyUsername', 'proxyPassword', 'os', 'orbitaVersion',
];

type ProfilesTableColumnsOrderEvaluateParams = {
  isProxyColumnShownAmongFirstColumns: boolean;
}

export const evaluateProfilesTableColumnsOrder = ({
  isProxyColumnShownAmongFirstColumns,
}: ProfilesTableColumnsOrderEvaluateParams): ProfilesTableColumn[] => {
  if (isProxyColumnShownAmongFirstColumns) {
    return LOCATION_FIRST_SESSION_DEFAULT_COLUMNS_ORDER;
  }

  return DEFAULT_COLUMNS_ORDER;
};

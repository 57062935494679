import React from 'react';
import { Trans } from 'react-i18next';

import { GeoProxyTrafficTooltipList, GeoProxyTrafficTooltipWrapper } from './styles';
import { GeoProxyType } from '../../../../../../../common/constants/types';

const TYPES_LIST_ORDER = [GeoProxyType.DataCenter, GeoProxyType.Resident, GeoProxyType.Mobile];

type GeoProxyTrafficTooltipProps = {
  trafficByType: Record<GeoProxyType, string>;
}

const GeoProxyTrafficTooltip: React.FC<GeoProxyTrafficTooltipProps> = (props) => {
  const { trafficByType } = props;

  const renderTypeItem = (geoProxyType: GeoProxyType): JSX.Element => {
    const trafficAmount = trafficByType[geoProxyType];

    return (
      <li key={geoProxyType}>
        <Trans
          i18nKey={`pricing.geoProxyTrafficTooltip.${geoProxyType}`}
          values={{ amount: trafficAmount }}
        />
      </li>
    );
  };

  return (
    <GeoProxyTrafficTooltipWrapper>
      <span>
        <Trans i18nKey='pricing.geoProxyTrafficTooltip.description' />
      </span>
      <GeoProxyTrafficTooltipList>
        {TYPES_LIST_ORDER.map(renderTypeItem)}
      </GeoProxyTrafficTooltipList>
    </GeoProxyTrafficTooltipWrapper>
  );
};

export default GeoProxyTrafficTooltip;

import styled from '@emotion/styled';

export const DescriptionContainer = styled('div')`
  display: flex;
  gap: 10px;

  font: 400 16px/22px 'Roboto';
  letter-spacing: 0.42px;
  color: var(--696973-payment-expired-modal-text);
`;

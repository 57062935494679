import { IProfileFBAccount } from './interfaces';
import { API_BASE_URL } from '../../../common/constants/constants';
import { IProxy } from '../../interfaces';
import { http } from '../../services';
import { OS, OS_SPEC } from '../../utils/user-os';

export interface IDataToCreateProfile {
  profileName?: string;
  fileContent?: string;
  cookies?: string;
  proxy?: IProxy;
  facebookAccount?: IProfileFBAccount;
}

export interface IImportSourceData {
  sources: IDataToCreateProfile[];
  workspaceId: string;
  selectedFolder: string;
  userOs: OS;
  osSpec?: OS_SPEC;
  isCreateMultipleProfiles?: boolean;
  resolution: string;
}

export const createProfileByData = async (data: IImportSourceData): Promise<string[]> => {
  const { sources, workspaceId, userOs, selectedFolder, isCreateMultipleProfiles, osSpec, resolution = '' } = data;

  const result = await http(`${API_BASE_URL}/browser/features/import/files`, {
    method: 'POST',
    body: JSON.stringify({
      files: sources,
      workspaceId,
      os: userOs,
      osSpec,
      folderName: selectedFolder,
      isCreateMultipleProfiles,
      resolution,
    }),
  });

  return result.body;
};

import { useCallback, useState } from 'react';

import { requestUserWorkspaces, requestUserWorkspacesWithFolders } from '../features/common/api';
import { IWorkspace, IWorkspaceShort } from '../interfaces/workspaces';
import { history } from '../services';
import { IUpdateWorkspaceContextData, IUserCtx, IWorkspaceContextData, IWorkspaceCtx, WORKSPACE_DEFAULT_VALUE } from '../state';
import { setCurrentWorkspaceId, useCurrentWorkspaceId } from '../state/current-workspace-id.atom';
import { setAllProfilesFolderId, useAllProfilesFolderId } from '../state/folders/all-profiles-folder-id.atom';
import { onWorkspaceDataUpdate } from '../state/on-workspace-data-update';

export const useWorkspace = (): IWorkspaceCtx => {
  const currentWorkspaceId = useCurrentWorkspaceId();
  const allProfilesFolderId = useAllProfilesFolderId() || null;

  const [workspace, setWorkspace] = useState<IWorkspaceContextData>(WORKSPACE_DEFAULT_VALUE);
  const [availableWorkspaces, setAvailableWorkspaces] = useState<IWorkspaceShort[]>([]);

  const updateWorkspace = useCallback((partial: Partial<IUpdateWorkspaceContextData>): void => {
    const newWorkspaceId = partial.id;
    const newAllProfilesFolderId = partial.allProfilesFolderId;
    if (newAllProfilesFolderId || newAllProfilesFolderId === null) {
      setAllProfilesFolderId(newAllProfilesFolderId);
    }

    delete partial.id;
    delete partial.allProfilesFolderId;

    const isSameWorkspace = !newWorkspaceId || currentWorkspaceId === newWorkspaceId;
    setWorkspace(prev => {
      if (isSameWorkspace) {
        onWorkspaceDataUpdate(prev, partial);

        return {
          ...prev,
          ...partial,
        };
      }

      return {
        ...WORKSPACE_DEFAULT_VALUE,
        userId: prev.userId,
        ...partial,
      };
    });

    if (!isSameWorkspace) {
      setCurrentWorkspaceId(newWorkspaceId || null);
    }

    const hasProfileCount = Object.prototype.hasOwnProperty.call(partial, 'profilesCount');
    const shouldUpdateWorkspaceInAvailable = isSameWorkspace && (partial.members || partial.name || partial.planId || hasProfileCount);
    if (shouldUpdateWorkspaceInAvailable) {
      setAvailableWorkspaces(prev => prev
        .map(availableWorkspace => {
          if (availableWorkspace.id !== currentWorkspaceId) {
            return availableWorkspace;
          }

          return {
            ...availableWorkspace,
            memberCount: partial.members ? partial.members.length : availableWorkspace.memberCount,
            name: partial.name ?? availableWorkspace.name,
            planId: partial.planId ?? availableWorkspace.planId,
            planName: partial.planName ?? availableWorkspace.planName,
            planProfilesMax: partial.planProfilesMax ?? availableWorkspace.planProfilesMax,
            planMembersMax: partial.planMembersMax ?? availableWorkspace.planMembersMax,
            profilesCount: partial.profilesCount ?? availableWorkspace.profilesCount,
          };
        }),
      );
    }
  }, [currentWorkspaceId]);

  const updateAvailableWorkspaces = useCallback((newWorkspaces: IWorkspaceShort[], isLogout?: boolean): void => {
    setAvailableWorkspaces(newWorkspaces);

    if (!(isLogout || newWorkspaces.length)) {
      history.push('/no_workspace');
    }
  }, []);

  const updateAvailableWorkspacesFolders = useCallback(async () => {
    const { workspacesWithFolders } = await requestUserWorkspacesWithFolders();
    const workspaceIds = availableWorkspaces.map(availableWorkspace => availableWorkspace.id);
    const workspaceIdsWithFolders = workspacesWithFolders.map(workspaceWithFolder => workspaceWithFolder.id);

    const isSameWorkspaceList = (workspaceIds.length === workspaceIdsWithFolders.length) &&
      workspaceIds.every((element, index) => element === workspaceIdsWithFolders[index]);

    const mergeWorkspacesLists = (workspaces: IWorkspaceShort[]): void => {
      const mergedWorkspaces = workspaces.map(prevWorkspace => {
        const workspaceWithFolder = workspacesWithFolders.find(newWorkspace => prevWorkspace.id === newWorkspace.id);
        const hasFolders = Object.prototype.hasOwnProperty.call(workspaceWithFolder, 'folders');
        if (!(workspaceWithFolder && hasFolders)) {
          return prevWorkspace;
        }

        return {
          ...prevWorkspace,
          folders: workspaceWithFolder.folders,
          permissions: workspaceWithFolder.permissions,
        };
      });

      updateAvailableWorkspaces(mergedWorkspaces);
    };

    if (isSameWorkspaceList) {
      mergeWorkspacesLists(availableWorkspaces);

      return;
    }

    const { workspaces } = await requestUserWorkspaces();
    mergeWorkspacesLists(workspaces);
  }, [availableWorkspaces]);

  const addNewAvailableWorkspace = useCallback((newWorkspace: IWorkspace) => {
    setAvailableWorkspaces(prev => [...prev, {
      ...newWorkspace,
      memberCount: newWorkspace.members.length,
      permissions: {
        canAddBilling: newWorkspace.permissions.editWorkspace,
        canClaimProfilesWithoutFolder: newWorkspace.permissions.editWorkspace,
      },
    }]);
  }, []);

  const updateAvailableWorkspace = useCallback((workspaceId: string, workspaceData: Partial<IWorkspaceShort>) => {
    setAvailableWorkspaces(prev => prev.map(avaliableWorkspace => {
      if (avaliableWorkspace.id !== workspaceId) {
        return avaliableWorkspace;
      }

      return {
        ...avaliableWorkspace,
        ...workspaceData,
      };
    }));
  }, []);

  const isFirstPlanSelected = useCallback(
    (userCtx: IUserCtx): boolean => userCtx.firstPlanSelected || workspace.owner !== userCtx._id,
    [workspace.owner]);

  const setProfilesCount = useCallback((profilesCount: number): void => {
    updateWorkspace({ id: currentWorkspaceId || '', profilesCount });
  }, [currentWorkspaceId]);

  const me = workspace.members.find(member => member.user === workspace.userId);

  return {
    ...workspace,
    allProfilesFolderId,
    id: currentWorkspaceId || '',
    updateWorkspace,
    me,
    isLoaded: !!me,
    availableWorkspaces,
    updateAvailableWorkspaces,
    updateAvailableWorkspace,
    updateAvailableWorkspacesFolders,
    addNewAvailableWorkspace,
    isFirstPlanSelected,
    setProfilesCount,
  };
};

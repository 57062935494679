import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IFullProfile, IProfile } from '../../../../interfaces';
import { templatesContext } from '../../../../state';
import { useProfilesSettingsState } from '../../../../state/profiles-settings-atom';
import { IconSpin } from '../../../../ui/gologin-header/icons';
import { IconCheckFingerprint } from '../../../../ui/gologin-header/icons/icon-check-fingerprint';
import { ANDROID_RESOLUTIONS, RESOLUTIONS } from '../../../common/all-resolutions';
import { updateMultipleFingerprints } from '../../../updateProfile/api';
import { ContainerParams, DrawerParams, FingerprintResultContainer } from '../../styles';

let FINGERPRINT_REFRESH_TIMEOUT: NodeJS.Timeout;

interface IFingerprint {
  resolution: typeof RESOLUTIONS[number]|typeof ANDROID_RESOLUTIONS[number];
  updateStateProfile: (profileData: Partial<IFullProfile>) => void;
  updateProfileFingerprints: (profilesData: IProfile[]) => void;
}

export const Fingerprint: FC<IFingerprint> = (props) => {
  const [isFingerprintResult, setIsFingerprintResult] = useState<boolean>(false);
  const [isFingerprintRefreshing, setIsFingerprintRefreshing] = useState<boolean>(false);

  const { profileIds } = useProfilesSettingsState();

  const {
    resolution = `${screen.width}x${screen.height}`, updateStateProfile,
    updateProfileFingerprints,
  } = props;

  const { selectedTemplate } = useContext(templatesContext);

  const { t: translation } = useTranslation();

  const refreshFingerprint = async (): Promise<void> => {
    try {
      if (FINGERPRINT_REFRESH_TIMEOUT) {
        clearTimeout(FINGERPRINT_REFRESH_TIMEOUT);
      }

      setIsFingerprintRefreshing(true);

      const propsToFingerprint = { resolution, instanceIds: profileIds, template: selectedTemplate.id };
      const newFingerprints = await updateMultipleFingerprints(propsToFingerprint);

      if (profileIds.length > 1) {
        updateProfileFingerprints(newFingerprints);
      } else {
        const [newFingerprint] = newFingerprints;
        const {
          fonts,
          navigator,
        } = newFingerprint;

        let deviceMemory = navigator.deviceMemory || 2;
        if (deviceMemory > 8) {
          deviceMemory = 8;
        }

        if (deviceMemory < 1) {
          deviceMemory = 1;
        }

        let { webGLMetadata } = newFingerprint;
        const webGLMetadataMode = webGLMetadata.mode && webGLMetadata.mode === 'off' ? webGLMetadata.mode : 'mask';
        webGLMetadata = {
          ...webGLMetadata,
          mode: webGLMetadataMode,
          vendor: webGLMetadata.vendor || 'Google Inc.',
          renderer: webGLMetadata.renderer || '',
        };

        updateStateProfile({
          ...newFingerprint,
          webGLMetadata,
          fonts: {
            families: fonts.families,
          },
          navigator: {
            ...navigator,
            deviceMemory,
          },
        });
      }
    } finally {
      setIsFingerprintRefreshing(false);
      setIsFingerprintResult(true);

      // Set notification timeout
      FINGERPRINT_REFRESH_TIMEOUT = setTimeout(() => {
        setIsFingerprintResult(false);
      }, 2000);
    }
  };

  const renderIcon = (): JSX.Element => {
    if (isFingerprintResult && !isFingerprintRefreshing) {
      return <IconCheckFingerprint padding={0} />;
    }

    return <IconSpin hasSpin={isFingerprintRefreshing} padding={0} />;
  };

  return (
    <ContainerParams>
      <DrawerParams>
        <FingerprintResultContainer
          isFingerprintRefreshing={isFingerprintRefreshing}
          onClick={refreshFingerprint}
          iconColor={isFingerprintResult ? 'var(--00A987-profile-new-settings)' : 'var(--1B1B1F-profile-new-settings)'}
          iconHoveredColor='var(--00A987-profile-new-settings)'
          textColor={isFingerprintResult ? 'var(--00A987-profile-new-settings)' : 'var(--2B2B31-profile-new-settings)'}
          textHoveredColor='var(--00A987-profile-new-settings)'
          iconType='stroke'
        >
          {renderIcon()}
          <span style={{ marginLeft: 7 }}>
            {translation('quickSettings.fingerprint.refreshActionTxt')}
          </span>
        </FingerprintResultContainer>
      </DrawerParams>
    </ContainerParams>
  );
};

import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { calculateSelectableConnectionTypes } from '../../../common/proxy/traffic/utils';
import { IGeolocationProxyFullData, IProxyTraffic } from '../../interfaces';

const TRAFFIC_DATA_BY_TYPE: IProxyTraffic = {
  trafficUsed: 0,
  trafficLimit: 0,
  trafficUsedBytes: 0,
  trafficLimitBytes: 0,
};

const MOBILE_BUNDLE_PRICES = [
  { dataAmount: 2, price: { usd: 1500 }, pricePerGb: { usd: 750, rub: 52500, pkr: 105000, bdt: 45000 } },
  { dataAmount: 5, price: { usd: 3500 }, pricePerGb: { usd: 700, rub: 49000, pkr: 98000, bdt: 42000 }  },
  { dataAmount: 10, price: { usd: 7000 }, pricePerGb: { usd: 700, rub: 49000, pkr: 98000, bdt: 42000 }  },
  { dataAmount: 20, price: { usd: 13000 }, pricePerGb: { usd: 650, rub: 45500, pkr: 91000, bdt: 39000 }  },
  { dataAmount: 50, price: { usd: 30000 }, pricePerGb: { usd: 600, rub: 42000, pkr: 84000, bdt: 36000 }  },
  { dataAmount: 100, price: { usd: 55000 }, pricePerGb: { usd: 550, rub: 38500, pkr: 77000, bdt: 33000 }  },
];

const RESIDENT_BUNDLE_PRICES = [
  { dataAmount: 2, price: { usd: 1500 }, pricePerGb: { usd: 750, rub: 52500, pkr: 105000, bdt: 45000 } },
  { dataAmount: 5, price: { usd: 3000 }, pricePerGb: { usd: 600, rub: 42000, pkr: 84000, bdt: 36000 } },
  { dataAmount: 10, price: { usd: 6000 }, pricePerGb: { usd: 600, rub: 42000, pkr: 84000, bdt: 36000 } },
  { dataAmount: 20, price: { usd: 11000 }, pricePerGb: { usd: 550, rub: 38500, pkr: 77000, bdt: 33000 } },
  { dataAmount: 50, price: { usd: 25000 }, pricePerGb: { usd: 500, rub: 35000, pkr: 70000, bdt: 30000 } },
  { dataAmount: 100, price: { usd: 45000 }, pricePerGb: { usd: 450, rub: 31500, pkr: 63000, bdt: 27000 } },
];

const DATA_CENTER_BUNDLE_PRICES = [
  { dataAmount: 5, price: { usd: 1000 }, pricePerGb: { usd: 200, rub: 14000, pkr: 28000, bdt: 12000 } },
  { dataAmount: 10, price: { usd: 2000 }, pricePerGb: { usd: 200, rub: 14000, pkr: 28000, bdt: 12000 } },
  { dataAmount: 20, price: { usd: 4000 }, pricePerGb: { usd: 200, rub: 14000, pkr: 28000, bdt: 12000 } },
  { dataAmount: 50, price: { usd: 10000 }, pricePerGb: { usd: 200, rub: 14000, pkr: 28000, bdt: 12000 } },
  { dataAmount: 100, price: { usd: 20000 }, pricePerGb: { usd: 200, rub: 14000, pkr: 28000, bdt: 12000 } },
];

export const DEFAULT_AVAILABLE_FOR_PURCHASE = [5, 10, 20, 50, 100] as const;
export const TRAFFIC_DATA_DEFAULT: IGeolocationProxyFullData = <const>{
  prices: {
    resident: 0,
    mobile: 0,
    dataCenter: 0,
  },
  availableForPurchase: DEFAULT_AVAILABLE_FOR_PURCHASE,
  residentTrafficData: TRAFFIC_DATA_BY_TYPE,
  mobileTrafficData: TRAFFIC_DATA_BY_TYPE,
  dataCenterTrafficData: TRAFFIC_DATA_BY_TYPE,
  bundlePrices: {
    mobile: MOBILE_BUNDLE_PRICES,
    resident: RESIDENT_BUNDLE_PRICES,
    dataCenter: DATA_CENTER_BUNDLE_PRICES,
  },
};

export const trafficDataAtom = atom<IGeolocationProxyFullData>(TRAFFIC_DATA_DEFAULT);
const isTrafficDataLoadingAtom = atom<boolean>(true);

const setTrafficData = (trafficData: IGeolocationProxyFullData): void =>
  getDefaultStore().set(trafficDataAtom, trafficData);
export const getTrafficData = (): IGeolocationProxyFullData => getDefaultStore().get(trafficDataAtom);
const setIsTrafficDataLoading = (isLoading: boolean): void =>
  getDefaultStore().set(isTrafficDataLoadingAtom, isLoading);

export const useTrafficData = (): IGeolocationProxyFullData => useAtomValue(trafficDataAtom);
export const useIsTrafficDataLoading = (): boolean => useAtomValue(isTrafficDataLoadingAtom);

export const updateIsTrafficDataLoading = (isLoading: boolean): void => {
  setIsTrafficDataLoading(isLoading);
};

export const updateTrafficData = (trafficData: IGeolocationProxyFullData): void => {
  setTrafficData(trafficData);
  updateIsTrafficDataLoading(false);
};

export const clearTrafficData = (): void => {
  updateIsTrafficDataLoading(true);
  setTrafficData(TRAFFIC_DATA_DEFAULT);
};

import { Icon, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GeolocationProxyForm, GeolocationProxyFormProps } from './geolocation-proxy-form';
import { GeoProxyType } from '../../../../../common/constants/types';
import { ProxyMode } from '../../../../interfaces';
import { getProfileGeoProxyParams } from '../api';
import ProxyChecker from '../components/proxy-checker';
import { LoaderDivProxy } from '../domElements';

type GeolocationProxyFormContainerProps = Pick<GeolocationProxyFormProps, 'profileId' |
  'profileInfo' |
  'geolocationProxy' |
  'isCreateProfile' |
  'isSaveButtonPressed' |
  'saveProfile' |
  'updateProfileInfo' |
  'setIsGeolocationScreen'
> & {
  setGeolocationProxy: (params: any) => void;
}

const GeolocationProxyFormContainer: React.FC<GeolocationProxyFormContainerProps> = (props) => {
  const {
    profileId,
    profileInfo,
    geolocationProxy,
    isCreateProfile,
    isSaveButtonPressed,
    saveProfile,
    updateProfileInfo,
    setGeolocationProxy,
    setIsGeolocationScreen,
  } = props;

  const [proxyLoading, setProxyLoading] = useState<boolean>(false);
  const [currentDisplay, setCurrentDisplay] = useState<'result'|'form'>('result');
  const [initialCheck, setInitialCheck] = useState<boolean>(false);
  const [connection, setConnection] = useState<GeoProxyType | null>(null);
  const [currentProxyCountry, setCurrentProxyCountry] = useState<string>('');

  const { t: translation } = useTranslation();

  useEffect(() => {
    const profileProxy = profileInfo.proxy || {};
    setGeolocationProxy(profileProxy);
    if (profileProxy.country) {

      return;
    }

    const query: any = {
      usernameAuth: profileInfo.proxy.username || '',
      passwordAuth: profileInfo.proxy.password || '',
      profileId: profileId || '',
    };

    const proxyMode: ProxyMode = profileInfo.proxy?.mode;
    const isCustomSelected = proxyMode !== 'geolocation';
    if (!(query.usernameAuth && query.passwordAuth) || isCustomSelected) {
      setCurrentDisplay('form');

      return;
    }

    setProxyLoading(true);
    const queryString =
      Object.keys(query)
        .reduce((res: string, key: string) => res + key + '=' + encodeURIComponent(query[key]) + '&', '');

    getProfileGeoProxyParams(queryString)
      .then((responseObject: any) => {
        if (responseObject.country) {
          setCurrentDisplay('result');
          responseObject.status = 'success';
          responseObject.origin = responseObject.ip;
          changeConnectionType(responseObject.connectionType);
        } else {
          setCurrentDisplay('form');
        }

        setGeolocationProxy(responseObject);
        setProxyLoading(false);
      })
      .catch(() => {
        message.error(translation('notifications.error.noProxy'));
        setCurrentDisplay('form');
      });
  }, []);

  useEffect(() => {
    setIsGeolocationScreen && setIsGeolocationScreen(true);

    return () => {
      setIsGeolocationScreen && setIsGeolocationScreen(false);
    };
  }, []);

  const changeConnectionType = (connectionType: GeoProxyType | null): void => {
    setCurrentProxyCountry('');
    setConnection(connectionType);
  };

  const setCurrentDisplayFunction = (display: 'form'|'result'): void => {
    if (display === 'form') {
      updateProfileInfo({
        proxy: {
          port: 80,
          host: '',
          username: '',
          password: '',
          mode: 'geolocation',
        },
      });
    }

    setCurrentDisplay(display);
  };

  const getProxyInfo = () => {
    if (!geolocationProxy.country) {
      return null;
    }

    return (
      <ProxyChecker
        profileProxy={profileInfo.proxy}
        proxyChecked={geolocationProxy}
        setCurrentDisplay={setCurrentDisplayFunction}
        currentDisplay={currentDisplay}
        setProxyChecked={setGeolocationProxy}
        isLoading={false}
        geolocationShowOption={true}
        initialCheck={initialCheck}
      />
    );
  };

  const getProxySelectorForm = () => {
    if (currentDisplay === 'result') {
      return null;
    }

    return (
      <GeolocationProxyForm
        profileInfo={profileInfo}
        profileId={profileId}
        updateProfileInfo={updateProfileInfo}
        setCurrentDisplay={setCurrentDisplayFunction}
        setProxyCurrent={setGeolocationProxy}
        setInitialCheck={setInitialCheck}
        setProxyLoading={setProxyLoading}
        isSaveButtonPressed={isSaveButtonPressed}
        geolocationProxy={geolocationProxy}
        setIsGeolocationScreen={setIsGeolocationScreen}
        saveProfile={saveProfile}
        isCreateProfile={isCreateProfile}
        connection={connection}
        setConnection={changeConnectionType}
        currentDisplay={currentDisplay}
        currentProxyCountry={currentProxyCountry}
        setCurrentProxyCountry={setCurrentProxyCountry}
      />
    );
  };

  const isLoading = proxyLoading && currentDisplay === 'result';
  if (isLoading) {
    return (
      <LoaderDivProxy>
        <Icon type='loading' />
      </LoaderDivProxy>
    );
  }

  return (
    <>
      {getProxyInfo()}
      {getProxySelectorForm()}
    </>
  );
};

export default GeolocationProxyFormContainer;

import { EMPTY_LIMITS, EMPTY_PROXY_DATA, Limits, PricingChosenLimits, PricingChosenProxyData, ProxyData, ProxyDataTiers, ProxyDataTypes } from '../../../../../interfaces/workspaces/limits';
import { DEFAULT_AVAILABLE_FOR_PURCHASE } from '../../../../../state/proxy/traffic-data.atom';
import { DISCOUNT_IDS } from '../../../constants';
import { getBaseLimitsFromPlan, getPlanByPlanId, isLimitChangeBelowMinimum, isLimitsBigger, isProxyDataBigger, isProxyDataChangeBelowMinimum, mergeLimits, mergeProxyData, substractLimits, substractProxyData } from '../../../utils';
import {
  DeriveShouldShowProxyDataLineParams,
  DeriveAddonProxyDataForProxyLineParams,
  RetrieveChosenLimitsByPlanAndPeriodParams,
  DeriveChosenProxyDataByPlanAndPeriodParams,
  RetrieveIsAddonsOnlyPurchaseParams,
  RetrieveIsAtLeastOneLimitChosenByUserParams,
  RetrieveIsAtLeastOneProxyDataChosenByUserParams,
  RetrieveIsSamePlanAndPeriod,
  CalculateChosenProxyDataByPlanAndPeriodParams,
  DeriveShouldShowLimitsLineParams,
  CalculateChosenLimitsByPLanAndPeriod,
  DeriveShouldShowProductLineParams,
  PaymentModalStateParams,
} from './types';

export const deriveShouldShowProxyDataLine = (params: DeriveShouldShowProxyDataLineParams): boolean => {
  const {
    hasActiveRealPayment,
    selectedDiscountId,
    workspaceDiscountId,
    workspacePaidProxyData,
  } = params;

  const selectedPlanChosenProxyData = deriveChosenProxyDataByPlanAndPeriod(params);
  // clicked proxy data in plan card
  if (isProxyDataBigger(selectedPlanChosenProxyData, workspacePaidProxyData)) {
    return true;
  }

  // doesn't have paid proxy data
  if (!isProxyDataBigger(workspacePaidProxyData, EMPTY_PROXY_DATA)) {
    return false;
  }

  // upgrage month - annual
  if (hasActiveRealPayment && selectedDiscountId === DISCOUNT_IDS.Annual && workspaceDiscountId === DISCOUNT_IDS.Monthly) {
    return true;
  }

  // if limits clicked - it is not plan renew
  if (retrieveIsAtLeastOneLimitChosenByUser(params)) {
    return false;
  }

  const isSamePlanAndPeriod = retrieveIsSamePlanAndPeriod(params);
  // if plan renew
  if (hasActiveRealPayment && isSamePlanAndPeriod) {
    return true;
  }

  return false;
};

export const deriveShouldShowLimitsLine = (params: DeriveShouldShowLimitsLineParams): boolean => {
  const { hasActiveRealPayment, selectedPlanId, plansList, workspaceLimits } = params;
  // clicked limits in plan card
  if (retrieveIsAtLeastOneLimitChosenByUser(params)) {
    return true;
  }

  const planObj = plansList.find(planObj => planObj.id === selectedPlanId) || null;
  const basePlanLimits = getBaseLimitsFromPlan(planObj);
  const workspacePaidLimits = substractLimits(workspaceLimits, basePlanLimits);
  // doesn't have paid limits
  if (!isLimitsBigger(workspacePaidLimits, EMPTY_LIMITS)) {
    return false;
  }

  // if proxy data clicked - it is not plan renew
  if (retrieveIsAtLeastOneProxyDataChosenByUser(params)) {
    return false;
  }

  // if plan renew
  if (hasActiveRealPayment && retrieveIsSamePlanAndPeriod(params)) {
    return true;
  }

  return false;
};

export const deriveShouldShowProductLine = (params: DeriveShouldShowProductLineParams): boolean => {
  return !retrieveIsAddonsOnlyPurchase(params);
};

export const deriveShouldDiscountLineBeActive = (params: PaymentModalStateParams): boolean => {
  const {
    hasActiveRealPayment,
    selectedDiscountId,
    promoDiscount,
  } = params;

  const { active: promoDiscountActive = false } = promoDiscount || {};
  if (selectedDiscountId === DISCOUNT_IDS.Monthly && promoDiscountActive) {
    return true;
  }

  if (selectedDiscountId === DISCOUNT_IDS.Monthly) {
    return false;
  }

  if (!(hasActiveRealPayment && retrieveIsSamePlanAndPeriod(params))) {
    return true;
  }

  if (retrieveIsAtLeastOneLimitChosenByUser(params) || retrieveIsAtLeastOneProxyDataChosenByUser(params)) {
    return false;
  }

  return true;
};

export const deriveAddonProxyDataForProxyLine = (params: DeriveAddonProxyDataForProxyLineParams): ProxyData => {
  const {
    hasActiveRealPayment,
    workspacePaidProxyData,
    selectedDiscountId,
    workspaceDiscountId,
  } = params;

  const selectedPlanChosenProxyData = deriveChosenProxyDataByPlanAndPeriod(params);
  const workspaceAddonProxyData = substractProxyData(selectedPlanChosenProxyData, workspacePaidProxyData);

  if (retrieveIsAtLeastOneProxyDataChosenByUser(params)) {
    return workspaceAddonProxyData;
  }

  if (!hasActiveRealPayment) {
    return workspaceAddonProxyData;
  }

  // if month to annual upgrade
  if (hasActiveRealPayment && selectedDiscountId === DISCOUNT_IDS.Annual && workspaceDiscountId === DISCOUNT_IDS.Monthly) {
    return workspacePaidProxyData;
  }

  if (!retrieveIsSamePlanAndPeriod(params)) {
    return workspaceAddonProxyData;
  }

  const isAtLeastOneLimitChosenByUser = retrieveIsAtLeastOneLimitChosenByUser(params);
  const isAtLeastOneProxyDataChosenByUser = retrieveIsAtLeastOneProxyDataChosenByUser(params);
  if (isAtLeastOneLimitChosenByUser || isAtLeastOneProxyDataChosenByUser) {
    workspaceAddonProxyData;
  };

  return workspacePaidProxyData;
};

export const retrieveIsSamePlanAndPeriod = (params: RetrieveIsSamePlanAndPeriod): boolean => {
  const {
    workspacePlanId,
    selectedPlanId,
    workspaceDiscountId,
    selectedDiscountId,
  } = params;

  return workspacePlanId === selectedPlanId && workspaceDiscountId === selectedDiscountId;
};

export const retrieveChosenLimitsByPlanAndPeriod = (params: RetrieveChosenLimitsByPlanAndPeriodParams): Limits => {
  const {
    chosenLimitsList,
    selectedPlanId,
    selectedDiscountId,
    plansList,
  } = params;

  const { limits: chosenPlanLimits } = chosenLimitsList.find(limits => {
    const { planId: chosenPlanId, discountId } = limits || {};

    return selectedPlanId === chosenPlanId && selectedDiscountId === discountId;
  }) || {};

  const planObj = getPlanByPlanId(selectedPlanId, plansList);
  if (!chosenPlanLimits) {
    const { limits = EMPTY_LIMITS } = planObj || {};

    return limits;
  }

  return chosenPlanLimits;
};

export const deriveChosenProxyDataByPlanAndPeriod = (params: DeriveChosenProxyDataByPlanAndPeriodParams): ProxyData => {
  const {
    chosenProxyDataList,
    selectedDiscountId,
    selectedPlanId,
  } = params;

  const { proxyData: chosenPlanProxyData } = chosenProxyDataList.find(proxyData => {
    const { planId: chosenPlanId, discountId } = proxyData || {};

    return selectedPlanId === chosenPlanId && selectedDiscountId === discountId;
  }) || {};

  if (!chosenPlanProxyData) {

    return EMPTY_PROXY_DATA;
  }

  return chosenPlanProxyData;
};

export const calculateProxyDataByPlanAndPeriod = (params: CalculateChosenProxyDataByPlanAndPeriodParams): PricingChosenProxyData[] | null => {
  const {
    selectedPlanId,
    selectedDiscountId,
    deltaProxyData,
    chosenProxyDataList,
    workspacePaidProxyData,
  } = params;

  const { tiers = {} } = chosenProxyDataList.find(proxyData => {
    const { planId: chosenPlanId, discountId } = proxyData || {};

    return selectedPlanId === chosenPlanId && selectedDiscountId === discountId;
  }) || {};

  const newTiers: ProxyDataTiers = {};
  const deltaTier = Object.keys(deltaProxyData).reduce<ProxyDataTiers>((acc, key) => {
    const typedKey = key as ProxyDataTypes;
    const delta: number = deltaProxyData[typedKey] || 0;
    const tier = tiers[typedKey] || 0;
    if (delta > 0) {
      const nextTier =
        DEFAULT_AVAILABLE_FOR_PURCHASE.find(defaultTier => defaultTier > tier)
        || DEFAULT_AVAILABLE_FOR_PURCHASE[DEFAULT_AVAILABLE_FOR_PURCHASE.length - 1];

      acc[typedKey] = nextTier;
      newTiers[typedKey] = nextTier;

      return acc;
    }

    if (delta < 0) {
      const nextTier = DEFAULT_AVAILABLE_FOR_PURCHASE.findLast(defaultTier => defaultTier < tier) || 0;
      acc[typedKey] = nextTier;
      newTiers[typedKey] = nextTier || 0;

      return acc;
    }


    return acc;
  }, {});

  const newProxyData = mergeProxyData(workspacePaidProxyData, deltaTier);
  const isBelowMinimum = isProxyDataChangeBelowMinimum(workspacePaidProxyData, newProxyData);
  if (isBelowMinimum) {
    return null;
  }

  return chosenProxyDataList.map(chosen => {
    const { planId: chosenProxyDataPlanId, discountId: chosenProxyDataDiscountId } = chosen;
    if (selectedPlanId === chosenProxyDataPlanId && selectedDiscountId === chosenProxyDataDiscountId) {
      return {
        ...chosen,
        proxyData: newProxyData,
        tiers: newTiers,
      };
    }

    return chosen;
  });
};

export const calculateChosenLimitsByPlanAndPeriod = (params: CalculateChosenLimitsByPLanAndPeriod): PricingChosenLimits[] | null => {
  const {
    workspacePlanId,
    workspaceDiscountId,
    selectedPlanId,
    selectedDiscountId,
    workspaceLimits,
    chosenLimitsList,
    plansList,
    deltaLimits,
  } = params;

  const planObj = getPlanByPlanId(selectedPlanId, plansList);
  const { limits: planLimits = EMPTY_LIMITS } = planObj || {};
  const { limits: chosenPlanLimits = planLimits } = chosenLimitsList.find(limits => {
    const { planId: chosenPlanId, discountId } = limits || {};

    return selectedPlanId === chosenPlanId && selectedDiscountId === discountId;
  }) || {};

  const isSamePlanAndDiscount = selectedPlanId === workspacePlanId && selectedDiscountId === workspaceDiscountId;
  const limitsToCheck = isSamePlanAndDiscount ? workspaceLimits : planLimits;
  const newLimits = mergeLimits(chosenPlanLimits, deltaLimits);
  const isBelowMinimum = isLimitChangeBelowMinimum(limitsToCheck, newLimits);
  if (isBelowMinimum) {
    return null;
  }

  return chosenLimitsList.map(chosen => {
    const { planId: chosenLimitsPLanId, discountId: chosenLimitsDiscountId } = chosen;
    if (selectedPlanId === chosenLimitsPLanId && selectedDiscountId === chosenLimitsDiscountId) {
      return {
        ...chosen,
        limits: newLimits,
      };
    }

    return chosen;
  });

};

export const retrieveIsAtLeastOneLimitChosenByUser = (params: RetrieveIsAtLeastOneLimitChosenByUserParams): boolean => {
  const {
    selectedPlanId,
    plansList,
    workspaceLimits,
  } = params;


  const isSamePlanAndPeriod = retrieveIsSamePlanAndPeriod(params);
  const planObj = plansList.find(planObj => planObj.id === selectedPlanId) || null;
  const basePlanLimits = getBaseLimitsFromPlan(planObj);
  const baseLimits = isSamePlanAndPeriod ? workspaceLimits : basePlanLimits;
  const selectedPlanLimits = retrieveChosenLimitsByPlanAndPeriod(params);
  const selectedPlanChosenLimits = substractLimits(selectedPlanLimits, baseLimits);

  return Object.values(selectedPlanChosenLimits).some((limit: number) => limit > 0);
};

export const retrieveIsAtLeastOneProxyDataChosenByUser = (params: RetrieveIsAtLeastOneProxyDataChosenByUserParams): boolean => {
  const { workspacePaidProxyData } = params;
  const selectedPlanChosenProxyData = deriveChosenProxyDataByPlanAndPeriod(params);
  const workspaceAddonProxyData = substractProxyData(selectedPlanChosenProxyData, workspacePaidProxyData);

  return Object.values(workspaceAddonProxyData).some((traffic: number) => traffic > 0);
};

export const retrieveIsAddonsOnlyPurchase = (params: RetrieveIsAddonsOnlyPurchaseParams): boolean => {
  const isSamePlanAndPeriod = retrieveIsSamePlanAndPeriod(params);
  const isAtLeastOneLimitChosenByUser = retrieveIsAtLeastOneLimitChosenByUser(params);
  const isAtLeastOneProxyDataChosenByUser = retrieveIsAtLeastOneProxyDataChosenByUser(params);

  return isSamePlanAndPeriod && (isAtLeastOneLimitChosenByUser || isAtLeastOneProxyDataChosenByUser);
};

import styled from '@emotion/styled';

import { GologinPopoverItemRow } from '../../gologin-popover/gologin-popover-item-row';
import { IconWrapperWithDescription } from '../icons/wrapper';

export const FOLDERS_POPOVER_Z_INDEX = 2000;

export const SearchFolderInput = styled.input`
  flex: 1;
  min-width: 0;

  border: none;
  outline: none;

  padding: 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--222222-header-folders-menu);

  background: transparent;
  &::placeholder {
    color: var(--CDCDCD-header-folders-menu);
  }
`;

export const ContainerAddFolderSearch = styled(IconWrapperWithDescription)`
  color: var(--767676-header-folders-menu);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  :hover {
    span {
      transition: background-color 0.05s ease-in-out, color 0.05s ease-in-out, stroke 0.05s ease-in-out;
      color: var(--222222-header-folders-menu);
    }
  }
`;

export const FolderRow = styled(GologinPopoverItemRow)`
  justify-content: space-between;
`;

export const ContainerIcon = styled.div`
  padding: 1px 0;
`;

export const FolderNameText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FoundText = styled.span`
  color: var(--767676-header-folders-menu);

  b {
    font-weight: 400;
    color: var(--1B1B1F-header-folders-menu);
  }
`;

export const GrayTextEllipsis = styled.span`
  color: var(--767676-header-folders-menu);
`;

export const FolderContainer = styled.div`
  flex-grow: 1;

  * + * {
    margin-top: 1px;
  }
`;

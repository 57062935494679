import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { setPaymentModalPaymentCountry } from './pricing/payment-modal.atom';
import { E_PAYMENT_COUNTRY } from '../features/pricing/interfaces';
import { DEFAULT_LOCAL_USER_DEVICE, ILocalUserDevice } from '../interfaces';
import { http } from '../services';
import { TIMEZONE_CHECK_URL_RENDERER } from '../services/http/config';
import { getUserScreenInfo } from '../utils/user-hardware.utils';

const localUserDeviceAtom = atom<ILocalUserDevice>(DEFAULT_LOCAL_USER_DEVICE);

export const getLocalUserDevice = (): ILocalUserDevice => getDefaultStore().get(localUserDeviceAtom);
const setLocalUserDevice = (newLocalUserDevice: ILocalUserDevice): void => {
  const localUserDevice = getLocalUserDevice();
  const isSame = localUserDevice.languages === newLocalUserDevice.languages &&
    localUserDevice.country === newLocalUserDevice.country &&
    localUserDevice.timezone === newLocalUserDevice.timezone;

  // do not re-render anything (especially profiles settings)
  // depending on it if nothing changed
  if (isSame) {
    return;
  }

  getDefaultStore().set(localUserDeviceAtom, newLocalUserDevice);
};

export const useLocalUserDevice = (): ILocalUserDevice => useAtomValue(localUserDeviceAtom);

export const refreshLocalUserDevice = async (): Promise<void> => {
  const { body } = await http(TIMEZONE_CHECK_URL_RENDERER);

  const localUserDevice = getLocalUserDevice();
  let { screen } = localUserDevice;
  if (!screen.width) {
    screen = await getUserScreenInfo();
  }

  setLocalUserDevice({ ...body, screen });

  const { country = E_PAYMENT_COUNTRY.ANY } = body;
  setPaymentModalPaymentCountry(country);
};

import { atom, getDefaultStore, useAtomValue } from 'jotai';

type OrbitaFeaturesOpts = {
  runProfilesInSyncMinOrbitaVer: number;
}

const ORBITA_FEATURES_OPTS: OrbitaFeaturesOpts = {
  runProfilesInSyncMinOrbitaVer: 134,
};

const orbitaFeaturesOptsAtom = atom<OrbitaFeaturesOpts>(ORBITA_FEATURES_OPTS);

export const useOrbitaFeaturesOpts = (): OrbitaFeaturesOpts => useAtomValue(orbitaFeaturesOptsAtom);
export const getOrbitaFeaturesOpts = (): OrbitaFeaturesOpts => getDefaultStore().get(orbitaFeaturesOptsAtom);

export const setOrbitaFeaturesOpts = (features: OrbitaFeaturesOpts): void => {
  getDefaultStore().set(orbitaFeaturesOptsAtom, features);
};

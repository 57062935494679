import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { GEO_PROXY_TYPE_HINTS, GeoProxyTypeHint } from './constants';
import {
  AddButtonPopoverRow,
  AddButtonPopoverRowContent,
  AddButtonPopoverRowRightControls,
  AddButtonPopoverRowTitle,
  PROXY_GROUP_ADD_BUTTON_POPOVER_WIDTH,
} from './styles';
import { ProxyGroupAddButtonProps } from '..';
import { GROUPED_PROXY_MANAGER_ANALYTICS_EVENTS } from '../../../../../../../common/constants/analytics';
import { GeoProxyType } from '../../../../../../../common/constants/types';
import { calculateRemainingTrafficLimit, stringifyTrafficAmount } from '../../../../../../../common/proxy/traffic/utils';
import { setGeoProxyLastSelectedType } from '../../../../../../state/proxy/geoproxy-form-data.atom';
import { PROXY_CHECK_TOOLTIP_LOCATIONS } from '../../../../../../state/proxy/proxy-check/constants';
import { createGeoProxy } from '../../../../../../state/proxy/proxy-operations/create-geoproxy.operations';
import { useTrafficData } from '../../../../../../state/proxy/traffic-data.atom';
import { openQuickPricing } from '../../../../../../state/quick-pricing.atom';
import { itemRowIconProps } from '../../../../../../ui/gologin-header/account-menu/menu-items';
import { IconCheckSmall } from '../../../../../../ui/gologin-header/icons/icon-check-small';
import { IconHome } from '../../../../../../ui/gologin-header/icons/icon-home';
import { IconProxyDataCenterSmall } from '../../../../../../ui/gologin-header/icons/icon-proxy-datacenter-small';
import { IconProxyMobile } from '../../../../../../ui/gologin-header/icons/icon-proxy-mobile';
import GologinPopover from '../../../../../../ui/gologin-popover';
import { GologinPopoverHint } from '../../../../../../ui/gologin-popover/gologin-popover-hint';
import { sendActionAnalytics } from '../../../../../common/api';
import { IMenuItem } from '../../../../../quickProfiles/profile-dropdown-menu/interfaces';
import { TRAFFIC_LIMIT } from '../../../../constants/settings';
import PurchaseTrafficButton from '../../purchase-traffic-button';
import { PurchaseTrafficButtonText } from '../../purchase-traffic-button/styles';
import { generateProxyAnalyticsData } from '../../../../proxy-helpers';

export const POPOVER_DEFAULT_PADDING: React.CSSProperties['padding'] = '6px 12px';

type GeoProxyTypeItem = Omit<IMenuItem, 'name'> & {
  name: GeoProxyType;
  hint: {
    translationKey: string;
  };
}

export type ProxyGroupAddButtonPopoverPropsBase = Pick<ProxyGroupAddButtonProps, 'groupId' | 'country' | 'availableTypes'> & {
  geoProxyLastSelectedType: GeoProxyType;
};

type ProxyGroupAddButtonPopoverProps = ProxyGroupAddButtonPopoverPropsBase & {
  anchorElement: HTMLElement | null;
  handleClose: () => void;
}

const ProxyGroupAddButtonPopover: React.FC<ProxyGroupAddButtonPopoverProps> = (props) => {
  const { groupId, country, geoProxyLastSelectedType, anchorElement, availableTypes, handleClose } = props;

  const trafficData = useTrafficData();

  const [activeGeoProxyHint, setActiveGeoProxyHint] = useState<GeoProxyTypeHint>(GEO_PROXY_TYPE_HINTS[geoProxyLastSelectedType]);

  useEffect(() => {
    if (anchorElement) {
      sendActionAnalytics(GROUPED_PROXY_MANAGER_ANALYTICS_EVENTS.visitedSelectGeoProxyType, { proxyCountry: country });
    }
  }, [anchorElement]);

  const handleItemClick = (connectionType: GeoProxyType): void => {
    setGeoProxyLastSelectedType(connectionType);
    createGeoProxy({
      groupId,
      profileId: '',
      country,
      selectedConnectionType: connectionType,
      availableConnectionTypes: availableTypes,
      trafficData,
      checkTooltipView: PROXY_CHECK_TOOLTIP_LOCATIONS.proxyGroupedListItem,
    }).then((geoProxyFormSubmitResult) => {
      if (!geoProxyFormSubmitResult || typeof geoProxyFormSubmitResult === 'string') {
        return;
      }

      const proxyAnalyticsData = generateProxyAnalyticsData(geoProxyFormSubmitResult);
      sendActionAnalytics(GROUPED_PROXY_MANAGER_ANALYTICS_EVENTS.createdLocationViaBuiltInProxyClickedOnServerTypeInExpandedGroup, proxyAnalyticsData);
    });

    handleClose();
  };

  const geoProxyTypeItems: GeoProxyTypeItem[] = [
    {
      name: GeoProxyType.Resident,
      translationKey: `proxyTypes.${GeoProxyType.Resident}`,
      icon: <IconHome {...itemRowIconProps} />,
      hint: { translationKey: GEO_PROXY_TYPE_HINTS[GeoProxyType.Resident] },
      onAction: () => handleItemClick(GeoProxyType.Resident),
    },
    {
      name: GeoProxyType.Mobile,
      translationKey: `proxyTypes.${GeoProxyType.Mobile}`,
      icon: <IconProxyMobile {...itemRowIconProps} />,
      hint: { translationKey: GEO_PROXY_TYPE_HINTS[GeoProxyType.Mobile] },
      onAction: () => handleItemClick(GeoProxyType.Mobile),
    },
    {
      name: GeoProxyType.DataCenter,
      translationKey: `proxyTypes.${GeoProxyType.DataCenter}`,
      icon: <IconProxyDataCenterSmall {...itemRowIconProps} />,
      hint: { translationKey: GEO_PROXY_TYPE_HINTS[GeoProxyType.DataCenter] },
      onAction: () => handleItemClick(GeoProxyType.DataCenter),
    },
  ];

  const onActionMenuItem = (onAction: () => void, isDisabled?: boolean): void => {
    if (isDisabled) {
      return;
    }

    onAction();
  };

  const renderMenuItemRightControls = (geoProxyType: GeoProxyType): JSX.Element => {
    const remainingTrafficLimit = calculateRemainingTrafficLimit(trafficData, geoProxyType);
    if (remainingTrafficLimit <= TRAFFIC_LIMIT) {
      return <PurchaseTrafficButton />;
    }

    return (
      <PurchaseTrafficButtonText>
        <Trans
          i18nKey='proxyManager.trafficRemainingLimit'
          values={{ limit: stringifyTrafficAmount(remainingTrafficLimit) }}
        />
      </PurchaseTrafficButtonText>
    );
  };

  const renderMenuItem = (menuItem: GeoProxyTypeItem): JSX.Element => {
    const { name, isDisabled, onAction, translationKey, icon } = menuItem;

    const handleMenuItemClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
      event.stopPropagation();
      const remainingTrafficLimit = calculateRemainingTrafficLimit(trafficData, name);
      if (remainingTrafficLimit > TRAFFIC_LIMIT) {
        return onActionMenuItem(onAction, isDisabled);
      }

      openQuickPricing('popup-buy-locked-proxy');
    };

    return (
      <AddButtonPopoverRow
        key={name}
        style={{ padding: POPOVER_DEFAULT_PADDING }}
        onClick={handleMenuItemClick}
        onMouseOver={(): void => {
          setActiveGeoProxyHint(GEO_PROXY_TYPE_HINTS[name]);
        }}
      >
        {icon}
        <AddButtonPopoverRowContent>
          <AddButtonPopoverRowTitle>
            <Trans i18nKey={translationKey} />
          </AddButtonPopoverRowTitle>
          <AddButtonPopoverRowRightControls>
            {name === geoProxyLastSelectedType ? (
              <IconCheckSmall
                padding={0}
                iconColor='var(--2B2B31-header)'
              />
            ) : null}
            {renderMenuItemRightControls(name)}
          </AddButtonPopoverRowRightControls>
        </AddButtonPopoverRowContent>
      </AddButtonPopoverRow>
    );
  };
  
  return (
    <GologinPopover
      anchorEl={anchorElement}
      onClose={(event): void => {
        event.stopPropagation();
        handleClose();
      }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      extraTranslate={{ right: 15, bottom: 3 }}
      width={PROXY_GROUP_ADD_BUTTON_POPOVER_WIDTH}
      zIndex={1060}
    >
      {geoProxyTypeItems.map(renderMenuItem)}
      {activeGeoProxyHint ? (
        <GologinPopoverHint
          style={{ padding: POPOVER_DEFAULT_PADDING }}
          onClick={(event): void => event.stopPropagation()}
        >
          <Trans i18nKey={activeGeoProxyHint} />
        </GologinPopoverHint>
      ) : null}
    </GologinPopover>
  );
};

export default ProxyGroupAddButtonPopover;

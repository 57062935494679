import styled from '@emotion/styled/macro';

import { GologinPopoverItemRow } from '../../../../../../ui/gologin-popover/gologin-popover-item-row';

// weird sizes for appropriate hover/click area
export const IconMeatballsWrapper = styled.div`
  width: 32px;
  height: 24px;
  padding-right: 8px;
  margin-right: -8px;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  cursor: pointer;
`;

export const CurrentProxySortingIconWrapper = styled.div``;

export const ProxySectionContextMenuPopoverItemRow = styled(GologinPopoverItemRow)`
  padding: 6px 12px;

  & > span {
    margin: 0 8px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  ${CurrentProxySortingIconWrapper} {
    flex-grow: 1;
    text-align: end;
  }
`;

import React from 'react';
import { Trans } from 'react-i18next';

import { CurrentProxySortingIconWrapper, ProxySectionContextMenuPopoverItemRow } from './styles';
import { capitalizeFirstCharacter } from '../../../../../../../common/utils';
import {
  PROXY_GROUP_SORTER_FIELD_LIST,
  PROXY_GROUP_SORTER_FIELDS,
  toggleProxyGroupsOrder,
  useProxyGroupsSorter,
} from '../../../../../../state/proxy/proxy-groups/proxy-groups-sorting.atom';
import {
  toggleProxyGroupModesVisibility,
  useVisibleProxyGroupModes,
} from '../../../../../../state/proxy/proxy-groups/proxy-visible-group-modes.atom';
import { PROXY_GOLOGIN_GROUP_MODES_LIST, PROXY_GROUP_MODES, ProxyGroupMode } from '../../../../../../state/proxy/proxy-groups/types';
import { openProxyManager, useProxyManagerState } from '../../../../../../state/proxy/proxy-manager-modal-status.atom';
import { pasteProxies } from '../../../../../../state/proxy/proxy-operations/paste-proxies-operations';
import { selectAllProxies } from '../../../../../../state/proxy/selected-proxies.atom';
import { DivAnchor } from '../../../../../../types';
import { IconCheck, IconEyeOpen, IconPaste } from '../../../../../../ui/gologin-header/icons';
import { IconCheckboxChecked } from '../../../../../../ui/gologin-header/icons/icon-checkbox-checked';
import { IconEyeCrossed } from '../../../../../../ui/gologin-header/icons/icon-eye-crossed';
import IconProxyPlus from '../../../../../../ui/gologin-header/icons/icon-proxy-plus';
import { IconSortSmall } from '../../../../../../ui/gologin-header/icons/icon-sort-small';
import { IIconWrapper } from '../../../../../../ui/gologin-header/icons/wrapper';
import GologinPopover from '../../../../../../ui/gologin-popover';
import { IMenuDivider, IMenuItem } from '../../../../../quickProfiles/profile-dropdown-menu/interfaces';
import { Divider, DividerContainer, itemRowIconProps } from '../../../../../quickProfiles/profile-dropdown-menu/styles';

type ProxySectionMenuItem = IMenuItem & {
  rightIcon?: JSX.Element;
}

type ProxySectionContextMenuPopoverProps = {
  anchorElement: DivAnchor;
  handleClose: () => void;
}

const ProxySectionContextMenuPopover: React.FC<ProxySectionContextMenuPopoverProps> = (props) => {
  const { anchorElement, handleClose } = props;

  const { containerElement, currentProfileId, proxySelectorLocation: proxySelectorLocationInState } = useProxyManagerState();
  const visibleProxyGroupModes = useVisibleProxyGroupModes();
  const { field: currentSorterField } = useProxyGroupsSorter();

  let proxySelectorLocation = proxySelectorLocationInState;
  if (!proxySelectorLocation) {
    proxySelectorLocation = 'no-location';
  }

  const profileId = currentProfileId || '';

  const handleAddProxy = (): void => {
    openProxyManager({
      containerElement,
      modalView: 'proxy-add',
      currentProxy: null,
      modalEditingProxyId: null,
      currentProfileId: profileId,
      proxySelectorLocation,
    });
  };

  const handlePasteProxy = async (): Promise<void> => {
    await pasteProxies({
      localProxySelectorLocation: proxySelectorLocation,
      isInDrawer: false,
    });

    handleClose();
  };

  const handleSelectProxies = (): void => {
    selectAllProxies();
  };

  const getAreProxyGroupModesVisible = (proxyGroupModes: ProxyGroupMode[]): boolean =>
    proxyGroupModes.every(proxyGroupMode => visibleProxyGroupModes.includes(proxyGroupMode));

  const getGologinProxiesVisibilityKey = (secondKey: 'GologinProxies' | 'UserProxies', toggledProxyGroupModes: ProxyGroupMode[]): string => {
    const keyWord = getAreProxyGroupModesVisible(toggledProxyGroupModes) ? 'hide' : 'show';

    return `proxyManager.groupsContextMenu.${keyWord}${secondKey}`;
  };

  const sortActions: ProxySectionMenuItem[] = PROXY_GROUP_SORTER_FIELD_LIST.map((sortField) => {
    const capitalizedName = capitalizeFirstCharacter(sortField);

    const sortAction: ProxySectionMenuItem = {
      name: `sortBy${capitalizedName}`,
      translationKey: `proxyManager.groupsContextMenu.sortBy${capitalizedName}`,
      icon: <IconSortSmall {...itemRowIconProps} />,
      onAction: () => toggleProxyGroupsOrder(PROXY_GROUP_SORTER_FIELDS[sortField]),
    };

    const isSortingCurrent = currentSorterField === PROXY_GROUP_SORTER_FIELDS[sortField];
    if (isSortingCurrent) {
      const rightIconProps: IIconWrapper = {
        ...itemRowIconProps,
        iconColor: 'var(--222222)',
      };

      sortAction.rightIcon = (
        <CurrentProxySortingIconWrapper>
          <IconCheck {...rightIconProps} />
        </CurrentProxySortingIconWrapper>
      );
    }

    return sortAction;
  });

  const actions: (ProxySectionMenuItem | IMenuDivider)[] = [
    {
      name: 'addProxy',
      translationKey: 'proxyManager.addNewProxy',
      icon: <IconProxyPlus {...itemRowIconProps} />,
      onAction: handleAddProxy,
    },
    {
      name: 'pasteProxy',
      translationKey: 'proxies.pasteProxy',
      icon: <IconPaste {...itemRowIconProps} />,
      onAction: () => handlePasteProxy(),
    },
    {
      name: 'pasteProxy',
      translationKey: 'proxyManager.selectProxies',
      icon: <IconCheckboxChecked {...itemRowIconProps} />,
      onAction: handleSelectProxies,
    },
    {
      divider: true,
    },
    {
      name: 'gologinProxiesVisibility',
      translationKey: getGologinProxiesVisibilityKey('GologinProxies', PROXY_GOLOGIN_GROUP_MODES_LIST),
      icon: getAreProxyGroupModesVisible(PROXY_GOLOGIN_GROUP_MODES_LIST) ?
        <IconEyeCrossed {...itemRowIconProps} /> : <IconEyeOpen {...itemRowIconProps} />,
      onAction: () => toggleProxyGroupModesVisibility(PROXY_GOLOGIN_GROUP_MODES_LIST),
    },
    {
      name: 'userProxiesVisibility',
      translationKey: getGologinProxiesVisibilityKey('UserProxies', [PROXY_GROUP_MODES.userProxies]),
      icon: getAreProxyGroupModesVisible([PROXY_GROUP_MODES.userProxies]) ?
        <IconEyeCrossed {...itemRowIconProps} /> : <IconEyeOpen {...itemRowIconProps} />,
      onAction: () => toggleProxyGroupModesVisibility([PROXY_GROUP_MODES.userProxies]),
    },
    {
      divider: true,
    },
    ...sortActions,
  ];

  // TODO: separate commons parts with profile-dropdown-menu
  const isMenuDivider = (menuItem: IMenuItem | IMenuDivider): menuItem is IMenuDivider => (menuItem as IMenuDivider).divider;

  const onActionMenuItem = (onAction: () => void, isDisabled?: boolean): void => {
    if (isDisabled) {
      return;
    }

    onAction();
  };

  const renderMenuItem = (menuItem: ProxySectionMenuItem | IMenuDivider, index: number): JSX.Element => {
    if (isMenuDivider(menuItem)) {
      return (
        <DividerContainer key={index}>
          <Divider />
        </DividerContainer>
      );
    }

    const {
      name,
      isDisabled,
      onAction,
      translationKey,
      icon: leftIcon,
      rightIcon = null,
    } = menuItem;

    return (
      <ProxySectionContextMenuPopoverItemRow
        key={name}
        onClick={(): void => onActionMenuItem(onAction, isDisabled)}
      >
        {leftIcon}
        <span>
          <Trans i18nKey={translationKey} />
        </span>
        {rightIcon}
      </ProxySectionContextMenuPopoverItemRow>
    );
  };

  return (
    <GologinPopover
      anchorEl={anchorElement}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      extraTranslate={{ left: 10, bottom: 6 }}
      width={209}
      zIndex={1060}
    >
      {actions.map(renderMenuItem)}
    </GologinPopover>
  );
};

export default ProxySectionContextMenuPopover;

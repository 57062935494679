import React, { useRef } from 'react';

import ProxySectionContextMenuPopover from './proxy-section-context-menu-popover';
import { IconMeatballsWrapper } from './styles';
import {
  hideProxySectionContextMenu,
  toggleProxySectionContextMenuVisibility,
  useIsProxySectionContextMenuButtonVisible,
  useProxySectionContextMenuTargetRef,
} from '../../../../../../state/proxy/proxy-groups/proxy-section-context-menu.atom';
import { DivAnchor } from '../../../../../../types';
import { IconMeatballs } from '../../../../../../ui/gologin-header/icons';

const ICON_ACTIVE_COLOR = 'var(--2B2B31-header)';

export const ProxySectionContextMenu: React.FC = () => {
  const isProxySectionContextMenuButtonVisible = useIsProxySectionContextMenuButtonVisible();
  const proxySectionContextMenuTargetRef = useProxySectionContextMenuTargetRef();

  const buttonWrapperRef = useRef<DivAnchor>(null);

  const proxySectionContextMenuTarget: DivAnchor = proxySectionContextMenuTargetRef?.current || null;
  const isMeatballsButtonActive = !!proxySectionContextMenuTarget;
  const isMeatballsButtonVisible = isProxySectionContextMenuButtonVisible || isMeatballsButtonActive;

  const handleClick = (): void => {
    toggleProxySectionContextMenuVisibility(buttonWrapperRef);
  };

  const handleClose = (): void => {
    hideProxySectionContextMenu();
  };

  return (
    <>
      <IconMeatballsWrapper ref={buttonWrapperRef}>
        {isMeatballsButtonVisible ? (
          <IconMeatballs
            padding={0}
            iconColor={isMeatballsButtonActive ? ICON_ACTIVE_COLOR : 'var(--98989F-proxy-manager-icon)'}
            iconHoveredColor={ICON_ACTIVE_COLOR}
            onClick={handleClick}
          />
        ) : null}
      </IconMeatballsWrapper>
      <ProxySectionContextMenuPopover
        anchorElement={proxySectionContextMenuTarget}
        handleClose={handleClose}
      />
    </>
  );
};

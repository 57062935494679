import React from 'react';
import { useTranslation } from 'react-i18next';

import PriceItem from './price-item';
import { ItemRow, PriceListPopoverCustom } from './styles';
import { GeoProxyType } from '../../../../../common/constants/types';
import { IGeolocationProxyPrices } from '../../../../interfaces';
import { useCurrentTheme } from '../../../../state/theme.atom';
import getSelectedThemeColor from '../../../../utils/get-selected-theme-color';

type AccountMenuProps = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  price: IGeolocationProxyPrices;
  geoProxyType: GeoProxyType;
  availableForPurchase: number[];
  visionSide?: 'left' | 'right';
};

const PriceList: React.FC<AccountMenuProps> = (props) => {
  const {
    anchorEl,
    onClose,
    price,
    geoProxyType,
    availableForPurchase,
    visionSide = 'left',
  } = props;

  const theme = useCurrentTheme();
  const { t: translation } = useTranslation();

  const isDarkTheme = getSelectedThemeColor(theme) === 'dark';

  return (
    <PriceListPopoverCustom
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      style={{ zIndex: 1200 }}
      isDarkTheme={isDarkTheme}
    >
      <ItemRow
        style={{ color: 'var(--B3B3B8-header-buy-proxy)', cursor: 'default' }}
      >
        {translation('trafficModal.buyTraffic')}
      </ItemRow>
      {availableForPurchase.map((count) => (
        <PriceItem
          key={count}
          price={price}
          geoProxyType={geoProxyType}
          count={count}
          onClose={onClose}
          visionSide={visionSide}
        />
      ))}
    </PriceListPopoverCustom>
  );
};

export default PriceList;

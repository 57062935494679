import React, { FC } from 'react';

import { ChangeLimitsContainer } from './styles';
import { IconPlus } from '../../../../../ui/gologin-header/icons';
import { IconMinus } from '../../../../../ui/icons/IconMinus';

export interface IChangeLimitSelectorProps {
  maxValue: number;
  onPlusClick: () => void;
  onMinusClick: () => void;
  isCustomPlan: boolean;
}

const ChangeLimitSelector: FC<IChangeLimitSelectorProps> = (props) => {
  const { maxValue, onPlusClick, onMinusClick, isCustomPlan } = props;

  const iconColor = isCustomPlan ? 'var(--98989F-new-pricing-limits-select-custom)' : 'var(--98989F-new-pricing-limits-select)';
  const iconHoveredColor = isCustomPlan ? 'var(--36363D-new-pricing-limits-select-custom)' : 'var(--36363D-new-pricing-limits-select)';

  return (
    <ChangeLimitsContainer isCustomPlan={isCustomPlan} >
      <IconMinus
        onClick={onMinusClick}
        styleType='lightGray'
        iconColor={iconColor}
        iconHoveredColor={iconHoveredColor}
      />
      {maxValue}
      <IconPlus
        onClick={onPlusClick}
        styleType='lightGray'
        iconColor={iconColor}
        iconHoveredColor={iconHoveredColor}
      />
    </ChangeLimitsContainer>
  );
};

export default ChangeLimitSelector;

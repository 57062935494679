import React from 'react';
import { Trans } from 'react-i18next';

import { ProxyEditButtonWrapper } from './styles';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { IconSpin } from '../../../ui/gologin-header/icons';
import IconProxyPencil from '../../../ui/icons/IconProxyPencil';
import TooltipWrapper from '../../../ui/tooltip-custom/tooltip-wrapper';

type ProxyEditButtonProps = {
  isProxyRestoring: boolean;
  handleProxyInfoEditClick?: React.MouseEventHandler<Element>;
}

const ProxyEditButton: React.FC<ProxyEditButtonProps> = (props) => {
  const { isProxyRestoring, handleProxyInfoEditClick } = props;
  if (isProxyRestoring) {
    return (
      <IconSpin
        hasSpin={true}
        size={16}
        iconColor='var(--767676-proxy-manager-check-proxy-button)'
        padding={0}
      />
    );
  }

  return (
    <TooltipWrapper
      isTransition={true}
      value={<Trans i18nKey='proxies.editProxy' />}
    >
      <ProxyEditButtonWrapper
        isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroupsV2}
        onClick={handleProxyInfoEditClick}
      >
        <IconProxyPencil />
      </ProxyEditButtonWrapper>
    </TooltipWrapper>
  );
};

export default ProxyEditButton;

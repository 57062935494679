import React from 'react';
import { Trans } from 'react-i18next';

import { PurchaseTrafficButtonText, PurchaseTrafficButtonWrapper } from './styles';
import { IconLockSmall } from '../../../../../ui/gologin-header/icons/icon-lock-small';
import TooltipWrapper from '../../../../../ui/tooltip-custom/tooltip-wrapper';

const PurchaseTrafficButton: React.FC = () => (
  <TooltipWrapper
    isTransition={true}
    value={<Trans i18nKey='proxyManager.groupPurchaseButton.tooltip' />}
  >
    <PurchaseTrafficButtonWrapper>
      <IconLockSmall
        padding={0}
        iconColor='var(--98989F-header)'
      />
      <PurchaseTrafficButtonText>
        <Trans i18nKey='proxyManager.groupPurchaseButton.text' />
      </PurchaseTrafficButtonText>
    </PurchaseTrafficButtonWrapper>
  </TooltipWrapper>
);

export default PurchaseTrafficButton;

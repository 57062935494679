import { RESOLUTIONS } from '../features/common/all-resolutions';
import { UserScreenInfo } from '../interfaces/';

let ipcRenderer: Electron.IpcRenderer;
const isElectron = !!window.require;

if (window.require) {
  ({ ipcRenderer } = window.require('electron'));
}

export const getUserScreenInfo = async (): Promise<UserScreenInfo> => {
  if (!isElectron) {
    return {
      width: window.screen.width,
      height: window.screen.height,
    };
  }

  return ipcRenderer.invoke('get-user-screen-info');
};

export const getUserScreenResolution = ({ width, height }: UserScreenInfo): typeof RESOLUTIONS[number] | '' => {
  if (!width) {
    return '';
  }

  // тут as необходим, так как разрешение экрана юзера может не входить в RESOLUTIONS (разрешений существует много),
  // но оно в любом случае будет иметь тот же самый формат, что и разрешения в RESOLUTIONS.
  return `${width}x${height}` as typeof RESOLUTIONS[number];
};

import { atom, getDefaultStore, useAtomValue } from 'jotai';
import { sendActionAnalytics } from '../../../features/common/api';
import { ExtraActionInfo, GROUPED_PROXY_MANAGER_ANALYTICS_EVENTS } from '../../../../common/constants/analytics';
import { convertProxyGroupIdToCountry } from '../../../features/proxy/utils/proxy-id';

export const visibleProxyGroupIdsAtom = atom<string[]>([]);

const getVisibleProxyGroupIds = (): string[] => getDefaultStore().get(visibleProxyGroupIdsAtom);
const setVisibleProxyGroupIds = (groupIds: string[]): void => getDefaultStore().set(visibleProxyGroupIdsAtom, groupIds);
export const useVisibleProxyGroupIds = (): string[] => useAtomValue(visibleProxyGroupIdsAtom);

export const hideProxyGroups = (): void => setVisibleProxyGroupIds([]);

export const toggleProxyGroup = (groupIdToToggle: string): void => {
  const proxyGroupsCurrentlyVisible = getVisibleProxyGroupIds();

  const isGroupAlreadyVisible = proxyGroupsCurrentlyVisible.includes(groupIdToToggle);
  const groupCountry = convertProxyGroupIdToCountry(groupIdToToggle);
  const analyticsExtraInfo: ExtraActionInfo = { proxyCountry: groupCountry.toUpperCase() };

  let proxyGroupsNewVisible = [...proxyGroupsCurrentlyVisible, groupIdToToggle];
  if (isGroupAlreadyVisible) {
    proxyGroupsNewVisible = proxyGroupsCurrentlyVisible.filter(groupId => groupId !== groupIdToToggle);
    sendActionAnalytics(GROUPED_PROXY_MANAGER_ANALYTICS_EVENTS.collapsedProxiesGroup, analyticsExtraInfo);
  } else {
    sendActionAnalytics(GROUPED_PROXY_MANAGER_ANALYTICS_EVENTS.expandedProxiesGroup, analyticsExtraInfo);
  }

  setVisibleProxyGroupIds(proxyGroupsNewVisible);
};

import { message } from 'antd';
import React from 'react';
import { Trans } from 'react-i18next';
import { LocalizationErrorMessages } from '../../../../common/constants/localization-error-messages';
import { sendReactErrorToSentry } from '../../../utils/sentry.helper';
import { openProfilesTableModal, PAYMENT_EXPIRED_MODAL_NAME } from '../../../state/profiles-table-modal.atom';

interface IProxyLimitError {
  body: {
    message?: string;
    currentCount?: number;
    maxCount?: number;
  };
}

export const handleProxiesLimitError = (error: any): void => {
  if (!error?.body) {
    sendReactErrorToSentry({
      message: JSON.stringify(error), transactionName: 'handle-proxies-limit-no-error-body',
      extra: { error },
    });
  } else if (!error.body.message) {
    sendReactErrorToSentry({
      message: JSON.stringify(error), transactionName: 'handle-proxies-limit-no-error-message',
      extra: { error },
    });
  }

  const defaultErrorMessage = LocalizationErrorMessages.SomethingWentWrongAgainLater;
  const { body } : IProxyLimitError = error || {};
  const { message: errMessage = defaultErrorMessage } = body || {};

  if (errMessage === 'base.notification.reachedMaxProxiesLimitForUnpaid') {
    openProfilesTableModal(PAYMENT_EXPIRED_MODAL_NAME);
  }

  if (!Object.hasOwn(body, 'maxCount')) {
    const errorMessageComponent = <Trans i18nKey={errMessage} />;
    message.error(errorMessageComponent);

    return;
  }

  const { message: errorMessage = defaultErrorMessage, currentCount = 0, maxCount = 0 } = body || {};
  const errorMessageComponent = <Trans i18nKey={errorMessage} values={{ currentCount, maxCount }} />;
  message.error(errorMessageComponent);
};

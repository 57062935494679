import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconLogout: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg
      width={16}
      height={16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_29800_198778)'>
        <path
          d='M8.5 8.5H15.5'
          strokeMiterlimit={10}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12.5 5.5L15.5 8.5L12.5 11.5'
          strokeMiterlimit={10}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.5 4.5V0.5H0.5V11.5L5.5 15.5V5.5L0.5 0.5'
          strokeMiterlimit={10}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_29800_198778'>
          <rect width={16} height={16} fill='white' />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);

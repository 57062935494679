import { useCallback, useState } from 'react';

import { RESOLUTIONS } from '../../features/common/all-resolutions';
import { ITemplatesCtx, ITemplate, TEMPLATE_DEFAULT_VALUE } from '../../state';

export const useTemplate = (): ITemplatesCtx => {
  const [selectedTemplate, setSelectedTemplate] = useState<ITemplate>(TEMPLATE_DEFAULT_VALUE);
  const [availableTemplates, setAvailableTemplates] = useState<ITemplate[]>([TEMPLATE_DEFAULT_VALUE]);

  const updateSelectedTemplate = useCallback((template: ITemplate) => {
    const newSelectedTemplate: ITemplate = {
      ...TEMPLATE_DEFAULT_VALUE,
      ...template,
      profileName: {
        ...TEMPLATE_DEFAULT_VALUE.profileName,
        ...template.profileName,
      },
      browser: {
        ...TEMPLATE_DEFAULT_VALUE.browser,
        ...template.browser,
      },
    };

    setSelectedTemplate(newSelectedTemplate);
    setAvailableTemplates(prev => [...prev.filter(temp => temp.id && temp.id !== template.id), newSelectedTemplate]);
  }, [setSelectedTemplate, setAvailableTemplates]);

  const updateAvailableTemplates = useCallback((templates: ITemplate[], userScreenResolution: typeof RESOLUTIONS[number] | ''): void => {
    const selectedTemplateFromList = templates.find(template => selectedTemplate && selectedTemplate.id === template.id)
      || templates[0]
      || TEMPLATE_DEFAULT_VALUE;

    // если нет id, значит темплейта в базе не существует, ставим дефолтное разрешение экрана юзера
    const isDefaultTemplate = !selectedTemplateFromList.id;
    if (isDefaultTemplate && userScreenResolution) {
      selectedTemplateFromList.browser.minResolution = userScreenResolution;
      selectedTemplateFromList.browser.maxResolution = userScreenResolution;
    }

    if (
      (selectedTemplateFromList.id !== selectedTemplate.id) ||
      (isDefaultTemplate && userScreenResolution)
    ) {
      updateSelectedTemplate(selectedTemplateFromList);
    }

    setAvailableTemplates(templates);
  }, [selectedTemplate.id, setAvailableTemplates]);

  return {
    selectedTemplate,
    updateSelectedTemplate,
    availableTemplates,
    updateAvailableTemplates,
  };
};

import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconWarningTriangle: React.FC<IIconWrapper> = props => (
  <IconWrapper {...props}>
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 13C8.55228 13 9 12.5523 9 12C9 11.4477 8.55228 11 8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13Z"
        fill="#F4752E"
      />
      <path
        d="M8 4.5V9.5"
        stroke="#F4752E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.741037 12.7768L6.97004 1.20877C7.07022 1.02284 7.21887 0.867485 7.40021 0.759204C7.58156 0.650923 7.78883 0.59375 8.00004 0.59375C8.21125 0.59375 8.41852 0.650923 8.59986 0.759204C8.7812 0.867485 8.92985 1.02284 9.03004 1.20877L15.259 12.7768C15.3548 12.955 15.4028 13.155 15.3982 13.3573C15.3936 13.5595 15.3367 13.7572 15.2329 13.9308C15.1291 14.1045 14.9821 14.2483 14.8062 14.3482C14.6302 14.4481 14.4314 14.5007 14.229 14.5008H1.77104C1.56871 14.5007 1.36987 14.4481 1.19392 14.3482C1.01797 14.2483 0.870936 14.1045 0.767167 13.9308C0.663397 13.7572 0.606442 13.5595 0.601862 13.3573C0.597283 13.155 0.645235 12.955 0.741037 12.7768V12.7768Z"
        stroke="#F4752E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </IconWrapper>
);

import { message } from 'antd';
import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DropdownPopover } from './dropdown-popover';
import { IDropdownMenu, IMenuDivider, IMenuItem } from './interfaces';
import { Divider, DividerContainer, ItemRow, itemRowIconProps } from './styles';
import { E_ANALYTICS_ACTIONS } from '../../../../common/constants/analytics';
import { performGlobalDeltaSync } from '../../../object-pool/delta-sync/perform-global-delta-sync';
import { workspaceContext } from '../../../state';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { reloadProfilesTableProfiles } from '../../../state/profiles-table/profiles-query';
import {
  DELETE_OR_LEAVE_PROFILE_MODAL_NAME,
  FOLDERS_MANAGER_MODAL_NAME,
  openProfilesTableModal,
  PAYMENT_EXPIRED_MODAL_NAME,
  PROFILE_COOKIES_MODAL_NAME,
  PROFILE_HISTORY_MODAL_NAME,
  SHARE_MODAL_NAME,
  TRANSFER_PROFILE_MODAL_NAME,
  UPDATE_PROFILE_MODAL_NAME,
} from '../../../state/profiles-table-modal.atom';
import {
  IconBinTrashDelete,
  IconClone,
  IconCookies,
  IconCopySmall,
  IconFolder,
  IconHistory,
  IconLink,
  IconPin,
  IconRunProfile,
  IconSettings,
  IconShare,
  IconTransfer,
  IconUpdate,
} from '../../../ui/gologin-header/icons';
import { sendActionAnalytics } from '../../common/api';
import { DEEP_LINKS_REDIRECT_ORIGIN } from '../../common/deep-links';
import { calculateProfileDeleteOrLeave } from '../../modalsComponents/components/remove-profile-modal';
import { launchProfileWeb } from '../actions';
import { submitProfilesClone } from '../api';
import { IconAutomation } from '../../../ui/gologin-header/icons/icon-automation';
import { openAutomationModal } from '../../../state/automation/automation-modal.atom';

const isElectron = !!window.require;

const ProfileDropdownMenu: FC<IDropdownMenu> = (props) => {
  const {
    profile,
    navigateToUpdatePage,
    isTriggerAlwaysShown,
    modifyProfilePin,
  } = props;

  const { id: profileId, isPinned, canBeRunning, status: runStatus } = profile;

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const { id: workspaceId, isUnpaid } = useContext(workspaceContext);

  const { t: translation } = useTranslation();

  const isDeleteDisabled = !(canBeRunning && ['profileStatuses.error', 'profileStatuses.ready'].includes(runStatus));
  const deleteAction = calculateProfileDeleteOrLeave(profile);

  const onCloneProfile = async (): Promise<void> => {
    await submitProfilesClone(workspaceId, [profileId]);
    if (NEW_FEATURES.objectPool) {
      await performGlobalDeltaSync();
    }

    reloadProfilesTableProfiles();
  };

  const onCopy = (text: string, successMessage: string): void => {
    navigator.clipboard.writeText(text).then(() => message.success(translation(successMessage)));
  };

  const onActionMenuItem = (onAction: () => void, isDisabled?: boolean): void => {
    if (isDisabled) {
      return;
    }

    onAction();
  };

  const openDeleteOrLeaveProfileTableModal = (): void => {
    openProfilesTableModal(DELETE_OR_LEAVE_PROFILE_MODAL_NAME, [profileId]);
    sendActionAnalytics(E_ANALYTICS_ACTIONS.clickedDeleteProfileProfileMenu, { profile: profileId });
  };

  const openModalAutomation = (): void => {
    openAutomationModal(profileId);
    sendActionAnalytics(E_ANALYTICS_ACTIONS.clickedAutomationProfileMenu, { profile: profileId });
  };

  const handleRunInCloud = () => {
    if (isUnpaid) {
      openProfilesTableModal(PAYMENT_EXPIRED_MODAL_NAME);

      return;
    }

    launchProfileWeb(profile);
  };
 
  const isMenuDivider = (menuItem: IMenuItem | IMenuDivider): menuItem is IMenuDivider => (menuItem as IMenuDivider).divider;

  const renderMenuItem = (menuItem: IMenuItem | IMenuDivider): JSX.Element => {
    if (isMenuDivider(menuItem)) {
      return (
        <DividerContainer>
          <Divider />
        </DividerContainer>
      );
    }

    const { name, isDisabled, onAction, translationKey, icon } = menuItem;

    return (
      <ItemRow
        key={name}
        isDisabled={isDisabled}
        onClick={(): void => onActionMenuItem(onAction, isDisabled)}
      >
        {icon}
        <span style={{ marginLeft: 8 }}>
          {translation(translationKey || `profiles.menu.${name}`)}
        </span>
      </ItemRow>
    );
  };

  const actions: (IMenuItem | IMenuDivider)[] = [
    {
      name: 'editProfile',
      icon: <IconSettings {...itemRowIconProps} />,
      onAction: navigateToUpdatePage,
    },
    {
      name: 'folders',
      translationKey: 'folders.title',
      icon: <IconFolder {...itemRowIconProps} />,
      onAction: () => openProfilesTableModal(FOLDERS_MANAGER_MODAL_NAME, [profile.id]),
    },
    {
      name: 'sharing',
      icon: <IconShare {...itemRowIconProps} iconType='stroke' />,
      onAction: () => {
        sendActionAnalytics(E_ANALYTICS_ACTIONS.visitedShareProfile, { actionInfo: 'profile menu' });
        openProfilesTableModal(SHARE_MODAL_NAME, [profile.id]);
      },
    },
    {
      name: 'clone',
      icon: <IconClone {...itemRowIconProps} />,
      onAction: onCloneProfile,
    },
    {
      name: 'transfer',
      translationKey: 'tableProfiles.menu.transfer',
      icon: <IconTransfer {...itemRowIconProps} />,
      onAction: () => openProfilesTableModal(TRANSFER_PROFILE_MODAL_NAME, [profileId]),
      isDisabled: !profile.permissions?.transferProfile,
      isVisible: NEW_FEATURES.workspaces || false,
    },
    {
      divider: true,
    },
    {
      name: 'cloudRun',
      icon: <IconRunProfile {...itemRowIconProps} />,
      onAction: () => handleRunInCloud(),
      isVisible: isElectron,
    },
    {
      name: 'cookies',
      translationKey: 'base.cookies',
      icon: <IconCookies {...itemRowIconProps} />,
      onAction: () => openProfilesTableModal(PROFILE_COOKIES_MODAL_NAME, [profileId]),
    },
    {
      name: 'history',
      icon: <IconHistory {...itemRowIconProps} />,
      onAction: () => openProfilesTableModal(PROFILE_HISTORY_MODAL_NAME, [profileId]),
    },
    {
      name: 'updateBrowser',
      icon: <IconUpdate {...itemRowIconProps} />,
      onAction: () => openProfilesTableModal(UPDATE_PROFILE_MODAL_NAME, [profileId]),
      isVisible: profile.suggestUpdateUA || false,
    },
    {
      divider: true,
    },
    {
      name: 'copyLink',
      translationKey: 'tableProfiles.contextMenu.openLinkCopy',
      icon: <IconLink {...itemRowIconProps} />,
      onAction: () => onCopy(`${DEEP_LINKS_REDIRECT_ORIGIN}/${encodeURIComponent(profile?.name)}`, 'base.copiedText'),
    },
    {
      name: 'copyProfileId',
      icon: <IconCopySmall {...itemRowIconProps} />,
      onAction: () => onCopy(profile?.id, 'base.copiedText'),
    },
    {
      name: 'pin',
      icon: <IconPin {...itemRowIconProps} />,
      onAction: () => modifyProfilePin(isPinned ? 'unpinned' : 'pinned'),
      translationKey: isPinned ? 'profiles.menu.unpin' : 'profiles.menu.pin',
    },
    {
      divider: true,
    },
    {
      name: 'delete',
      translationKey: `base.${deleteAction}`,
      icon: <IconBinTrashDelete {...itemRowIconProps} />,
      onAction: openDeleteOrLeaveProfileTableModal,
      isDisabled: isDeleteDisabled,
    },
    {
      name: 'automation',
      translationKey: 'profiles.menu.automation',
      icon: <IconAutomation {...itemRowIconProps} />,
      onAction: openModalAutomation,
      isDisabled: !isElectron,
    },
  ];

  const handlePopoverOpen = (): void => {
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = (): void => {
    setIsPopoverOpen(false);
  };

  return (
    <DropdownPopover
      isPopoverOpen={isPopoverOpen}
      onOpen={handlePopoverOpen}
      onClose={handlePopoverClose}
      isTriggerAlwaysShown={isTriggerAlwaysShown}
    >
      {actions.filter(action => action.isVisible ?? true).map(renderMenuItem)}
    </DropdownPopover>
  );
};

export default ProfileDropdownMenu;

const AWLAYS_DISABLED_FEATURE_VALUE = <const>false;
type AlwaysDisabledFeatureValue = typeof AWLAYS_DISABLED_FEATURE_VALUE;

export interface IBackendNewUserFeatures {
  header?: boolean;
  drawer?: boolean;
  pricing?: boolean;
  workspaces?: boolean;
  workspacePayments?: boolean;
  workspaceInviteInFolders?: boolean;
  migrationToWorkspace?: boolean;
  replaceIntercomCall?: boolean;
  dragAndDrop?: boolean;
  proxyGroups?: AlwaysDisabledFeatureValue;
  hideTorAndFreeProxy?: AlwaysDisabledFeatureValue;
  pricingGeoProxyTraffic?: AlwaysDisabledFeatureValue;
  proxyGroupsV2?: boolean;
  hideTorAndFreeProxyV2?: boolean;
  pricingGeoProxyTrafficV2?: boolean;
  renameProxyToLocation: boolean;
  paddlePayments?: boolean;
}

export interface INewFeatures extends IBackendNewUserFeatures {
  themeSwitch?: boolean;
  importProxyFile?: boolean;
  templateProfileName?: boolean;
  newStripeCheckout?: boolean;
  proxyPagination?: boolean;
  showUpgrade?: boolean;
}

const DEFAULT_NEW_FEATURES: INewFeatures = {
  header: true,
  drawer: true,
  pricing: false,
  themeSwitch: true,
  workspaces: true,
  workspacePayments: true,
  workspaceInviteInFolders: true,
  migrationToWorkspace: true,
  replaceIntercomCall: true,
  dragAndDrop: true,
  importProxyFile: true,
  templateProfileName: true,
  newStripeCheckout: true,
  proxyPagination: true,
  showUpgrade: true,
  proxyGroups: AWLAYS_DISABLED_FEATURE_VALUE,
  hideTorAndFreeProxy: AWLAYS_DISABLED_FEATURE_VALUE,
  pricingGeoProxyTraffic: AWLAYS_DISABLED_FEATURE_VALUE,
  proxyGroupsV2: true,
  hideTorAndFreeProxyV2: true,
  pricingGeoProxyTrafficV2: true,
  renameProxyToLocation: true,
  paddlePayments: false,
};

export let NEW_FEATURES: INewFeatures = { ...DEFAULT_NEW_FEATURES };

const getPreparedFeature = (feature: INewFeatures): INewFeatures => {
  const preparedFeature: INewFeatures = feature;
  if (feature.workspaces) {
    preparedFeature.header = true;
    preparedFeature.drawer = true;
  }

  if (feature.header) {
    preparedFeature.templateProfileName = true;
  }

  if (feature.migrationToWorkspace) {
    preparedFeature.workspaces = true;
  }

  // drag and drop won't work on old design yet
  if (!feature.header) {
    preparedFeature.dragAndDrop = false;
  }

  return preparedFeature;
};

export const toggleNewUserFeature = (newFeatures: INewFeatures): void => {
  const keys: (keyof INewFeatures)[] = Object.keys(DEFAULT_NEW_FEATURES) as Array<keyof typeof DEFAULT_NEW_FEATURES>;
  const feature: INewFeatures = {};

  keys.forEach(key => {
    feature[key] = DEFAULT_NEW_FEATURES[key] && (newFeatures[key] ?? false);
  });

  const preparedFeature = getPreparedFeature(feature);
  NEW_FEATURES = {
    ...preparedFeature,
    newStripeCheckout: true,
    proxyGroups: AWLAYS_DISABLED_FEATURE_VALUE,
    hideTorAndFreeProxy: AWLAYS_DISABLED_FEATURE_VALUE,
    pricingGeoProxyTraffic: AWLAYS_DISABLED_FEATURE_VALUE,
  };
};

export const resetNewUserFeature = (): void => {
  NEW_FEATURES = {
    ...DEFAULT_NEW_FEATURES,
  };
};

export const setFeatures = (features: Partial<INewFeatures>): void => {
  NEW_FEATURES = {
    ...NEW_FEATURES,
    ...features,
  };

  window.location.reload();
};

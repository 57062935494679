import React, { FC, memo } from 'react';
import { Trans } from 'react-i18next';

import { E_ANALYTICS_ACTIONS } from '../../../../common/constants/analytics';
import {
  OLD_PRICING_PAGE_URL,
  PRICING_PAGE_URL,
} from '../../../../common/constants/constants';
import { GeoProxyType } from '../../../../common/constants/types';
import { sendActionAnalytics } from '../../../features/common/api';
import {
  getAutoLoginToken,
  IGetAutoLoginToken,
} from '../../../features/pricing/api';
import { E_PAYMENT_METHODS } from '../../../features/pricing/interfaces';
import GroupsTableCustomizationMenu from '../../../features/quickProfiles/table-customization-menu/groups-table-customization-menu';
import { sendReactErrorToSentry } from '../../../utils/sentry.helper';
import GologinPopover from '../../gologin-popover';
import { GologinPopoverItemRow } from '../../gologin-popover/gologin-popover-item-row';
import { getPaymentURL } from './api';

export const TABLE_CUSTOMIZATION_POPOVER_WIDTH = 183;
const isElectron = !!window.require;

interface ICustomizationOption {
  titleKey: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  isSelected?: boolean;
}

interface IProxyPaymentWaySelector {
  onClose: () => void;
  rootAnchorEl: HTMLElement | null;
  count: number;
  geoProxyType: GeoProxyType;
  visionSide?: 'left' | 'right';
}

const ProxyPaymentWaySelector: FC<IProxyPaymentWaySelector> = (props) => {
  const {
    rootAnchorEl,
    count,
    geoProxyType,
    onClose,
    visionSide = 'left',
  } = props;

  const onCardClick = async (): Promise<void> => {
    sendActionAnalytics(E_ANALYTICS_ACTIONS.clickedBuyProxy, {
      paymentMethod: E_PAYMENT_METHODS.PADDLE,
    });
    onClose();
    if (!isElectron) {
      window.location.replace(
        `${OLD_PRICING_PAGE_URL}/proxy?dataGbCount=${count}&dataType=${geoProxyType}`,
      );

      return;
    }

    const autoLoginTokenReq: IGetAutoLoginToken = await getAutoLoginToken();
    const autoLoginToken = autoLoginTokenReq?.auto_login_token;
    const checkoutPageUrl = `${PRICING_PAGE_URL}?dataGbCount=${count}&dataType=${geoProxyType}&auto-login-token=${autoLoginToken}`;
    window
      .require('electron')
      .shell.openExternal(checkoutPageUrl)
      .catch((error) => {
        const errorMessage = error instanceof Error ? error.message : 'unknown';
        sendReactErrorToSentry({
          message: errorMessage,
          transactionName: 'open-paddle-proxy-checkout-page',
        });
      });
  };

  const onCryptoClick = async (): Promise<void> => {
    sendActionAnalytics(E_ANALYTICS_ACTIONS.clickedBuyProxy, {
      paymentMethod: E_PAYMENT_METHODS.CRYPTO,
    });
    onClose();
    const { checkoutUrl } = await getPaymentURL({
      dataGbCount: count,
      dataType: geoProxyType,
      path: window.location.hash,
    });

    if (isElectron) {
      window.require('electron').shell.openExternal(checkoutUrl);

      return;
    }

    window.location.replace(checkoutUrl);
  };

  const customizationOptions: ICustomizationOption[] = [
    {
      titleKey: 'trafficModal.payWithCard',
      onClick: onCardClick,
    },
    {
      titleKey: 'trafficModal.payWithCrypto',
      onClick: onCryptoClick,
    },
  ];

  return (
    <>
      <GologinPopover
        anchorEl={rootAnchorEl}
        onClose={onClose}
        anchorOrigin={
          visionSide === 'left'
            ? { vertical: 'top', horizontal: 'left' }
            : { vertical: 'top', horizontal: 'right' }
        }
        transformOrigin={
          visionSide === 'left'
            ? { vertical: 'top', horizontal: 'right' }
            : { vertical: 'top', horizontal: 'left' }
        }
        width={TABLE_CUSTOMIZATION_POPOVER_WIDTH}
        extraTranslate={{ left: 9 }}
      >
        {customizationOptions.map(({ titleKey, onClick, isSelected }) => (
          <GologinPopoverItemRow
            onClick={onClick}
            isSelected={isSelected}
            key={titleKey}
            style={{ gap: 8, padding: '6px 12px 6px 12px' }}
          >
            <div style={{ flex: '1' }}>
              <Trans i18nKey={titleKey} />
            </div>
          </GologinPopoverItemRow>
        ))}
      </GologinPopover>
      <GroupsTableCustomizationMenu />
    </>
  );
};

export default memo(ProxyPaymentWaySelector);

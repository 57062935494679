import { Popper, PopperProps } from '@material-ui/core';
import React, { memo, useCallback, useEffect } from 'react';

import ProxyDeletionConfirm from './proxy-deletion-confirm';
import ProxyManagerForm from './proxy-edit-view/proxy-manager-form';
import ProxyList, { ProxyListProps } from './proxy-list';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { useProfilesList } from '../../../state/profiles-list.atom';
import { closeProxyManager, ProxySelectorLocation, useProxyManagerState } from '../../../state/proxy/proxy-manager-modal-status.atom';
import { EMPTY_PROXY } from '../constants';
import ProxyPopoverWrapper from '../proxy-popover-wrapper';

const PROFILES_TABLE_PROXY_MANAGER_MARGIN = '-8px 35px 8px -35px';
const DRAWER_PROXY_MANAGER_MARGIN = '8px 35px -8px -35px';

const ProxyManager: React.FC = () => {
  const profilesList = useProfilesList();
  const {
    modalView,
    currentProfileId,
    currentProxy: currentProxyInManager,
    containerElement,
    proxySelectorLocation,
  } = useProxyManagerState();

  const handleGlobalKeyDown = useCallback((event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      closeProxyManager();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleGlobalKeyDown, true);

    return () => {
      document.removeEventListener('keydown', handleGlobalKeyDown, true);
    };
  }, []);

  const currentProfile = profilesList.find(profile => profile.id === currentProfileId);
  const currentProxy = currentProxyInManager || currentProfile?.proxy || EMPTY_PROXY;

  const renderContent = (): JSX.Element => {
    const props: ProxyListProps = { currentProxy, localProxyElementLocation: 'no-location' };
    switch (true) {
      case modalView === 'proxy-list':
        return <ProxyList {...props} />;
      default:
        return <ProxyManagerForm />;
    }
  };

  let popperMargin: React.CSSProperties['margin'] = NEW_FEATURES.proxyGroupsV2 ? PROFILES_TABLE_PROXY_MANAGER_MARGIN : '0px 32px 0px -32px';
  switch (true) {
    case proxySelectorLocation === ProxySelectorLocation.profileSettingsPage:
      popperMargin = 0;
      break;
    case !NEW_FEATURES.header:
      switch (true) {
        case proxySelectorLocation === 'old-drawer':
          popperMargin = '0px 32px 0px -32px';
          break;
        default:
          popperMargin = '-16px 32px 16px -32px';
          break;
      }

      break;
    case proxySelectorLocation && ['pinned-proxy', 'proxy-proxy'].includes(proxySelectorLocation):
      popperMargin = NEW_FEATURES.proxyGroupsV2 ? DRAWER_PROXY_MANAGER_MARGIN : '16px 32px -16px -32px';
      break;
    default:
      break;
  }

  const popperProps: Omit<PopperProps, 'children'> = {
    open: !!modalView,
    style: { zIndex: 1060, margin: popperMargin },
    disablePortal: false,
    anchorEl: containerElement,
    placement: 'bottom-start',
    modifiers: {
      preventOverflow: { enabled: true, boundariesElement: 'window' },
      offset: { enabled: true },
    },
  };

  return (
    <Popper {...popperProps}>
      <ProxyPopoverWrapper>
        {renderContent()}
        <ProxyDeletionConfirm />
      </ProxyPopoverWrapper>
    </Popper>
  );
};

export default memo(ProxyManager);

import { ProfileStatusTranslationKey, ProfileStatusType } from '../../app/types';
import { IProfileOptions } from './profile-extractor.interfaces';

export interface ITransferStatusData {
  status: ProfileStatusType;
  statusMessage?: IStatusMessageObject|string;
  startProfileTimestamp?: number;
  errorMessage?: string;
  profileOptions?: IProfileOptions;
  timings?: any;
  proxyId?: string;
}

export type ProfileStatus = {
  key: ProfileStatusType;
  priority: number;
  info: ProfileStatusTranslationKey;
};

export interface IProfileStatusUnexpected {
  key: ProfileStatus['key'];
  priority: ProfileStatus['priority'];
  info: 'unexpected';
}

export type IProfileStatusOrUnexpected = ProfileStatus | IProfileStatusUnexpected;

export const TimezoneErrorPrefixes = {
  timezone: 'timezone',
  proxy: 'proxy',
  resync: 'resync',
} as const;

type TimezoneErrorPrefix = typeof TimezoneErrorPrefixes[keyof typeof TimezoneErrorPrefixes];

interface IStatusMessageObject {
  errorType: string;
  errorMessage: string;
  prefix: TimezoneErrorPrefix;
}

export interface IUpdateProfileStatusEvent {
  status: ProfileStatusTranslationKey;
  message: IStatusMessageObject | string;
  profileId: string;
  proxyId?: string;
}

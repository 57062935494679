import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';

export const ProfilesCounterWrapper = styled.div<{ newStyle?: boolean }>`
  ${(props): SerializedStyles => props.newStyle ? css`
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;

    color: var(--98989F-proxy-manager-icon);
  ` : css`
    flex-grow: 1;

    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.2px;
    
    color: var(--CDCDCD-proxy-manager-profiles-count);
  `}

  & > * {
    width: min-content; // for tooltip to get distributed evenly under the counter 
  }
`;

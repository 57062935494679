import { css, SerializedStyles } from '@emotion/core';
import styled from '@emotion/styled';
import { Popover } from '@material-ui/core';

import { FOLDERS_POPOVER_Z_INDEX } from '../folders-menu/styles';

export const FOLDERS_LIST_CUSTOM_POPOVER_Z_INDEX = FOLDERS_POPOVER_Z_INDEX + 1;

export const FolderNameInput = styled.input`
  flex: 1;

  border: none;
  outline: none;
  font-size: 14px;

  padding: 0;

  background: transparent;
  color: var(--2B2B31-header-folders-menu);

  &::placeholder {
    color: var(--CDCDCD-header-folders-sub-menu);
  }
`;

export const ItemRow = styled.div`
  padding: 6px 16px;

  display: flex;
  flex-direction: row;
  align-items: center;

  color: var(--2B2B31-header-folders-menu);
  font-size: 14px;

  cursor: pointer;
`;

export const FolderRow = styled(ItemRow)`
  border-radius: 4px;
  background: var(--header-folders-sub-menu-background);

  :hover {
    background: var(--header-folders-sub-menu-item-hovered-background);
  }
`;

export const FoldersListPopoverCustom = styled(Popover)<{ isDarkTheme: boolean }>`
  z-index: ${FOLDERS_LIST_CUSTOM_POPOVER_Z_INDEX};

  .MuiPopover-paper {
    width: 214px;
    padding: 5px;
    outline: var(--header-folders-sub-menu-outline);
    border-radius: 4px;
    margin-top: 2px;
    margin-left: 92px;

    z-index: ${FOLDERS_LIST_CUSTOM_POPOVER_Z_INDEX};

    display: flex;
    flex-direction: column;

    background: var(--header-folders-sub-menu-background);

    ${(props): SerializedStyles|null => !props.isDarkTheme ? css`
      box-shadow: 0 3px 6px var(--0000001A-header-folders-sub-menu), 0 4px 8px var(--00000014-header-folders-sub-menu), 0 1px 12px var(--0000000A-header-folders-sub-menu);
    ` : null}
  }
`;

export const RemoveFolderText = styled.span`
  color: var(--767676-header-folders-sub-menu);

  :hover {
    color: var(--222222-header-folders-sub-menu);
  }
`;

import React, { useState } from 'react';

import TooltipCustom from './index';
import { ITooltipWrapper } from './interfaces';

const TooltipWrapper: React.FC<ITooltipWrapper> = (props) => {
  const { isTransition, containerStyle, style: tooltipContainerStyle, value, placement, children } = props;

  const [anchorElTooltip, setAnchorElTooltip] = useState<HTMLElement|null>(null);

  const showTooltip = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => setAnchorElTooltip(event.currentTarget);

  const hideTooltip = (): void => setAnchorElTooltip(null);

  return (
    <div
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      style={containerStyle}
    >
      {children}
      <TooltipCustom
        anchorEl={anchorElTooltip}
        value={value}
        style={tooltipContainerStyle}
        isTransition={isTransition}
        placement={placement}
      />
    </div>
  );
};

export default TooltipWrapper;

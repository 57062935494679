import * as Sentry from '@sentry/react';
import React from 'react';

import { message } from 'antd';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

import { E_ANALYTICS_ACTIONS } from '../../../../common/constants/analytics';
import { IProfile } from '../../../interfaces';
import { acceptDeleteProfiles } from '../../../object-pool/transactions/accepters/accept-delete-profiles';
import { getCurrentWorkspaceId } from '../../../state/current-workspace-id.atom';
import {
  getProfileRunStatus,
  upsertProfileRunStatuses,
} from '../../../state/profile-run-statuses.atom';
import { getOrbitaFeaturesOpts } from '../../../state/profiles-table/orbita-features';
import { filterProfilesByPermission, getProfilesList, IProfileRunStatus } from '../../../state/profiles-list.atom';
import { closeProfilesSettings, getProfilesSettingsState } from '../../../state/profiles-settings-atom';
import { reloadProfilesTableProfiles } from '../../../state/profiles-table/profiles-query';
import { openProfilesTableModal, TRANSFER_PROFILE_MODAL_NAME } from '../../../state/profiles-table-modal.atom';
import {
  getProfilesTableSelectedIds,
  getSelectedProfilesByAction,
} from '../../../state/profiles-table-selected-ids.atom';
import { generateBrowserIconsMassOpsPromise } from '../../browser-system-icons';
import { sendActionAnalytics } from '../../common/api';
import { getBrowserVersionCurrent } from '../../common/orbita-browser';
import { stopProfileOrbita, stopProfileWeb } from '../actions';
import { submitProfilesClone, removeMultipleInvites, submitProfilesDelete } from '../api';
import { ILaunchProfileOrbita } from '../components/interfaces/launch-profile-orbita.interface';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { performGlobalDeltaSync } from '../../../object-pool/delta-sync/perform-global-delta-sync';
import { CheckAvailableToStartWithAutomationProfiles } from '../interfaces/automation-check-available-profiles.interfaces';
import { StartProfiles } from './interfaces';

let ipcRenderer: Electron.IpcRenderer;
const isElectron = !!window.require;
if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

export const stopProfiles = (): void => {
  const selectedProfilesIds = getProfilesTableSelectedIds();
  getProfilesList().forEach((profile) => {
    if (!selectedProfilesIds.includes(profile.id)) {
      return;
    }

    const profileRunStatus = getProfileRunStatus(profile.id);
    if (profileRunStatus.isWeb && profileRunStatus.status === 'profileStatuses.running') {
      stopProfileWeb(profile.id, true);

      return;
    }

    stopProfileOrbita(profile.id);
  });
};

const getBrowserMajorVersionCurrent = (userAgent = ''): string => {
  const version = getBrowserVersionCurrent(userAgent);
  const [majorVersion] = version.split('.');

  return majorVersion;
};

const filterOrbitasVersionsAvailable = async (majorVersions: number[]): Promise<number[]> =>
  ipcRenderer.invoke('check-orbitas-available', majorVersions);

const isProfileVersionLoading = (profile: IProfile, versionLoadingNow?: string): boolean => {
  const chromeVersionMatch = profile.navigator.userAgent.match(/[\s\S\w]+Chrome\/(\d+)/) || [];
  if (!chromeVersionMatch) {
    return true;
  }

  const [_, version = ''] = chromeVersionMatch;

  return Number(versionLoadingNow) === Number(version);
};

const isProfileAvailableToRun = (profile: IProfile): boolean => {
  const selectedProfilesIds = getProfilesTableSelectedIds();
  if (!selectedProfilesIds.includes(profile.id)) {
    return false;
  }

  const profileRunStatus = getProfileRunStatus(profile.id);

  return profile.canBeRunning &&
    !(profileRunStatus.isWeb || profileRunStatus.status === 'profileStatuses.running' || isProfileVersionLoading(profile));
};

export const getAvailableToRunWithAutomationProfiles = async ({
  profiles,
  transaction,
}: CheckAvailableToStartWithAutomationProfiles): Promise<IProfile[]> => {
  const span = transaction?.startChild({ op: 'check-browser-version-before-launch-mass-start-automation' });
  let availableToRunProfiles = profiles;
  let orbitaVersionsToDownload: string[] = [];

  if (isElectron) {
    const profilesMajorVersions = availableToRunProfiles.map((profile) => +getBrowserMajorVersionCurrent(profile.navigator.userAgent));
    const profilesMajorVersionsUnique = [...new Set(profilesMajorVersions)];
    const orbitasVersionsAvailable = await filterOrbitasVersionsAvailable(profilesMajorVersionsUnique);

    availableToRunProfiles = availableToRunProfiles.filter((profile) => {
      const majorVersion = +getBrowserMajorVersionCurrent(profile.navigator.userAgent);

      const orbitaMatchResult = orbitasVersionsAvailable.includes(majorVersion);
      if (!orbitaMatchResult) {
        orbitaVersionsToDownload.push(`${majorVersion}`);
      }

      return orbitaMatchResult;
    });
  }

  if (orbitaVersionsToDownload.length) {
    const errorMessage = <Trans i18nKey={'automation.errors.noOrbitaForLaunch'} values={{ versions: orbitaVersionsToDownload.join(', ') }}></Trans>
    message.error(errorMessage);
  }

  span?.finish();
  return availableToRunProfiles;
};

export const getAvailableToRunProfiles = async (profiles: IProfile[], transaction?: Sentry.Transaction): Promise<IProfile[]> => {
  const span = transaction?.startChild({ op: 'check-browser-version-before-launch-mass-run' });
  let availableToRunProfiles = profiles.filter(isProfileAvailableToRun);
  if (isElectron) {
    const profilesMajorVersions = availableToRunProfiles.map((profile) => +getBrowserMajorVersionCurrent(profile.navigator.userAgent));
    const profilesMajorVersionsUnique = [...new Set(profilesMajorVersions)];
    const orbitasVersionsAvailable = await filterOrbitasVersionsAvailable(profilesMajorVersionsUnique);

    availableToRunProfiles = availableToRunProfiles.filter((profile) => {
      const majorVersion = +getBrowserMajorVersionCurrent(profile.navigator.userAgent);

      return orbitasVersionsAvailable.includes(majorVersion);
    });
  }

  span?.finish();
  return availableToRunProfiles;
};

export const startProfiles = async (opts: StartProfiles): Promise<void> => {
  const { launchProfileOrbita, isInstancesSyncRequired, translation } = opts;
  const selectedProfilesIds = getProfilesTableSelectedIds();
  const transaction = Sentry.startTransaction({ name: 'prepare-mass-run' });

  const spanUpdateState = transaction.startChild({ op: 'update-state' });
  spanUpdateState.finish();
  sendActionAnalytics('launched profile via mass operations');
  const profilesList = getProfilesList();
  const profilesSelected = profilesList.filter(({ id }) => selectedProfilesIds.includes(id));
  const [availableToRunProfiles, iconsStartDataMassOps] = await Promise.all([
    getAvailableToRunProfiles(profilesList, transaction),
    generateBrowserIconsMassOpsPromise(profilesSelected, transaction),
  ]);

  transaction.finish();

  // у нас и так нет никаких нотификаций на этот кейс, но почему-то мы его не проверяем, а идем дальше вплоть до
  // availableToRunProfiles.forEach(), хотя в этом нет смысла. слава тестер сказал, что все забили на этот кейс,
  // и вопрос к фронтендерам
  if (!availableToRunProfiles.length) {
    return;
  }

  const [masterProfileId] = selectedProfilesIds;
  if (isInstancesSyncRequired) {
    const masterProfile = availableToRunProfiles.find(({ id }) => id === masterProfileId);
    if (!masterProfile) {
      message.error(translation('tableProfiles.notification.unsupportedInstRunSync'));

      return;
    }

    const { runProfilesInSyncMinOrbitaVer } = getOrbitaFeaturesOpts();
    const masterUa = masterProfile.navigator.userAgent;
    const masterProfileMajorVersion = +getBrowserMajorVersionCurrent(masterUa);

    const currenUserOrbitaV = await ipcRenderer.invoke('get-orbita-browser-version') || '0.0.0';
    const [majorUserOrbitaVerStr] = currenUserOrbitaV.split('.');
    const majorUserOrbitaVer = Number(majorUserOrbitaVerStr);
    if (
      !majorUserOrbitaVer ||
      majorUserOrbitaVer < runProfilesInSyncMinOrbitaVer ||
      masterProfileMajorVersion < runProfilesInSyncMinOrbitaVer
    ) {
      message.error(translation('tableProfiles.notification.unsupportedInstRunSync'));

      return;
    }

    const instancesSyncErrorProfiles: IProfileRunStatus[] = [];
    for (let i = 0; i < availableToRunProfiles.length; i++) {
      const { id, navigator } = availableToRunProfiles[i];
      const profileMajorVersion = +getBrowserMajorVersionCurrent(navigator.userAgent);
      if (id === masterProfileId || profileMajorVersion === masterProfileMajorVersion) {
        continue;
      }

      availableToRunProfiles.splice(i, 1);
      instancesSyncErrorProfiles.push({
        id,
        status: 'profileStatuses.error',
        statusMessage: translation('tableProfiles.profileStatusMessages.invalidProfileVerRunSync') as string,
      });

      i--;
    }

    upsertProfileRunStatuses(instancesSyncErrorProfiles);
    if (availableToRunProfiles.length <= 1) {
      message.error(translation('tableProfiles.notification.invalidInstCountRunSync'));

      return;
    }
  }

  const slaveProfilesIds = availableToRunProfiles.reduce<string[]>((acc: string[], profile) => {
    if (profile.id !== masterProfileId) {
      acc.push(profile.id);
    }

    return acc;
  }, []);

  availableToRunProfiles.forEach((profile): void => {
    const dataForLaunch: ILaunchProfileOrbita = {
      profile,
      isMultipleLaunch: true,
      updateStatusToSync: false,
      skipOrbitaVersionCheck: true,
      iconsStartData: iconsStartDataMassOps[profile.id],
    };

    const isMasterProfile = masterProfileId === profile.id;
    if (isInstancesSyncRequired) {
      dataForLaunch.isInstancesSyncRequired = true;
      dataForLaunch.isMasterProfile = isMasterProfile;
      dataForLaunch.masterProfileId = masterProfileId;
      dataForLaunch.slaveProfilesIds = slaveProfilesIds
    }

    launchProfileOrbita(dataForLaunch).catch(() => null);
  });
};

export const toggleModalTransfer = (translation: TFunction): void => {
  const selectedProfileIds = getProfilesTableSelectedIds();
  const canTransferProfiles = filterProfilesByPermission(selectedProfileIds, 'transferProfile');

  if (!canTransferProfiles) {
    message.error(translation('notifications.error.permissionWorkspace'));

    return;
  }

  openProfilesTableModal(TRANSFER_PROFILE_MODAL_NAME, selectedProfileIds);
};

export const cloneProfile = async (translation: TFunction): Promise<void> => {
  const workspaceId = getCurrentWorkspaceId();
  const selectedProfileIds = getProfilesTableSelectedIds();
  sendActionAnalytics('clicked clone profile via mass operations');
  await submitProfilesClone(workspaceId, selectedProfileIds);

  if (NEW_FEATURES.objectPool) {
    await performGlobalDeltaSync();
  }

  reloadProfilesTableProfiles();

  message.success(translation('notifications.success.profileCloned'));
};

export const deleteOrLeaveMultipleProfiles = async (translation: TFunction): Promise<void> => {
  const workspaceId = getCurrentWorkspaceId();
  const promises = [];
  const profilesForDelete = getSelectedProfilesByAction('delete');
  const profileIdsToDelete = profilesForDelete.map((profile) => profile.id);
  if (profileIdsToDelete.length) {
    promises.push(
      submitProfilesDelete(workspaceId, profileIdsToDelete)
        .then(() => acceptDeleteProfiles(profileIdsToDelete)),
    );

    if (ipcRenderer) {
      ipcRenderer.invoke('stop-multiple-profiles', profileIdsToDelete);
      ipcRenderer.invoke('del-profiles-ext-folder', profileIdsToDelete);
    }

    sendActionAnalytics(E_ANALYTICS_ACTIONS.clickedConfirmDeleteProfileViaMassOperations, { actionInfo: profileIdsToDelete.length.toString() });
  }

  const profilesForLeave = getSelectedProfilesByAction('leave');
  if (profilesForLeave.length) {
    promises.push(await removeMultipleInvites(profilesForLeave.map((profile: any) => profile.shareId)));
  }

  const removedProfileIds = [...profileIdsToDelete, ...profilesForLeave.map(profile => profile.id)];
  const profileSettingsState = getProfilesSettingsState();
  if (removedProfileIds.find(removedProfileId => profileSettingsState.profileIds.includes(removedProfileId))) {
    closeProfilesSettings();
  }

  await Promise.all(promises);
  if (profileIdsToDelete.length) {
    message.success(translation('tableProfiles.notification.profilesDeleted', { count: profileIdsToDelete.length }));
  }

  if (profilesForLeave.length) {
    await performGlobalDeltaSync();
  }

  reloadProfilesTableProfiles();
};

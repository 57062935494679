import { Button, Icon } from 'antd';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { determineIsGeoProxyType, GeoProxyType } from '../../../../../common/constants/types';
import {
  calculateTrafficUsageWithRemainderInBytes,
  convertBytes,
  stringifyTrafficAmount,
  TRAFFIC_UNITS,
  TrafficUnit,
} from '../../../../../common/proxy/traffic/utils';
import { useTrafficData } from '../../../../state/proxy/traffic-data.atom';
import { luminatiCountries } from '../../../../utils/luminati-countries';
import { sendReactErrorToSentry } from '../../../../utils/sentry.helper';
import { GEOPROXY_INVALID_TYPE_ERROR, GEOPROXY_INVALID_TYPE_ERROR_MESSAGE, GEOPROXY_TYPE_TAG } from '../../../proxy/constants';
import {
  CountryFlagContainer,
  IconSync,
  LoaderDiv,
  ProxyCheckDescription,
  ProxyFirstLineContainer,
  SuccessProxyCheckContainer,
} from '../domElements';
import {
  ICountryListElemInfo,
  IProxyChecked,
} from '../interfaces';
import { checkProxy } from '../utils/check-proxy';

const isElectron = !!window.require;

type ProxyCheckerFormProps = {
  proxyChecked: IProxyChecked;
  currentDisplay?: 'form'|'result';
  setProxyChecked: (params: any) => void;
  setCurrentDisplay: (params: 'form'|'result') => void;
  isLoading: boolean;
  profileProxy?: any;
  style?: any;
  geolocationShowOption: boolean;
  initialCheck: boolean;
}

const ProxyChecker: React.FC<ProxyCheckerFormProps> = (props) => {
  const trafficData = useTrafficData();

  const [isCheckLoading, setCheckLoading] = useState<boolean>(false);

  const { t: translation } = useTranslation();

  const { proxyChecked, profileProxy } = props;

  useEffect(() => {
    if (!profileProxy || proxyChecked?.status === 'success') {
      return;
    }

    checkCurrentProxy();
  }, []);

  const getProxyObjToUpdate = (res: any) => {
    let proxyData = {
      ...res,
    };

    if (res.status === 'fail') {
      proxyData = {
        ...proxyChecked,
        status: 'fail',
        error: res.error,
      };
    }

    const baseObj: any = {
      ...proxyData,
      connectionType: proxyChecked.connectionType,
      trafficUsed: proxyChecked.trafficUsed || '',
      trafficLimit: proxyChecked.trafficLimit || 0,
    };

    if (proxyChecked.city) {
      baseObj.city = proxyChecked.city;
      baseObj.country = proxyChecked.country;
    }

    return baseObj;
  };

  const checkCurrentProxy = () => {
    const optionsToCheckProxy: any = {
      proxy: {
        type: props.profileProxy.type,
        host: props.profileProxy.host,
        port: props.profileProxy.port,
        username: props.profileProxy.username,
        password: props.profileProxy.password,
      },
      setProxyChecked: (res: any) => props.setProxyChecked(getProxyObjToUpdate(res)),
    };

    const processCbs = () => {
      setCheckLoading(true);
    };

    const resCbs = () => {
      setCheckLoading(false);
    };

    checkProxy(optionsToCheckProxy, processCbs, resCbs, translation);
  };

  const getSyncLink = () => {
    if (isCheckLoading) {
      return (
        <IconSync
          type='sync'
          spin={true}
        />
      );
    }

    return (
      <IconSync
        type='sync'
        onClick={checkCurrentProxy}
      />
    );
  };

  const getChangeProxyButton = () => {
    if (!(props.geolocationShowOption && props.currentDisplay === 'result')) {
      return null;
    }

    return (
      <Button
        type={'primary'}
        onClick={() => props.setCurrentDisplay('form')}
        style={{ marginTop: '10px' }}
      >
        {translation('proxy.btnChangeProxy')}
      </Button>
    );
  };

  const getProxyPingText = () => {
    if (proxyChecked.status === 'success' && proxyChecked.ping && isElectron) {
      return (
        <div>
          &nbsp;
          {proxyChecked.ping}
          {' '}
          ms
        </div>
      );
    }

    if (proxyChecked.status === 'fail') {
      return (
        <div style={{ color: 'red' }}>
          &nbsp;&nbsp;
          {proxyChecked.error}
&nbsp;
        </div>
      );
    }

    return null;
  };

  const renderTrafficUsageInfo = (): JSX.Element | null => {
    if (!props.geolocationShowOption) {
      return null;
    }

    if (!determineIsGeoProxyType(proxyChecked.connectionType)) {
      sendReactErrorToSentry({
        transactionName: GEOPROXY_INVALID_TYPE_ERROR,
        message: GEOPROXY_INVALID_TYPE_ERROR_MESSAGE,
        tags: [
          [GEOPROXY_TYPE_TAG, proxyChecked.connectionType],
        ],
      });

      return null;
    }

    const { trafficUsedBytes, trafficLimitBytes } = calculateTrafficUsageWithRemainderInBytes(trafficData, proxyChecked.connectionType);
    const trafficUnit: TrafficUnit = proxyChecked.connectionType === GeoProxyType.Mobile ? TRAFFIC_UNITS.mb : TRAFFIC_UNITS.gb;
    const [trafficUsedInUnits, trafficLimitInUnits] = [trafficUsedBytes, trafficLimitBytes].map((bytes) => {
      const trafficInUnits = convertBytes(bytes, trafficUnit);

      return stringifyTrafficAmount(trafficInUnits, 2);
    });

    return (
      <Trans
        i18nKey='profileSettings.proxy.trafficUsedAndRemainingInfo'
        values={{
          usedTraffic: trafficUsedInUnits,
          trafficLimit: trafficLimitInUnits,
          unit: trafficUnit,
        }}
      />
    );
  };

  const getCheckProxyDescription = () => {
    if (!props.geolocationShowOption) {
      window.scrollTo({ behavior: 'smooth', top: 1000 });

      let mode = 'HTTP';

      if (profileProxy.mode === 'socks4') {
        mode = 'SOCKS4';
      }

      if (profileProxy.mode === 'socks5') {
        mode = 'SOCKS5';
      }

      return `${mode} • ${proxyChecked.origin}`;
    }

    let connectionType = 'Residential';
    switch (proxyChecked.connectionType) {
      case GeoProxyType.Mobile:
        connectionType = 'Mobile';
        break;
      case GeoProxyType.DataCenter:
        connectionType = 'Data Center';
        break;
      default:
        break;
    }

    return `HTTP • ${connectionType} • ${proxyChecked.origin?.includes(':') ? 'IPv6' : 'IPv4'}`;
  };

  const getContent = () => {
    if (props.isLoading) {
      return (
        <LoaderDiv>
          <Icon type='loading' />
        </LoaderDiv>
      );
    }

    if (proxyChecked.status) {
      const countryInfo: ICountryListElemInfo|undefined =
        luminatiCountries.find((el: ICountryListElemInfo) => el.code === proxyChecked?.country?.toLowerCase());

      const countryName = countryInfo?.country || proxyChecked?.country?.toUpperCase();

      return (
        <>
          <SuccessProxyCheckContainer>
            <div className='check-params'>
              <CountryFlagContainer>
                {getUnicodeFlagIcon(proxyChecked.country)}
              </CountryFlagContainer>
              <ProxyFirstLineContainer>
                {translation('proxy.proxyIn')}
                {' '}
                {countryName}
                {' '}
                •
                {' '}
                {proxyChecked.country}
                {' '}
                •
                {' '}
                {proxyChecked.city}
                {' '}
                •
                {getProxyPingText()}
                {getSyncLink()}
              </ProxyFirstLineContainer>
            </div>
            <ProxyCheckDescription>
              {getCheckProxyDescription()}
            </ProxyCheckDescription>
            <ProxyCheckDescription>
              {renderTrafficUsageInfo()}
            </ProxyCheckDescription>
            {getChangeProxyButton()}
          </SuccessProxyCheckContainer>
        </>
      );
    }

    return null;
  };

  return getContent();
};

export default ProxyChecker;

import { message } from 'antd';
import React from 'react';
import { Trans } from 'react-i18next';

import { ProxyListMultipleOperationButtonsWrapper, ProxyListMultipleOperationsWrapper } from './styles';
import { NEW_FEATURES } from '../../../../../state/feature-toggle/new-features';
import { useProxyManagerListEntities } from '../../../../../state/proxy/proxy-groups/proxy-groups.atom';
import { selectAllProxies, updateSelectedProxies, useSelectedProxies } from '../../../../../state/proxy/selected-proxies.atom';
import { switchConfirmBlockVisible, switchIsSelectProxyModeOpened, useIsSelectProxyModeOpened } from '../../../../../state/proxy-select-menu.atom';
import { IconBinTrashDelete, IconClose } from '../../../../../ui/gologin-header/icons';
import { IconProxyCopy } from '../../../../../ui/gologin-header/icons/icon-proxy-copy';
import IconSelectAll from '../../../../../ui/gologin-header/icons/icon-select-all';
import { IIconWrapper } from '../../../../../ui/gologin-header/icons/wrapper';
import TooltipWrapper from '../../../../../ui/tooltip-custom/tooltip-wrapper';
import { copyProxies } from '../../../proxy-helpers';
import { FooterBtn } from '../../proxy-edit-view/styles';

const ProxyListMultipleOperationsMenu: React.FC = () => {
  const { selectedProxies } = useSelectedProxies();
  const isSelectProxyModeOpened = useIsSelectProxyModeOpened();
  const proxyManagerListEntities = useProxyManagerListEntities();

  const handleCancel = (): void => {
    updateSelectedProxies({
      selectedProxy: null,
      selectedProxies: [],
    });

    switchIsSelectProxyModeOpened(false);
  };

  const handleSelectAll = (): void => {
    // TODO: type properly before the proxyGroups release
    const proxies = proxyManagerListEntities.filter((entity) => entity && entity.host);
    if (selectedProxies.length === proxies.length) {
      updateSelectedProxies({ selectedProxies: [] });
    } else {
      selectAllProxies();
    }
  };

  const handleDelete = (): void => {
    if (!selectedProxies.length) {
      message.error(<Trans i18nKey='proxies.noSelectedProxies' />);

      return;
    }

    updateSelectedProxies({ selectedProxy: null });
    switchConfirmBlockVisible(true);
  };

  const handleCopy = (): void => {
    // TODO: type properly before the proxyGroups release
    const proxies = proxyManagerListEntities.filter(proxyEntity => proxyEntity && selectedProxies.includes(proxyEntity.id));
    if (proxies.length) {
      copyProxies(proxies);
      message.success(<Trans i18nKey='base.copiedText' />);
    }
  };

  const footerIconProps: IIconWrapper = {
    padding: 0,
    styleType: 'lightGray',
    iconColor: NEW_FEATURES.proxyGroupsV2 ?
      'var(--B5B5BA)' : 'var(--CDCDCD-proxy-manager-update-form)',
    iconHoveredColor: NEW_FEATURES.proxyGroupsV2 ?
      'var(--proxy-group-header-arrow-color)' : 'var(--00A987-proxy-manager)',
  };

  const footerButtons = [{
    name: 'selectAllProxies',
    title: <Trans i18nKey='proxies.selectAllProxies' />,
    onClick: handleSelectAll,
    icon: <IconSelectAll {...footerIconProps} />,
  }, {
    name: 'copyProxies',
    title: (selectedProxies) ? <Trans i18nKey='proxies.copyProxy' /> : <Trans i18nKey='proxies.selectProxiesToCopy' />,
    onClick: handleCopy,
    icon: <IconProxyCopy {...footerIconProps} />,
    tooltipTrigger: (selectedProxies) ? 'hover' : 'click',
  }, {
    name: 'deleteProxies',
    title: (selectedProxies) ? <Trans i18nKey='proxies.deleteProxies' /> : <Trans i18nKey='proxies.selectProxiesToDelete' />,
    onClick: handleDelete,
    icon: <IconBinTrashDelete {...footerIconProps} />,
    tooltipTrigger: (selectedProxies) ? 'hover' : 'click',
  }, {
    name: 'cancel',
    title: <Trans i18nKey='proxies.cancel' />,
    onClick: handleCancel,
    icon: <IconClose {...footerIconProps} />,
  }];

  if (!isSelectProxyModeOpened) {
    return null;
  }

  return (
    <ProxyListMultipleOperationsWrapper isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroupsV2}>
      <ProxyListMultipleOperationButtonsWrapper>
        {footerButtons.map((button): JSX.Element => (
          <TooltipWrapper
            key={button.name}
            isTransition={true}
            value={button.title}
          >
            <FooterBtn onClick={button.onClick} type='button'>
              {button.icon}
            </FooterBtn>
          </TooltipWrapper>
        ))}
      </ProxyListMultipleOperationButtonsWrapper>
    </ProxyListMultipleOperationsWrapper>
  );
};

export default ProxyListMultipleOperationsMenu;

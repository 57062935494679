import styled from '@emotion/styled';

export const ProxyListMultipleOperationsWrapper = styled.div<{ isProxyManagerWithGroups: boolean }>`
  width: 100%;
  height: 44px;
  padding: 10px 0;
  border-top: 1px solid var(--EBEBEB-proxy-manager-list-menu);

  position: absolute;
  left: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: ${(props): React.CSSProperties['background'] => props.isProxyManagerWithGroups ?
    'var(--FFFFFF-proxy-manager-popup-grouped)' : 'var(--FFFFFF-proxy-manager-popup)'};
`;

export const ProxyListMultipleOperationButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

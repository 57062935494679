import styled from '@emotion/styled/macro';

import { GOLOGIN_POPOVER_DEFAULT_PADDING } from './gologin-popover-item-row';
import { textFont } from '../style-templates';

export const GologinPopoverHint = styled.div`
  padding: ${GOLOGIN_POPOVER_DEFAULT_PADDING};

  ${textFont()}
  text-align: left;
  color: var(--767676);
`;

import React, { FC } from 'react';
import { CardListItem, DescriptionPlan, IconContainer } from './styles';
import IconPlanCheck from '../../../../ui/icons/IconPlanCheck';
import ChangeLimitSelector from './change-limits-selector';


export type LimitsSelectorProps = {
  maxValueSelected: number;
  onPlusClick: () => void;
  onMinusClick: () => void;
  isCustomPlan: boolean;
  shouldShowChangeLimitSelector: boolean;
  descriptionText: () => JSX.Element;
  selectorUnavailableDescriptionText: () => JSX.Element;
}

export const LimitsSelector: FC<LimitsSelectorProps> = (props): JSX.Element => {
  const {
    isCustomPlan,
    onPlusClick,
    onMinusClick,
    maxValueSelected,
    shouldShowChangeLimitSelector,
    descriptionText,
    selectorUnavailableDescriptionText,
  } = props;

  return (
    <CardListItem isCustomPlan={isCustomPlan}>
      <IconContainer>
        <IconPlanCheck />
      </IconContainer>
      <DescriptionPlan>
        {shouldShowChangeLimitSelector ? (
          <>
            <ChangeLimitSelector
              maxValue={maxValueSelected}
              onPlusClick={onPlusClick}
              onMinusClick={onMinusClick}
              isCustomPlan={isCustomPlan}
            />
            {' '}
            <div>
              {descriptionText()}
            </div>
          </>
        ) : (
          selectorUnavailableDescriptionText()
        )}
      </DescriptionPlan>
    </CardListItem>
  );
};

export default LimitsSelector